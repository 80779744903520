import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breakpoint } from "../../models/layout";
import { createNumberList } from "../../utils/list";
import Pager from "../pager/Pager";
import styles from "./DataTable.module.scss";

export type BaseData = Record<string, any> & {
  link?: string;
};

export interface ColumnItem<D extends BaseData> {
  width?: string | number;
  hideBreakpoint?: Breakpoint;
  headerCell: {
    props?: React.DetailedHTMLProps<
      React.ThHTMLAttributes<HTMLTableCellElement>,
      HTMLTableCellElement
    >;
    renderChildren: (colItem: ColumnItem<D>) => React.ReactNode;
  };
  dataCell: {
    props?: React.DetailedHTMLProps<
      React.TdHTMLAttributes<HTMLTableCellElement>,
      HTMLTableCellElement
    >;
    renderChildren: (
      dataItem: D,
      colItem: ColumnItem<D>,
      rowIndex: number
    ) => React.ReactNode;
  };
}

export type ColumnDict<D extends BaseData> = Partial<
  Record<keyof D, ColumnItem<D>>
>;

interface Props<D extends BaseData> {
  className?: string;
  tableClass?: string;
  footerClass?: string;
  columns: (keyof D)[];
  columnDict: ColumnDict<D>;
  data?: D[];
  loading?: boolean;
  currentPage?: number;
  totalPage?: number;
  itemsPerPage?: number;
  onPreviousPageButtonClick: () => void;
  onNextPageButtonClick: () => void;
}

// eslint-disable-next-line consistent-return
function getHideBreakpointClassName(breakpoint: Breakpoint): string {
  switch (breakpoint) {
    case "sm":
      return styles.hideOnSm;
    case "md":
      return styles.hideOnMd;
    case "lg":
      return styles.hideOnLg;
    case "xl":
      return styles.hideOnXl;
    case "xxl":
      return styles.hideOnXxl;
  }
}

const DataTable = React.memo(<D extends BaseData>(props: Props<D>) => {
  const {
    className,
    tableClass,
    footerClass,
    columns,
    columnDict,
    data,
    loading,
    currentPage,
    totalPage,
    itemsPerPage,
    onPreviousPageButtonClick,
    onNextPageButtonClick,
  } = props;

  const { t } = useTranslation();

  const getColumnItem = useCallback(
    (colKey: keyof D): ColumnItem<D> | undefined => {
      return columnDict[colKey];
    },
    [columnDict]
  );

  const renderHeaderCell = useCallback(
    (colKey: keyof D) => {
      const colItem = getColumnItem(colKey);
      if (!colItem) {
        return null;
      }

      const { width, hideBreakpoint, headerCell } = colItem;
      return (
        <div
          {...headerCell.props}
          key={String(colKey)}
          className={classNames(
            styles.tableCell,
            headerCell.props?.className,
            hideBreakpoint
              ? getHideBreakpointClassName(hideBreakpoint)
              : undefined
          )}
          style={{
            width,
            ...headerCell.props?.style,
          }}
        >
          {headerCell.renderChildren(colItem)}
        </div>
      );
    },
    [getColumnItem]
  );

  const renderDataCell = useCallback(
    (rowIndex: number, colKey: keyof D, dataItem?: D) => {
      const colItem = getColumnItem(colKey);
      if (!colItem) {
        return null;
      }

      const { hideBreakpoint, dataCell } = colItem;
      const showPlaceholder = !dataItem;
      return (
        <div
          {...dataCell.props}
          key={String(colKey)}
          className={classNames(
            styles.tableCell,
            dataCell.props?.className,
            hideBreakpoint
              ? getHideBreakpointClassName(hideBreakpoint)
              : undefined,
            {
              "placeholder-glow": showPlaceholder,
            }
          )}
        >
          {showPlaceholder ? (
            <Placeholder xs={12} />
          ) : (
            dataCell.renderChildren(dataItem, colItem, rowIndex)
          )}
        </div>
      );
    },
    [getColumnItem]
  );

  const renderDataRow = useCallback(
    (rowIndex: number, dataItem?: D) => {
      const link =
        dataItem && "link" in dataItem && typeof dataItem["link"] === "string"
          ? dataItem.link
          : undefined;

      const cellComponents = columns.map((colKey) =>
        renderDataCell(rowIndex, colKey, dataItem)
      );

      if (link === undefined) {
        return (
          <div key={rowIndex} className={styles.tableRow}>
            {cellComponents}
          </div>
        );
      }
      return (
        <Link
          key={rowIndex}
          className={classNames(styles.tableRow, styles.link)}
          to={link}
        >
          {cellComponents}
        </Link>
      );
    },
    [columns, renderDataCell]
  );

  const headerCellComponents = useMemo(() => {
    return columns.map((colKey) => renderHeaderCell(colKey));
  }, [columns, renderHeaderCell]);

  const dataRowComponents = useMemo(() => {
    if (loading) {
      return createNumberList(itemsPerPage ?? 0).map((rowIndex) =>
        renderDataRow(rowIndex, undefined)
      );
    }
    if (!data) {
      return null;
    }
    return data.map((dataItem, rowIndex) => renderDataRow(rowIndex, dataItem));
  }, [loading, data, itemsPerPage, renderDataRow]);

  return (
    <div className={classNames(styles.root, className)}>
      <div className={classNames(styles.table, tableClass)}>
        <div className={styles.tableHeaderGroup}>
          <div className={styles.tableRow}>{headerCellComponents}</div>
        </div>
        <div className={styles.tableRowSpacer} />
        <div className={styles.tableRowGroup}>{dataRowComponents}</div>
      </div>
      {data && data.length === 0 && !loading && (
        <div className={styles.noDataRow}>
          <span className={styles.noDataText}>
            {t("components.dataTable.noData")}
          </span>
        </div>
      )}
      <div className={classNames(styles.footer, footerClass)}>
        <Pager
          currentPage={currentPage}
          totalPage={totalPage}
          onPreviousPage={onPreviousPageButtonClick}
          onNextPage={onNextPageButtonClick}
        />
      </div>
    </div>
  );
});

export default DataTable as <D>(props: Props<D>) => React.ReactElement;
