import { TypePolicies } from "@apollo/client";
import { PaginationListView } from "../models/pagination";
import { createFieldPolicy, createKeyArgItem } from "../utils/apollo";
import {
  UserFetchAlbumPhotosVariables,
  UserFetchAlbumPhotos_userFetchAlbumPhotos,
} from "./__generated__/UserFetchAlbumPhotos";
import {
  UserFetchAlbumsVariables,
  UserFetchAlbums_userFetchAlbums,
} from "./__generated__/UserFetchAlbums";
import {
  UserFetchArticlesVariables,
  UserFetchArticles_userFetchArticles,
} from "./__generated__/UserFetchArticles";
import {
  UserFetchLeadsVariables,
  UserFetchLeads_userFetchLeads,
} from "./__generated__/UserFetchLeads";

function mergePaginatedListView<LV extends PaginationListView<any>>(
  existing: LV | undefined,
  incoming: LV,
  offset: number
): LV {
  // Assume page is continued: no space between existing and incoming data
  const mergedData = existing ? existing.data.slice(0) : [];
  for (let i = 0; i < incoming.data.length; i++) {
    mergedData[offset + i] = incoming.data[i];
  }

  return {
    ...(existing ?? {}),
    ...incoming,
    data: mergedData,
  };
}

export const TYPE_POLICIES: TypePolicies = {
  UserLessonView: {
    keyFields: ["slug"],
  },
  Query: {
    fields: {
      userFetchAlbums: createFieldPolicy<
        UserFetchAlbums_userFetchAlbums,
        UserFetchAlbumsVariables
      >({
        keyArgs: [
          createKeyArgItem("listQuery", [
            createKeyArgItem("orderBy"),
            createKeyArgItem("keyword"),
          ]),
        ],
        merge: (existing, incoming, options) => {
          const offset = options.variables?.listQuery.offset ?? 0;
          return mergePaginatedListView(existing, incoming, offset);
        },
      }),
      userFetchAlbumPhotos: createFieldPolicy<
        UserFetchAlbumPhotos_userFetchAlbumPhotos,
        UserFetchAlbumPhotosVariables
      >({
        keyArgs: [
          createKeyArgItem("albumUuid"),
          createKeyArgItem("listQuery", [
            createKeyArgItem("orderBy"),
            createKeyArgItem("keyword"),
          ]),
        ],
        merge: (existing, incoming, options) => {
          const offset = options.variables?.listQuery.offset ?? 0;
          return mergePaginatedListView(existing, incoming, offset);
        },
      }),
      userFetchArticles: createFieldPolicy<
        UserFetchArticles_userFetchArticles,
        UserFetchArticlesVariables
      >({
        keyArgs: [createKeyArgItem("listQuery", [createKeyArgItem("orderBy")])],
        merge: (existing, incoming, options) => {
          const offset = options.variables?.offset ?? 0;
          return mergePaginatedListView(existing, incoming, offset);
        },
      }),
      userFetchLeads: createFieldPolicy<
        UserFetchLeads_userFetchLeads,
        UserFetchLeadsVariables
      >({
        keyArgs: [createKeyArgItem("listQuery", [createKeyArgItem("orderBy")])],
        merge: (existing, incoming, options) => {
          const offset = options.variables?.offset ?? 0;
          return mergePaginatedListView(existing, incoming, offset);
        },
      }),
    },
  },
};
