import classNames from "classnames";
import React from "react";
import styles from "./HorizontalRule.module.scss";

interface Props {
  className?: string;
}

const HorizontalRule: React.FC<Props> = React.memo((props) => {
  const { className } = props;
  return <hr className={classNames(styles.root, className)} />;
});

export default HorizontalRule;
