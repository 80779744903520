import React, { useCallback, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./AlertModal.module.scss";

/**
 * `positive`: confirm button is clicked;
 * `negative`: cancel button is clicked;
 * `passive`: backdrop / escape key is clicked;
 */
type CloseReason = "positive" | "negative" | "passive";

interface OpenModalOptions {
  title?: string;
  message: string;
  positiveButtonLabel?: string;
  negativeButtonLabel?: string;
}

interface ModalData extends OpenModalOptions {
  onClose: (reason: CloseReason) => void;
}

interface Props {
  show: boolean;
  data: ModalData | undefined;
  onRequestClose: (reason: CloseReason) => void;
}

interface UseAlertModalValues {
  open: (options: OpenModalOptions) => Promise<CloseReason>;
  close: () => void;
  props: Props;
}

export function useAlertModal(): UseAlertModalValues {
  const [show, setShow] = useState(false);
  const [data, setData] = useState<ModalData | undefined>(undefined);

  const open = useCallback(async (options: OpenModalOptions) => {
    return new Promise<CloseReason>((resolve) => {
      setData({
        ...options,
        onClose: resolve,
      });
      setShow(true);
    });
  }, []);

  const close = useCallback(() => {
    setShow(false);
  }, []);

  const onRequestClose = useCallback(
    (reason: CloseReason) => {
      data?.onClose(reason);
      setShow(false);
    },
    [data]
  );

  const values = useMemo<UseAlertModalValues>(
    () => ({
      open,
      close,
      props: {
        show,
        data,
        onRequestClose,
      },
    }),
    [close, onRequestClose, open, data, show]
  );

  return values;
}

const AlertModal: React.FC<Props> = React.memo((props) => {
  const { show, data, onRequestClose } = props;

  const { t } = useTranslation();

  const onNegativeButtonClick = useCallback(() => {
    onRequestClose("negative");
  }, [onRequestClose]);

  const onPositiveButtonClick = useCallback(() => {
    onRequestClose("positive");
  }, [onRequestClose]);

  // either the backdrop is clicked, or the escape key is pressed
  const onModalHide = useCallback(() => {
    onRequestClose("passive");
  }, [onRequestClose]);

  const contentComponent = useMemo(() => {
    if (!data) {
      return null;
    }
    return (
      <>
        {data.title && (
          <Modal.Header>
            <Modal.Title>{data.title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>{data.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onNegativeButtonClick}>
            {data.negativeButtonLabel ?? t("components.alertModal.cancel")}
          </Button>
          <Button variant="primary" onClick={onPositiveButtonClick}>
            {data.positiveButtonLabel ?? t("components.alertModal.confirm")}
          </Button>
        </Modal.Footer>
      </>
    );
  }, [data, onNegativeButtonClick, onPositiveButtonClick, t]);

  return (
    <Modal
      dialogClassName={styles.modalDialog}
      size="sm"
      centered={true}
      show={show}
      onHide={onModalHide}
    >
      {contentComponent}
    </Modal>
  );
});

export default AlertModal;
