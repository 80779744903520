import classNames from "classnames";
import React from "react";
import styles from "./UserMenuItemContent.module.scss";

interface Props {
  className?: string;
  icon: React.ReactNode;
  label: string;
  linkLabel: string;
}

const UserMenuItemContent: React.FC<Props> = React.memo((props) => {
  const { className, icon, label, linkLabel } = props;

  return (
    <div className={classNames(styles.root, className)}>
      <span className={styles.iconWrapper}>{icon}</span>
      <div className={styles.column}>
        <span className={styles.label}>{label}</span>
        <span className={styles.linkLabel}>{linkLabel}</span>
      </div>
    </div>
  );
});

export default UserMenuItemContent;
