import moment from "moment";
import { UserFetchEvents_userFetchEvents_data } from "../../graphql/__generated__/UserFetchEvents";
import { Event } from "../../models/event";
import { optional } from "./gql-model-utils";

export function convertEventFromGQLModel(
  value: UserFetchEvents_userFetchEvents_data
): Event {
  return {
    title: value.title,
    uuid: value.uuid,
    location: optional(value.location),
    remarks: optional(value.remarks),
    startTime: moment(value.startTime),
    endTime: moment(value.endTime),
  };
}
