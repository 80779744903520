import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import ResponsiveImage from "../../components/image/ResponsiveImage";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import styles from "./PurposePage.module.scss";

export const PURPOSE_PAGE_PATH_PATTERN = "/purpose";

export function generatePurposePagePath(): string {
  return generatePath(PURPOSE_PAGE_PATH_PATTERN, {});
}

const PURPOSE_CARDS_DATA = [
  {
    iconUrl: "/img/purpose/purpose-mission.png",
    titleKey: "pages.purposePage.mission1.title",
    descriptionKey: "pages.purposePage.mission1.description",
  },
  {
    iconUrl: "/img/purpose/purpose-mission.png",
    titleKey: "pages.purposePage.mission2.title",
    descriptionKey: "pages.purposePage.mission2.description",
  },
  {
    iconUrl: "/img/purpose/purpose-vision.png",
    titleKey: "pages.purposePage.vision.title",
    descriptionKey: "pages.purposePage.vision.description",
  },
  {
    iconUrl: "/img/purpose/purpose-core-values.png",
    titleKey: "pages.purposePage.coreValues.title",
    descriptionKey: "pages.purposePage.coreValues.description",
  },
];

const PurposePage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        title={t("pages.purposePage.header.title")}
        backgroundImageSrc="/img/purpose/purpose-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        <div className={styles.logoContainer}>
          <ResponsiveImage className={styles.logo} src="/img/common/logo.png" />
          <div className={styles.sloganContainer}>
            <span className={styles.slogan}>
              {t("pages.purposePage.slogan1")}
            </span>
            <span className={styles.slogan}>
              {t("pages.purposePage.slogan2")}
            </span>
            <span className={styles.slogan}>
              {t("pages.purposePage.slogan3")}
            </span>
            <span className={styles.slogan}>
              {t("pages.purposePage.slogan4")}
            </span>
          </div>
        </div>

        <Row className="g-3">
          {PURPOSE_CARDS_DATA.map((item, index) => (
            <Col key={index} md={6} sm={12}>
              <div className={styles.purposeCard}>
                <ResponsiveImage
                  className={styles.cardIcon}
                  src={item.iconUrl}
                />
                <div className={styles.cardDetails}>
                  <div className={styles.cardTitle}>{t(item.titleKey)}</div>
                  <div className={styles.cardDescription}>
                    {t(item.descriptionKey)}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default PurposePage;
