import React from "react";
import styles from "./MenuButtonItem.module.scss";

interface Props {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const MenuButtonItem: React.FC<Props> = React.memo((props) => {
  const { className, onClick, children } = props;

  return (
    <li className={className}>
      <button className={styles.button} type="button" onClick={onClick}>
        {children}
      </button>
    </li>
  );
});

export default MenuButtonItem;
