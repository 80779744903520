import classNames from "classnames";
import moment from "moment";
import React, { useMemo } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { useTranslation } from "react-i18next";
import { Event } from "../../models/event";
import styles from "./CalendarEventCard.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  event: Event | undefined;
}

const CalendarEventCard: React.FC<Props> = React.memo((props) => {
  const { className, loading, event } = props;

  const { t } = useTranslation();

  const isEventOnGoing = useMemo(() => {
    if (!event) {
      return undefined;
    }
    const now = moment();
    return (
      event.startTime.valueOf() <= now.valueOf() &&
      event.endTime.valueOf() >= now.valueOf()
    );
  }, [event]);

  const timeDisplay = useMemo(() => {
    if (!event) {
      return undefined;
    }
    return `${event.startTime.format("HH:ss")}-${event.endTime.format(
      "HH:ss"
    )}`;
  }, [event]);

  if (!event) {
    if (loading) {
      return (
        <div
          className={classNames(styles.root, className, {
            [styles.onGoing]: isEventOnGoing,
          })}
        >
          <div className={classNames(styles.title, "placeholder-glow")}>
            <Placeholder xs={12} />
          </div>
          <div className={classNames(styles.time, "placeholder-glow")}>
            <Placeholder xs={12} />
          </div>
          <div className={classNames(styles.remarks, "placeholder-glow")}>
            <Placeholder xs={12} />
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.onGoing]: isEventOnGoing,
      })}
    >
      <div className={styles.title}>{event.title}</div>
      <div className={styles.time}>{timeDisplay}</div>
      {event.location !== undefined && (
        <div className={styles.location}>{`${t(
          "components.calendarEventCard.locationLabel"
        )} ${event.location}`}</div>
      )}
      {event.remarks !== undefined && (
        <div className={styles.remarks}>{`${t(
          "components.calendarEventCard.remarksLabel"
        )} ${event.remarks}`}</div>
      )}
    </div>
  );
});

export default CalendarEventCard;
