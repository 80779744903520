import { PermissionLevel } from "../graphql/__generated__/globalTypes";
import { UserPermissionLevel } from "../models/user-permission-level";

// eslint-disable-next-line consistent-return
export function getUserPermissionLevelFromUser(
  permissionLevel: PermissionLevel
): UserPermissionLevel {
  switch (permissionLevel) {
    case PermissionLevel.FREE:
      return UserPermissionLevel.Free;
    case PermissionLevel.PREMIUM:
      return UserPermissionLevel.Premium;
  }
}

// eslint-disable-next-line consistent-return
export function mapPermissionLevelToNumericValue(
  permissionLevel: PermissionLevel
): number {
  switch (permissionLevel) {
    case PermissionLevel.FREE:
      return 0;
    case PermissionLevel.PREMIUM:
      return 1;
  }
}

// eslint-disable-next-line consistent-return
export function mapUserPermissionLevelToNumericValue(
  userPermissionLevel: UserPermissionLevel
): number {
  switch (userPermissionLevel) {
    case UserPermissionLevel.Anonymous:
    case UserPermissionLevel.Free:
      return 0;
    case UserPermissionLevel.Premium:
      return 1;
  }
}

export function mapGeneralAndUserPermissionLevelToNumericValue(
  permissionLevel: PermissionLevel | UserPermissionLevel
): number {
  if (typeof permissionLevel === "string") {
    return mapPermissionLevelToNumericValue(permissionLevel);
  }
  return mapUserPermissionLevelToNumericValue(permissionLevel);
}

export function comparePermissionLevel(
  a: PermissionLevel | UserPermissionLevel,
  b: PermissionLevel | UserPermissionLevel
): number {
  return (
    mapGeneralAndUserPermissionLevelToNumericValue(a) -
    mapGeneralAndUserPermissionLevelToNumericValue(b)
  );
}
