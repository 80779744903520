import React, { useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import styles from "./PFinCalculatorRadioField.module.scss";

interface RadioOption {
  id: string;
  value: string;
  label: string;
}

interface Props {
  className?: string;
  disabled?: boolean;
  options: RadioOption[];
  value: string;
  onChange?: (value: string) => void;
}

const PFinCalculatorRadioField: React.FC<Props> = React.memo((props) => {
  const { className, options, value, onChange } = props;

  const _onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  const formCheckComponents = useMemo(() => {
    return options.map((option) => (
      <Form.Check
        key={option.value}
        className={styles.formCheck}
        inline={true}
        type="radio"
        id={option.id}
        value={option.value}
        label={option.label}
        checked={value === option.value}
        onChange={_onChange}
      />
    ));
  }, [_onChange, options, value]);

  return <Form.Group className={className}>{formCheckComponents}</Form.Group>;
});

export default PFinCalculatorRadioField;
