import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment-timezone";
import ZH_HK from "./locales/zh-hk";

// Setup locale for moment
import "moment/locale/zh-hk";
moment.locale("zh-hk");

// Setup i18next
i18n
  .use(initReactI18next)
  .init({
    lng: "zh-HK",
    resources: {
      "zh-HK": {
        translation: ZH_HK,
      },
    },
    interpolation: {
      /**
       * (nicolas 10Aug2022)
       * JSX escapes string literal enclosed by `{}`. So no need to escape value in 18next level.
       */
      escapeValue: false,
    },
  })
  .catch((error) => {
    console.error(error);
  });
