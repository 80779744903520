import classNames from "classnames";
import React, { useMemo } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { BsLockFill, BsPlayFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FetchCategoryAndLessonsByLesson_fetchCategoryAndLessonsByLesson_lessons } from "../../graphql/__generated__/FetchCategoryAndLessonsByLesson";
import { PermissionLevel } from "../../graphql/__generated__/globalTypes";
import { UserPermissionLevel } from "../../models/user-permission-level";
import { generateLessonPagePath } from "../../pages/lesson/LessonPage";
import { isUserAbleToAccessLesson } from "../../utils/lesson";
import DurationDisplay from "../duration-display/DurationDisplay";
import FixedRatioImage from "../fixed-ratio-image/FixedRatioImage";
import FreeLessonLabel from "../free-lesson-label/FreeLessonLabel";
import styles from "./LessonPlaylistItem.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  userPermissionLevel: UserPermissionLevel;
  active: boolean;
  lesson:
    | FetchCategoryAndLessonsByLesson_fetchCategoryAndLessonsByLesson_lessons
    | undefined;
}

const LessonPlaylistItem: React.FC<Props> = React.memo((props) => {
  const { className, loading, userPermissionLevel, active, lesson } = props;

  const statusComponent = useMemo(() => {
    if (lesson?.permissionLevel === undefined) {
      return null;
    }

    const ableToAccess = isUserAbleToAccessLesson(
      userPermissionLevel,
      lesson.permissionLevel
    );

    if (ableToAccess) {
      // User is able to access the lesson
      if (!active) {
        switch (userPermissionLevel) {
          case UserPermissionLevel.Anonymous:
          case UserPermissionLevel.Free:
            return <FreeLessonLabel />;
          case UserPermissionLevel.Premium:
            return <span className={styles.statusEmpty} />;
        }
      }
      return <BsPlayFill className={styles.statusPlay} />;
    }

    // User is unable to access the lesson
    if (!active) {
      switch (lesson.permissionLevel) {
        case PermissionLevel.FREE:
          return <FreeLessonLabel />;
        case PermissionLevel.PREMIUM:
          return <BsLockFill className={styles.statusLock} />;
      }
    }
    return (
      <BsLockFill className={classNames(styles.statusLock, styles.active)} />
    );
  }, [active, lesson?.permissionLevel, userPermissionLevel]);

  if (!lesson) {
    if (loading) {
      return (
        <div className={classNames(styles.root, className)}>
          <div className={styles.statusCol} />
          <Placeholder className={styles.thumbnailCol} animation="glow">
            <Placeholder
              className={classNames(styles.thumbnailImage, "ratio ratio-1x1")}
              xs={12}
            />
          </Placeholder>
          <div className={styles.detailsCol}>
            <Placeholder className={styles.title} animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
            <Placeholder className={styles.duration} animation="glow">
              <Placeholder xs={4} />
            </Placeholder>
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <Link
      className={classNames(styles.root, className, {
        [styles.active]: active,
      })}
      to={generateLessonPagePath(lesson.slug)}
    >
      <div className={styles.statusCol}>{statusComponent}</div>
      <div className={styles.thumbnailCol}>
        <FixedRatioImage
          className={styles.thumbnailImage}
          aspectRatio={1}
          src={lesson.thumbnail.url}
        />
      </div>
      <div className={styles.detailsCol}>
        <div
          className={classNames(styles.title, {
            [styles.active]: active,
          })}
        >
          {lesson.title}
        </div>
        <DurationDisplay
          className={classNames(styles.duration, {
            [styles.active]: active,
          })}
          valueClass={styles.durationValue}
          duration={lesson.videoLength}
        />
      </div>
    </Link>
  );
});

export default LessonPlaylistItem;
