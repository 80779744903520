import { isApolloError } from "@apollo/client";
import { TFunction } from "i18next";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ShowErrorToast, useErrorToast } from "../contexts/DialogContext";
import { isObservableCancelledPrematurelyError } from "../utils/apollo";
import { isNetworkConnectionError } from "../utils/error";
import { isPolicyError } from "../utils/strapi-gql";

export interface ErrorHandlerContext {
  showErrorToast: ShowErrorToast;
  t: TFunction;
}

export type CustomErrorHandler = (
  error: any,
  context: ErrorHandlerContext
) => boolean;

export type ErrorHandler = (error: any) => void;

export interface UseErrorHandlerOptions {
  name?: string;
  unknownErrorMessage?: string;
  customErrorHandler?: CustomErrorHandler;
  defaultErrorToastCloseCallback?: () => void;
}

export function useImperativeErrorHandler(
  options: UseErrorHandlerOptions
): ErrorHandler {
  const { t } = useTranslation();
  const { showErrorToast } = useErrorToast();

  const defaultErrorToastCloseCallbackRef = useRef<
    UseErrorHandlerOptions["defaultErrorToastCloseCallback"]
  >(options.defaultErrorToastCloseCallback);

  const handlerName = useMemo(() => options.name, [options.name]);
  const unknownErrorMessage = useMemo(
    () =>
      options.unknownErrorMessage ??
      t("hooks.useImperativeErrorHandler.defaultUnknownErrorMessage"),
    [options.unknownErrorMessage, t]
  );
  const customErrorHandler = useMemo(
    () => options.customErrorHandler,
    [options.customErrorHandler]
  );

  const unknownErrorHandler = useCallback(
    (error: any) => {
      // Log error
      if (handlerName) {
        console.error(handlerName, error, { ...error });
      } else {
        console.error(error, { ...error });
      }

      let errorMessage = unknownErrorMessage;
      if (isApolloError(error)) {
        if (isPolicyError(error)) {
          errorMessage = t(
            "hooks.useImperativeErrorHandler.policyErrorMessage"
          );
        } else if (isNetworkConnectionError(error)) {
          errorMessage = t(
            "hooks.useImperativeErrorHandler.networkConnectionErrorMessage"
          );
        }
      }

      // Show alert for unknown error
      showErrorToast(errorMessage)
        .then(() => {
          defaultErrorToastCloseCallbackRef.current?.();
        })
        .catch((error) => console.error(error));
    },
    [handlerName, showErrorToast, t, unknownErrorMessage]
  );

  const mainErrorHandler = useCallback(
    (error: any) => {
      // Temporarily fix "Observable cancelled prematurely" error thrown by apollo-client due to React strict mode.
      // https://github.com/apollographql/apollo-client/issues/7608
      // TODO (nicolas 30May2022): Remove this checking after upgraded apollo-client to 3.6.4 (https://github.com/apollographql/apollo-client/pull/9701).
      if (
        process.env.NODE_ENV === "development" &&
        isObservableCancelledPrematurelyError(error)
      ) {
        console.warn(error);
        return;
      }

      const context: ErrorHandlerContext = {
        showErrorToast,
        t,
      };

      // Try handling error with custom error handler
      if (customErrorHandler?.(error, context)) {
        return;
      }

      // Handle unknown error
      unknownErrorHandler(error);
    },
    [customErrorHandler, unknownErrorHandler, showErrorToast, t]
  );

  const mainErrorHandlerRef = useRef<ErrorHandler>(mainErrorHandler);

  // Bind defaultErrorToastCloseCallbackRef
  useEffect(() => {
    defaultErrorToastCloseCallbackRef.current =
      options.defaultErrorToastCloseCallback;
  }, [options.defaultErrorToastCloseCallback]);

  // Bind handleErrorRef
  useEffect(() => {
    mainErrorHandlerRef.current = mainErrorHandler;
  }, [mainErrorHandler]);

  const wrappedMainErrorHandler = useCallback<ErrorHandler>((...args) => {
    mainErrorHandlerRef.current(...args);
  }, []);

  return wrappedMainErrorHandler;
}

export function useErrorHandler(
  error: any,
  options: UseErrorHandlerOptions
): void {
  const handleError = useImperativeErrorHandler(options);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
}
