import {
  StrapiYepValidationMessageGetterDict,
  StrapiErrorObject,
  StrapiYupValidationErrorDetails,
  StrapiYupValidationInnerError,
  StrapiValidationErrorHandlerOptions,
} from "../models/strapi-error";
import { parseStrapiYupValidationErrorMessage } from "./strapi-yup";

export function isStrapiYupValidationErrorDetails(
  details: Record<string, any>
): details is StrapiYupValidationErrorDetails {
  return (
    "errors" in details &&
    Array.isArray(details["errors"]) &&
    (details["errors"].length === 0
      ? true
      : "name" in details["errors"][0] &&
        "message" in details["errors"][0] &&
        "path" in details["errors"][0] &&
        typeof details["errors"][0]["name"] === "string" &&
        typeof details["errors"][0]["message"] === "string" &&
        Array.isArray(details["errors"][0]["path"]) &&
        typeof details["errors"][0]["path"][0] === "string")
  );
}

export function getDefaultErrorMessageFromStrapiYupError(
  error: StrapiYupValidationInnerError
): string {
  const pathStr = error.path.join(".");
  return `[${pathStr}] ${error.message}`;
}

export function getMessageFromStrapiYupError(
  yupError: StrapiYupValidationInnerError,
  msgGetterDict: StrapiYepValidationMessageGetterDict
): string {
  const pathStr = yupError.path.join(".");
  const details = parseStrapiYupValidationErrorMessage(yupError.message);
  if (details) {
    const messageGetter = msgGetterDict[pathStr];
    const msg = messageGetter?.(details);
    if (msg !== undefined) {
      return msg;
    }
  }
  return getDefaultErrorMessageFromStrapiYupError(yupError);
}

export function tryHandleStrapiValidationError(
  errorObject: StrapiErrorObject,
  options: StrapiValidationErrorHandlerOptions
): boolean {
  const { validationErrorHandler, yupErrorHandler } = options;
  if (
    yupErrorHandler &&
    isStrapiYupValidationErrorDetails(errorObject.details)
  ) {
    const handledList = errorObject.details.errors.map((yupError) =>
      yupErrorHandler(yupError)
    );
    // Treat yup validation error is handled if any inner errors are handled
    return handledList.some((handled) => handled);
  }
  if (validationErrorHandler?.(errorObject.message)) {
    return true;
  }
  return false;
}
