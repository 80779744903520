import React from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import styles from "./InternalLoadingModal.module.scss";

interface Props {
  show: boolean;
}

const InternalLoadingModal: React.FC<Props> = React.memo((props) => {
  const { show } = props;

  return (
    <Modal
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      size="sm"
      centered={true}
      show={show}
    >
      <Modal.Body>
        <Spinner animation="border" />
      </Modal.Body>
    </Modal>
  );
});

export default InternalLoadingModal;
