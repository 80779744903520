import React, { useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import {
  generatePath,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import PageBackground from "../../components/page/PageBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import PageNavHeader from "../../components/page/PageNavHeader";
import PageToolbar from "../../components/page/PageToolbar";
import { UserSubmitQuiz_userSubmitQuiz_quiz } from "../../graphql/__generated__/UserSubmitQuiz";
import { generateHomePagePath } from "../home/HomePage";
import styles from "./QuizResultPage.module.scss";

export interface QuizResultPageLocationState {
  quiz: UserSubmitQuiz_userSubmitQuiz_quiz;
  totalScore: number;
}

export const QUIZ_RESULT_PAGE_PATH_PATTERN = "/quiz-result";

export function generateQuizResultPagePath(): string {
  return generatePath(QUIZ_RESULT_PAGE_PATH_PATTERN, {});
}

const QuizResultPage: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const locationState = location.state as
    | QuizResultPageLocationState
    | undefined;

  const onDoneButtonClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const scoreDescriptionContentComponents = useMemo(() => {
    if (!locationState) {
      return null;
    }

    const totalQuestions = locationState.quiz.questions.length;
    const wrongAnswers =
      locationState.quiz.questions.length - locationState.totalScore;
    const score = locationState.totalScore;

    let comment: string;
    if (wrongAnswers > 0) {
      comment = t("pages.quizResultPage.scoreComment.fail");
    } else {
      comment = t("pages.quizResultPage.scoreComment.allPass");
    }

    return (
      <>
        <div>
          {t("pages.quizResultPage.scoreDescription", {
            totalQuestions,
            wrongAnswers,
            score,
          })}
        </div>
        <div>{comment}</div>
      </>
    );
  }, [locationState, t]);

  if (!locationState) {
    return <Navigate to={generateHomePagePath()} replace={true} />;
  }

  return (
    <div className={styles.root}>
      <PageNavHeader backButtonText={t("pages.quizResultPage.back")} />
      <PageToolbar contentClass={styles.toolbarContent}>
        <span className={styles.quizTitle}>{locationState.quiz.title}</span>
        <Button variant="outline-light" onClick={onDoneButtonClick}>
          {t("pages.quizResultPage.done")}
        </Button>
      </PageToolbar>
      <PageContentContainer className={styles.mainContent}>
        <div className={styles.resultCard}>
          <PageBackground
            topLeftBGClass={styles.topLeftBackground}
            bottomRightBGClass={styles.bottomRightBackground}
          />
          <h3 className={styles.scoreLabel}>
            {t("pages.quizResultPage.scoreLabel")}
          </h3>
          <h1 className={styles.scoreValue}>{locationState.totalScore}</h1>
          <div className={styles.scoreDescription}>
            {scoreDescriptionContentComponents}
          </div>
        </div>
      </PageContentContainer>
    </div>
  );
});

export default QuizResultPage;
