import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SortingMethodSelect.module.scss";

interface SortingMethodSelectOption<SortingMethod extends string> {
  value: SortingMethod;
  label: string;
}

interface Props<SortingMethod extends string> {
  className?: string;
  label?: string;
  options: SortingMethodSelectOption<SortingMethod>[];
  value: SortingMethod;
  onChange: (value: SortingMethod) => void;
}

const SortingMethodSelect = React.memo(
  <SortingMethod extends string>(props: Props<SortingMethod>) => {
    const { className, label, options, value, onChange } = props;

    const { t } = useTranslation();

    const _onSortingMethodChange = useCallback(
      (event: React.ChangeEvent<HTMLSelectElement>) => {
        const _sortingMethod = event.target.value as SortingMethod;
        onChange(_sortingMethod);
      },
      [onChange]
    );

    const optionComponents = useMemo(() => {
      return options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ));
    }, [options]);

    return (
      <div className={className}>
        <span className={styles.label}>
          {label ?? t("components.sortingMethodSelect.label")}
        </span>
        <select
          className={styles.select}
          value={value}
          onChange={_onSortingMethodChange}
        >
          {optionComponents}
        </select>
      </div>
    );
  }
);

export default SortingMethodSelect as <SortingMethod extends string>(
  props: Props<SortingMethod>
) => React.ReactElement;
