import classNames from "classnames";
import React, { useMemo } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { BsCalendarEvent, BsClock, BsGeoAlt } from "react-icons/bs";
import { Event } from "../../models/event";
import styles from "./SuggestedEventCard.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  event: Event | undefined;
}

const SuggestedEventCard: React.FC<Props> = React.memo((props) => {
  const { className, loading, event } = props;

  const dateDisplay = useMemo(() => {
    if (!event) {
      return undefined;
    }
    const startTime = event.startTime.format("LL (dddd)");
    if (
      event.startTime.clone().startOf("day").valueOf() ===
      event.endTime.clone().startOf("day").valueOf()
    ) {
      return startTime;
    }
    const endTime = event.endTime.format("LL (dddd)");
    return `${startTime} - ${endTime}`;
  }, [event]);

  const timeDisplay = useMemo(() => {
    if (!event) {
      return undefined;
    }
    return `${event.startTime.format("HH:mm")} - ${event.endTime.format(
      "HH:mm"
    )}`;
  }, [event]);

  if (!event) {
    if (loading) {
      return (
        <div className={classNames(styles.root, className)}>
          <h4 className={classNames(styles.title, "placeholder-glow")}>
            <Placeholder xs={12} />
          </h4>
          <div className={classNames(styles.detailRow, "placeholder-glow")}>
            <BsCalendarEvent />
            <span
              className={classNames(styles.label, "placeholder", "col-12")}
            />
          </div>
          <div className={classNames(styles.detailRow, "placeholder-glow")}>
            <BsClock className={styles.icon} />
            <span
              className={classNames(styles.label, "placeholder", "col-12")}
            />
          </div>
          <div className={classNames(styles.detailRow, "placeholder-glow")}>
            <BsGeoAlt className={styles.icon} />
            <span
              className={classNames(styles.label, "placeholder", "col-12")}
            />
          </div>
          <p className={classNames(styles.remarks, "placeholder-glow")}>
            <Placeholder xs={12} />
            <Placeholder xs={12} />
          </p>
        </div>
      );
    }
    return null;
  }

  return (
    <div className={classNames(styles.root, className)}>
      <h4 className={styles.title}>{event.title}</h4>
      <div className={styles.detailRow}>
        <BsCalendarEvent />
        <span className={styles.label}>{dateDisplay}</span>
      </div>
      <div className={styles.detailRow}>
        <BsClock className={styles.icon} />
        <span className={styles.label}>{timeDisplay}</span>
      </div>
      {event.location && (
        <div className={styles.detailRow}>
          <BsGeoAlt className={styles.icon} />
          <span className={styles.label}>{event.location}</span>
        </div>
      )}
      {event.remarks && <p className={styles.remarks}>{event.remarks}</p>}
    </div>
  );
});

export default SuggestedEventCard;
