import classNames from "classnames";
import React from "react";
import styles from "./PFinCalculatorResultDetailsTableRow.module.scss";

interface Props {
  className?: string;
  labelClass?: string;
  valueClass?: string;
  label?: React.ReactNode;
  value?: React.ReactNode;
}

const PFinCalculatorResultDetailsTableRow: React.FC<Props> = React.memo(
  (props) => {
    const { className, labelClass, valueClass, label, value } = props;
    return (
      <tr className={className}>
        <td>
          <div className={classNames(styles.labelWrapper, labelClass)}>
            {label}
          </div>
        </td>
        <td>
          <div className={classNames(styles.valueWrapper, valueClass)}>
            {value}
          </div>
        </td>
      </tr>
    );
  }
);

export default PFinCalculatorResultDetailsTableRow;
