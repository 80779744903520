import { isApolloError } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { useUserContext } from "../contexts/UserContext";
import { FETCH_ME } from "../graphql/queries";
import { FetchMe } from "../graphql/__generated__/FetchMe";
import { isApolloServerError } from "../utils/apollo";
import { LocalStorage } from "../utils/local-storage";
import { useLazyQuery } from "./useLazyQuery";

interface UseAuthValues {
  /**
   * Try login with access token. Returns false for invalid token.
   *  - Validate access token with fetchMe API
   *  - Persist access token
   *  - Set user data to AuthStateContext
   */
  tryLoginWithAccessToken: (token: string) => Promise<boolean>;
  /**
   * Logout.
   *  - Remove persisted access token
   *  - Unset user data from AuthStateContext
   */
  logout: () => void;
}

export function useAuth(): UseAuthValues {
  const { setUserData, unsetUserData } = useUserContext();

  const [fetchMe] = useLazyQuery<FetchMe>(FETCH_ME);

  const tryLoginWithAccessToken = useCallback(
    async (token: string): Promise<boolean> => {
      try {
        // Validate access token
        const result = await fetchMe({
          context: {
            headers: {
              authorization: `Bearer ${token}`,
            },
            skipLogoutForUnauthorizedRequest: true,
          },
        });
        if (!result.data) {
          return false;
        }

        // Persist access token
        LocalStorage.accessToken.set(token);

        // Set user data to AuthStateContext
        setUserData(token, result.data.fetchMe);

        return true;
      } catch (error: unknown) {
        if (
          error instanceof Error &&
          isApolloError(error) &&
          isApolloServerError(error.networkError) &&
          error.networkError.statusCode === 401
        ) {
          // Unauthorized error
          return false;
        }
        // Throw error for unexpected error
        throw error;
      }
    },
    [fetchMe, setUserData]
  );

  const logout = useCallback(() => {
    // Remove persisted access token
    LocalStorage.accessToken.remove();

    // Unset user data from AuthStateContext
    unsetUserData();
  }, [unsetUserData]);

  const values = useMemo<UseAuthValues>(() => {
    return {
      tryLoginWithAccessToken,
      logout,
    };
  }, [tryLoginWithAccessToken, logout]);

  return values;
}
