import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($identifier: String!, $password: String!, $provider: String) {
    login(
      input: {
        identifier: $identifier
        password: $password
        provider: $provider
      }
    ) {
      jwt
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo(
    $firstName: String
    $lastName: String
    $gender: String
    $phone: String
    $district: String
    $changePassword: UserChangePasswordInput
  ) {
    updateUserInfo(
      userInfo: {
        firstName: $firstName
        lastName: $lastName
        gender: $gender
        phone: $phone
        district: $district
      }
      changePassword: $changePassword
    ) {
      id
      username
      email
      firstName
      lastName
      gender
      permissionLevel
      phone
      district
      createdAt
    }
  }
`;

export const USER_SUBMIT_QUIZ = gql`
  mutation UserSubmitQuiz(
    $quizUuid: ID!
    $questions: [UserQuestionSubmitInput!]!
  ) {
    userSubmitQuiz(
      quizSubmitInput: { quizUuid: $quizUuid, questions: $questions }
    ) {
      uuid
      totalScore
      quiz {
        uuid
        title
        questions {
          uuid
        }
      }
    }
  }
`;
