import classNames from "classnames";
import React from "react";
import { useAppLayout } from "../../contexts/AppLayoutContext";
import styles from "./PageContainerWithBackground.module.scss";

interface Props {
  className?: string;
  backgroundContainerStyle?: string;
  children: React.ReactNode;
  stickyBackground?: boolean;
  backgroundComponent?: React.ReactNode;
}

const PageContainerWithBackground: React.FC<Props> = React.memo((props) => {
  const {
    className,
    backgroundContainerStyle,
    children,
    stickyBackground,
    backgroundComponent,
  } = props;

  const { contentWithOffset } = useAppLayout();

  return (
    <div className={classNames(styles.root, className)}>
      <div
        className={classNames(
          styles.backgroundContainer,
          backgroundContainerStyle,
          {
            [styles.sticky]: stickyBackground,
            [styles.offset]: contentWithOffset,
          }
        )}
      >
        {backgroundComponent}
      </div>
      {children}
    </div>
  );
});

export default PageContainerWithBackground;
