import moment from "moment";
import { TIMEZONE_HK } from "../../constants/moment";
import { UserFetchIncentives_userFetchIncentives_data } from "../../graphql/__generated__/UserFetchIncentives";
import { Incentive } from "../../models/incentive";

export function convertIncentiveFromGQLModel(
  value: UserFetchIncentives_userFetchIncentives_data
): Incentive {
  return {
    uuid: value.uuid,
    title: value.title,
    description: value.description,
    startDate: moment.tz(value.startDate, TIMEZONE_HK),
    endDate: moment.tz(value.endDate, TIMEZONE_HK),
    thumbnail: {
      uuid: value.thumbnail.uuid,
      readSignedUrl: value.thumbnail.readSignedUrl,
    },
    protectedFile: {
      uuid: value.protectedFile.uuid,
      originalFileName: value.protectedFile.originalFileName,
    },
  };
}
