import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { BsBoxArrowLeft } from "react-icons/bs";
import styles from "./LogoutMenuItemContent.module.scss";

interface Props {
  className?: string;
}

const LogoutMenuItemContent: React.FC<Props> = React.memo((props) => {
  const { className } = props;

  const { t } = useTranslation();

  return (
    <div className={classNames(styles.root, className)}>
      <span className={styles.iconWrapper}>
        <BsBoxArrowLeft />
      </span>
      <span className={styles.label}>{t("components.sideMenu.logout")}</span>
    </div>
  );
});

export default LogoutMenuItemContent;
