const EN = {
  pages: {
    homePage: {
      news: {
        title: "最新消息",
        noItems: "沒有最新消息",
      },
      albums: {
        title: "最新相集",
      },
      events: {
        title: "即將活動",
        noItems: "沒有活動",
      },
    },
    purposePage: {
      header: {
        title: "我們的宗旨",
      },
      slogan1: "興旺",
      slogan2: "繁榮",
      slogan3: "昌盛",
      slogan4: "成功",
      mission1: {
        title: "使命",
        description:
          "Prosperity致力以專業誠信的態度為客戶服務。理財富足，使命必達。",
      },
      mission2: {
        title: "使命",
        description:
          "Prosperity視團隊成員為重要資產，打造全方位的平台，羣策羣力達成事業及人身目標。",
      },
      vision: {
        title: "願景",
        description:
          "Prosperity成為行業中首屈一指的銀行財富管家，在業界中產生選響。",
      },
      coreValues: {
        title: "核心價值",
        description: "突破冒險；團結共羸\n專業誠信；關愛社會",
      },
    },
    newsPage: {
      header: {
        title: "最新消息",
      },
      sortingOptions: {
        latest: "最新",
        oldest: "最舊",
      },
      table: {
        createdAt: "日期",
        author: "發怖者",
        title: "內容",
      },
    },
    newsDetailsPage: {
      header: {
        title: "最新消息",
      },
      back: "返回上頁",
      createdAt: "日期: {{date}}",
      author: "發怖者: {{author}}",
    },
    eventsPage: {
      header: {
        title: "即將活動",
      },
      calendarEvents: {
        noResult: "沒有活動",
      },
      upcomingEvents: {
        title: "活動預告",
        noItems: "沒有活動",
      },
    },
    billboardPage: {
      header: {
        title: "每月排行榜",
      },
      submittedCasesChart: {
        title: "Submitted Cases",
        noData: "沒有數據",
      },
      submittedFYCChart: {
        title: "Submitted FYC",
        noData: "沒有數據",
      },
      billboardHeader: {
        ranking: "Rank",
        submittedFYC: "FYC",
        submittedCases: "Cases",
      },
      noItems: "沒有數據",
    },
    accountPage: {
      title: "我的帳戶",
      myProfile: "基本資料",
      emailField: {
        label: "電郵",
      },
      genderField: {
        label: "性別",
        male: "M",
        female: "F",
      },
      phoneField: {
        label: "電話",
      },
      districtField: {
        label: "分區",
      },
      changePassword: "帳戶管理",
      currentPasswordField: {
        label: "現時密碼",
      },
      newPasswordField: {
        label: "新密碼",
      },
      save: "儲存",
      changePasswordError: {
        invalidCurrentPassword: "現時密碼不正確",
        minString: "密碼須至少包含{{ min }}個字符",
      },
      infoUpdatedAlert: {
        title: "我的帳戶",
        message: "已更新",
      },
    },
    lessonCategoriesPage: {
      header: {
        title: "線上課程",
      },
      breadcrumbLabel: "課程類別",
    },
    lessonCategoryPage: {
      header: {
        title: "線上課程",
      },
      notFoundMessage: "Not Found",
      sortingOptions: {
        default: "默認",
        latest: "最新",
        oldest: "最舊",
      },
    },
    lessonPage: {
      lessonDescription: "課堂內容",
      quizNow: "立即進行測驗",
      notFoundMessage: "Not Found",
      forbiddenMessage:
        "Sorry, your account DO NOT have permission to access this lesson.\nPlease login to continue.",
    },
    quizPage: {
      back: "返回上頁",
      submit: "提交",
    },
    quizResultPage: {
      back: "返回上頁",
      done: "完成",
      scoreLabel: "你的得分為",
      scoreDescription:
        "{{ totalQuestions }}題中你答錯了{{ wrongAnswers }}題，總得分為{{ score }}分。",
      scoreComment: {
        fail: "繼續努力，明天會更好哦！",
        allPass: "全對了！",
      },
    },
    leadsPage: {
      header: {
        title: "潛在客戶",
      },
      sortingOptions: {
        latest: "最新",
        oldest: "最舊",
      },
      table: {
        createdAt: "加入日期",
        name: "姓名",
        phone: "聯絡電話",
        email: "電郵",
        status: "狀態",
      },
    },
    loginPage: {
      title: "登入",
      emailField: {
        label: "電郵",
        placeholder: "電郵",
      },
      passwordField: {
        label: "密碼",
        placeholder: "密碼",
      },
      login: "登入",
      googleSignIn: "使用 Google 帳戶登入",
      or: "或",
      copyright: "@ Copyright 2022 Designed by ProphecyHK",
      errors: {
        invalidIdOrPw: "電郵或密碼錯誤",
      },
    },
    googleAuthRedirectPage: {
      emailIsAlreadyTaken: "此電郵已被注冊",
    },
    incentivesPage: {
      header: {
        title: "Incentive",
      },
      sortingOptions: {
        latest: "最新",
        oldest: "最舊",
      },
    },
    albumsPage: {
      header: {
        title: "相簿",
      },
      sortingOptions: {
        latest: "最新",
        oldest: "最舊",
      },
    },
    albumPage: {
      back: "返回相簿",
      loadMorePhotos: "載入更多相片",
      noItems: "沒有相片",
    },
    pFinCalculatorPage: {
      header: {
        title: "保費融資計算機",
      },
      instruction: "請輸入以下資料以作計算：",
      viewInterestRateHistory: "查看過往利率走勢",
      loanGroupTitle: "貸款資料",
      policyGroupTitle: "保單資料",
      reportSettingsGroupTitle: "回報報表設定",
      issuerField: {
        label: "貸款銀行",
      },
      productField: {
        label: "貸款方案",
        placeholder: "請選擇",
      },
      loanRatio: {
        label: "貸款比率",
      },
      loanAmount: {
        label: "貸款金額",
      },
      totalPremiumField: {
        label: "保費總額",
      },
      basicPremiumField: {
        label: "基本保費 (本金)",
      },
      premiumPayingTermField: {
        label: "保費年期",
      },
      excludeHandlingFeeField: {
        label: "分行豁免貸款手續費",
      },
      loanInterestRateField: {
        label: "貸款利率",
      },
      termField: {
        label: "顯示年期",
      },
      reset: "重設",
      calculate: "計算結果",
      errors: {
        noProductMatchTheBP: "基本保費不符合所選旳貸款方案",
      },
    },
    pFinCalculatorResultPage: {
      back: "重新計算",
      toolbar: {
        title: "回報詳情",
        note: "資料僅供參考用途",
      },
      detailsCol: {
        title: "回報演示",
        description: "計算保單年期 - {{ terms }}",
      },
      loanDetails: {
        title: "貸款方案 - {{ productName }}",
        day1SV: "首日退保價值",
        loanAmount: "貸款金額",
      },
      returnDetails: {
        title: "回報演示",
        cashValue: "現金價值總額",
        loanAmount: "貸款金額",
        totalHandlingFee: "貸款總手續費",
        totalInterest: "貸款總利息",
        surrenderValue: "淨退保價值",
        netPremium: "自付淨保費",
        totalNetGrowthAmount: "總淨增值金額",
      },
      summaryTable: {
        yearlyFlatRate: "每年淨回報率 (平息)",
        leveragedIRR: "每年淨回報率 (Leveraged IRR)",
        grossIRR: "貸款前保單毛回報率 (Gross IRR)",
      },
      minus: "減去",
      download: "下載模擬計劃書",
    },
  },
  components: {
    sideMenu: {
      userMenu: {
        guest: "訪客",
        login: "登入",
        viewAccount: "瀏覽帳戶",
      },
      home: "主頁",
      purpose: "宗旨",
      news: "最新消息",
      events: "即將活動",
      billboard: "每月排行",
      potentialCustomers: "潛在客戶",
      courses: "線上課程",
      incentive: "Incentive",
      album: "相簿",
      premiumFinancingCalculator: "保費融資計算機",
      logout: "登出",
      confirmToLogout: "確定要登出？",
    },
    freeLessonLabel: {
      free: "FREE",
    },
    lessonCountDisplay: {
      lessonCount_one: "{{count}}堂課程",
      lessonCount_other: "{{count}}堂課程",
    },
    lessonItem: {
      loginToWatch: {
        text: "Login to Watch",
        tooltipMessage: "Please login to unlock the permission",
      },
      watchButton: "立即觀看",
    },
    alertModal: {
      confirm: "確認",
      cancel: "取消",
    },
    errorModal: {
      title: "錯誤",
      close: "關閉",
    },
    errorToast: {
      title: "錯誤",
    },
    calendarEventCard: {
      locationLabel: "地點:",
      remarksLabel: "備註:",
    },
    dataTable: {
      noData: "沒有資料",
    },
    sortingMethodSelect: {
      label: "排序：",
    },
    incentiveItem: {
      actionButton: "下載資料",
    },
    albumItem: {
      photosCount: "{{count}}張照片",
    },
    pFinCalculatorTermsBarChart: {
      basicPremium: "本金",
      loanAmount: "貸款",
      expense: "支出",
      discount: "優惠",
      return: "回報",
      total: "總數",
    },
    pFinCalculatorTermsTable: {
      term: "年期",
      cashValue: "現金價值總額",
      principal: "本金",
      expense: "總支出",
      return: "淨退保價值",
      netGrowthAmount: "淨增值金額 (增值率)",
    },
  },
  hooks: {
    useImperativeErrorHandler: {
      noPermission: "沒有權限存取",
      defaultUnknownErrorMessage: "未知錯誤",
      policyErrorMessage: "未知錯誤",
      networkConnectionErrorMessage: "暫時無法連接到伺服器，請稍後再試",
      validationErrorDialogTitle: "驗證錯誤",
    },
  },
  units: {
    time: {
      second: {
        short: "秒",
      },
      minute: {
        short: "分鐘",
      },
      hour: {
        short: "小時",
      },
    },
    number: {
      kilo: {
        short: "K",
      },
      mega: {
        short: "M",
      },
    },
    chineseNumber: {
      tenThousand: "萬",
      hundredMillion: "億",
      trillion: "兆",
    },
  },
  currency: {
    hkd: "HKD",
  },
  pFinIssuer: {
    NCB: "南洋商業銀行",
    CYB: "集友銀行",
    BOC: "中國銀行 (香港)",
  },
  term: {
    firstTerm: "首年",
    otherTerm: "第{{ term }}年",
  },
};

export default EN;
