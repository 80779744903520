import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  lessonCount: number;
}

const LessonCountDisplay: React.FC<Props> = React.memo((props) => {
  const { className, lessonCount } = props;
  const { t } = useTranslation();
  return (
    <span className={className}>
      {t("components.lessonCountDisplay.lessonCount", {
        count: lessonCount,
      })}
    </span>
  );
});

export default LessonCountDisplay;
