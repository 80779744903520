/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PermissionLevel {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
}

export interface ListQueryInput {
  keyword?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: any | null;
}

export interface UserChangePasswordInput {
  currentPassword: string;
  newPassword: string;
}

export interface UserQuestionSubmitInput {
  answer: string;
  uuid: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
