import React, { useMemo } from "react";
import Toast from "react-bootstrap/Toast";
import { useTranslation } from "react-i18next";
import styles from "./ErrorToast.module.scss";

interface Props {
  show: boolean;
  title?: string;
  message?: string;
  onRequestClose: () => void;
}

const ErrorToast: React.FC<Props> = React.memo((props) => {
  const { show, title, message, onRequestClose } = props;

  const { t } = useTranslation();

  const content = useMemo(() => {
    if (title === undefined) {
      return (
        <>
          <Toast.Header>
            <strong className="me-auto">
              {t("components.errorToast.title")}
            </strong>
          </Toast.Header>
          <Toast.Body className={styles.toastBody}>{message}</Toast.Body>
        </>
      );
    }
    return (
      <>
        <Toast.Header>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body className={styles.toastBody}>{message}</Toast.Body>
      </>
    );
  }, [message, title, t]);

  return (
    <Toast
      className="text-white"
      animation={true}
      show={show}
      bg="danger"
      onClose={onRequestClose}
    >
      {content}
    </Toast>
  );
});

export default ErrorToast;
