import { isApolloError, useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { useTranslation } from "react-i18next";
import { BsChevronRight, BsLockFill } from "react-icons/bs";
import { generatePath, useParams } from "react-router-dom";
import LinkButton from "../../components/button/LinkButton";
import MaxWidthContainer from "../../components/container/MaxWidthContainer";
import DurationDisplay from "../../components/duration-display/DurationDisplay";
import FreeLessonLabel from "../../components/free-lesson-label/FreeLessonLabel";
import IconButtonContent from "../../components/icon-button-content/IconButtonContent";
import LessonCountDisplay from "../../components/lesson-count-display/LessonCountDisplay";
import LessonPlaylistItem from "../../components/lesson-playlist-item/LessonPlaylistItem";
import PBreadcrumb from "../../components/p-breadcrumb/PBreadcrumb";
import VideoPlayer from "../../components/video-player/VideoPlayer";
import { StrapiErrorName } from "../../constants/strapi";
import { useUserContext } from "../../contexts/UserContext";
import { FETCH_CATEGORY_AND_LESSONS_BY_LESSON } from "../../graphql/queries";
import {
  FetchCategoryAndLessonsByLesson,
  FetchCategoryAndLessonsByLessonVariables,
  FetchCategoryAndLessonsByLesson_fetchCategoryAndLessonsByLesson_lessons_quizzes,
} from "../../graphql/__generated__/FetchCategoryAndLessonsByLesson";
import { PermissionLevel } from "../../graphql/__generated__/globalTypes";
import { useErrorHandlerWithDerivedValue } from "../../hooks/useErrorHandlerWithDerivedValue";
import { isUserAbleToAccessLesson } from "../../utils/lesson";
import { createNumberList } from "../../utils/list";
import { isGraphQLErrorsContainStrapiErrorName } from "../../utils/strapi-gql";
import { generateLessonCategoriesPagePath } from "../lesson-categories/LessonCategoriesPage";
import { generateLessonCategoryPagePath } from "../lesson-category/LessonCategoryPage";
import { generateQuizPagePath } from "../quiz/QuizPage";
import styles from "./LessonPage.module.scss";

type PageParamKey = "slug";

type DerivedDataErrorValue = "notFound";

export const LESSON_PAGE_PATH_PATTERN = "/lesson/:slug";

export function generateLessonPagePath(slug: string): string {
  return generatePath(LESSON_PAGE_PATH_PATTERN, {
    slug,
  });
}

const LessonPage: React.FC = React.memo(() => {
  const pageParams = useParams<PageParamKey>();

  const { t } = useTranslation();
  const { userState } = useUserContext();

  const {
    data: categoryAndLessonsData,
    loading: loadingCategoryAndLessonsData,
    error: fetchCategoryAndLessonsDataError,
  } = useQuery<
    FetchCategoryAndLessonsByLesson,
    FetchCategoryAndLessonsByLessonVariables
  >(FETCH_CATEGORY_AND_LESSONS_BY_LESSON, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    variables: {
      lessonSlug: pageParams.slug!,
    },
  });

  const derivedDataErrorValue =
    useErrorHandlerWithDerivedValue<DerivedDataErrorValue>(
      fetchCategoryAndLessonsDataError,
      {
        name: "fetchCategoryAndLessonsData",
        errorHandler: (error, ctx) => {
          if (isApolloError(error)) {
            if (
              isGraphQLErrorsContainStrapiErrorName(
                error.graphQLErrors,
                StrapiErrorName.NOT_FOUND_ERROR
              )
            ) {
              return ctx.deriveValue("notFound");
            }
          }
          return ctx.unhandled;
        },
      }
    );

  const lessons = useMemo(() => {
    if (!categoryAndLessonsData) {
      return undefined;
    }
    return categoryAndLessonsData.fetchCategoryAndLessonsByLesson.lessons;
  }, [categoryAndLessonsData]);

  const lesson = useMemo(() => {
    if (!lessons) {
      return undefined;
    }
    // Current lesson should exists in lessons under same category
    return lessons.find((lesson) => lesson.slug === pageParams.slug!);
  }, [lessons, pageParams.slug]);

  const category = useMemo(() => {
    if (!categoryAndLessonsData) {
      return undefined;
    }
    return categoryAndLessonsData.fetchCategoryAndLessonsByLesson.category;
  }, [categoryAndLessonsData]);

  const ableToAccessLesson = useMemo(() => {
    if (!lesson) {
      return undefined;
    }
    return isUserAbleToAccessLesson(
      userState.permissionLevel,
      lesson.permissionLevel
    );
  }, [userState.permissionLevel, lesson]);

  const isFreeLesson = useMemo(() => {
    if (!lesson) {
      return undefined;
    }
    return lesson.permissionLevel === PermissionLevel.FREE;
  }, [lesson]);

  const videoContainerComponent = useMemo(() => {
    if (!lesson) {
      if (loadingCategoryAndLessonsData) {
        return (
          <div className={styles.videoContainer}>
            <Placeholder className="ratio ratio-16x9" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        );
      }
      return null;
    }
    return (
      <div className={styles.videoContainer}>
        <VideoPlayer
          className={styles.video}
          ableToAccess={ableToAccessLesson ?? false}
          posterUrl={lesson.thumbnail.url}
          url={lesson.url}
        />
        {!ableToAccessLesson && (
          <div className={styles.permissionDeniedOverlay}>
            <div className={styles.iconWrapper}>
              <BsLockFill className={styles.icon} />
            </div>
            <div className={styles.message}>
              {t("pages.lessonPage.forbiddenMessage")}
            </div>
          </div>
        )}
      </div>
    );
  }, [loadingCategoryAndLessonsData, ableToAccessLesson, lesson, t]);

  const lessonPlaylistItemComponents = useMemo(() => {
    if (!lessons || !lesson) {
      if (loadingCategoryAndLessonsData) {
        return createNumberList(3).map((index) => (
          <LessonPlaylistItem
            key={index}
            loading={true}
            userPermissionLevel={userState.permissionLevel}
            active={false}
            lesson={undefined}
          />
        ));
      }
      return null;
    }
    return lessons.map((_lesson) => (
      <LessonPlaylistItem
        key={_lesson.slug}
        userPermissionLevel={userState.permissionLevel}
        active={_lesson.slug === lesson.slug}
        lesson={_lesson}
      />
    ));
  }, [
    loadingCategoryAndLessonsData,
    lesson,
    lessons,
    userState.permissionLevel,
  ]);

  const quizButtonComponent = useMemo(() => {
    if (!lesson) {
      if (loadingCategoryAndLessonsData) {
        return (
          <Placeholder.Button
            className="btn-rounded"
            variant="secondary"
            xs={8}
          />
        );
      }
      return null;
    }

    const quiz = lesson.quizzes[0] as
      | FetchCategoryAndLessonsByLesson_fetchCategoryAndLessonsByLesson_lessons_quizzes
      | undefined;
    const quizPagePath = quiz ? generateQuizPagePath(quiz.uuid) : "";
    return (
      <LinkButton
        className="btn-rounded"
        variant="secondary"
        to={quizPagePath}
        disabled={!quiz}
      >
        <IconButtonContent rightIcon={<BsChevronRight />}>
          {t("pages.lessonPage.quizNow")}
        </IconButtonContent>
      </LinkButton>
    );
  }, [loadingCategoryAndLessonsData, lesson, t]);

  // eslint-disable-next-line complexity
  const contentComponent = useMemo(() => {
    // If not loading and have no data
    if (!loadingCategoryAndLessonsData && !category && !lesson) {
      return null;
    }
    return (
      <div className={styles.sectionContainer}>
        <section className={styles.lessonSection}>
          {category && lesson ? (
            <PBreadcrumb
              parentItems={[
                {
                  label: t("pages.lessonCategoriesPage.breadcrumbLabel"),
                  to: generateLessonCategoriesPagePath(),
                },
                {
                  label: category.title,
                  to: generateLessonCategoryPagePath(category.slug),
                },
              ]}
              activeItemContent={
                <h2 className={styles.title}>{lesson.title}</h2>
              }
            />
          ) : (
            loadingCategoryAndLessonsData && (
              <Placeholder as="h2" animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
            )
          )}
          {videoContainerComponent}
          <div>
            {lesson ? (
              <DurationDisplay
                className={styles.duration}
                valueClass={styles.durationValue}
                duration={lesson.videoLength}
              />
            ) : (
              loadingCategoryAndLessonsData && (
                <Placeholder animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
              )
            )}
            {isFreeLesson && (
              <FreeLessonLabel className={styles.freeLessonLabel} />
            )}
          </div>
          <div className={styles.detailRow}>
            {lesson ? (
              <>
                <h3 className={styles.detailTitle}>
                  {t("pages.lessonPage.lessonDescription")}
                </h3>
                <p className={styles.detailContent}>{lesson.description}</p>
              </>
            ) : (
              loadingCategoryAndLessonsData && (
                <Placeholder
                  className={styles.detailContent}
                  as="p"
                  animation="glow"
                >
                  <Placeholder xs={12} />
                  <Placeholder xs={12} />
                  <Placeholder xs={12} />
                </Placeholder>
              )
            )}
          </div>
        </section>
        <section className={styles.playlistSection}>
          <div className={styles.quizHeader}>{quizButtonComponent}</div>
          <div className={styles.header}>
            {category ? (
              <>
                <h3 className={styles.title}>{category.title}</h3>
                <div className={styles.categoryDetailsRow}>
                  <LessonCountDisplay
                    className={styles.lessonCount}
                    lessonCount={category.lessonCount}
                  />
                  <DurationDisplay
                    className={styles.duration}
                    valueClass={styles.durationValue}
                    duration={category.totalVideoLength}
                  />
                </div>
              </>
            ) : (
              loadingCategoryAndLessonsData && (
                <>
                  <Placeholder
                    className={styles.title}
                    as="h3"
                    animation="glow"
                  >
                    <Placeholder xs={6} />
                  </Placeholder>
                  <Placeholder
                    className={styles.categoryDetailsRow}
                    animation="glow"
                  >
                    <Placeholder className={styles.lessonCount} xs={4} />
                    <Placeholder className={styles.duration} xs={4} />
                  </Placeholder>
                </>
              )
            )}
          </div>
          <div>{lessonPlaylistItemComponents}</div>
        </section>
      </div>
    );
  }, [
    loadingCategoryAndLessonsData,
    category,
    isFreeLesson,
    lesson,
    lessonPlaylistItemComponents,
    videoContainerComponent,
    quizButtonComponent,
    t,
  ]);

  return (
    <MaxWidthContainer className={styles.root}>
      {derivedDataErrorValue === "notFound" && (
        <section className={styles.notFoundContainer}>
          <h2>{t("pages.lessonPage.notFoundMessage")}</h2>
        </section>
      )}
      {contentComponent}
    </MaxWidthContainer>
  );
});

export default LessonPage;
