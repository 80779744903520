import React, { useContext } from "react";
import { SideMenuType } from "../layouts/AppLayout";

export interface AppLayoutContextValue {
  isSideMenuOpened: boolean;
  contentWithOffset: boolean;
  finalMenuType: SideMenuType;
  setIsSideMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppLayoutContext = React.createContext<AppLayoutContextValue | null>(
  null
);

export const AppLayoutContextProvider = AppLayoutContext.Provider;

export function useAppLayout(): AppLayoutContextValue {
  const value = useContext(AppLayoutContext);
  if (!value) {
    throw Error("useAppLayout should be used inside AppLayoutContextProvider");
  }
  return value;
}
