import React, { useMemo } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";
import AppLayout from "../layouts/AppLayout";
import AccountPage, {
  ACCOUNT_PAGE_PATH_PATTERN,
} from "../pages/account/AccountPage";
import HomePage, { HOME_PAGE_PATH_PATTERN } from "../pages/home/HomePage";
// import DemoPage from "../pages/demo/DemoPage";
import LessonCategoryPage, {
  LESSON_CATEGORY_PAGE_PATH_PATTERN,
} from "../pages/lesson-category/LessonCategoryPage";
import LessonPage, {
  LESSON_PAGE_PATH_PATTERN,
} from "../pages/lesson/LessonPage";
import LoginPage, { LOGIN_PAGE_PATH_PATTERN } from "../pages/login/LoginPage";
import GoogleAuthRedirectPage, {
  GOOGLE_AUTH_REDIRECT_PAGE_PATH_PATTERN,
} from "../pages/redirect/GoogleAuthRedirectPage";
import PurposePage, {
  PURPOSE_PAGE_PATH_PATTERN,
} from "../pages/purpose/PurposePage";
import NewsPage, { NEWS_PAGE_PATH_PATTERN } from "../pages/news/NewsPage";
import NewsDetailsPage, {
  NEWS_DETAILS_PAGE_PATH_PATTERN,
} from "../pages/news/NewsDetailsPage";
import EventsPage, {
  EVENTS_PAGE_PATH_PATTERN,
} from "../pages/events/EventsPage";
import BillboardPage, {
  BILLBOARD_PAGE_PATH_PATTERN,
} from "../pages/billboard/BillboardPage";
import LessonCategoriesPage, {
  LESSON_CATEGORIES_PAGE_PATH_PATTERN,
} from "../pages/lesson-categories/LessonCategoriesPage";
import QuizPage, { QUIZ_PAGE_PATH_PATTERN } from "../pages/quiz/QuizPage";
import QuizResultPage, {
  QUIZ_RESULT_PAGE_PATH_PATTERN,
} from "../pages/quiz/QuizResultPage";
import LeadsPage, { LEADS_PAGE_PATH_PATTERN } from "../pages/lead/LeadsPage";
import IncentivesPage, {
  INCENTIVES_PAGE_PATH_PATTERN,
} from "../pages/incentives/IncentivesPage";
import AlbumsPage, {
  ALBUMS_PAGE_PATH_PATTERN,
} from "../pages/album/AlbumsPage";
import AlbumPage, { ALBUM_PAGE_PATH_PATTERN } from "../pages/album/AlbumPage";
import PFinCalculatorPage, {
  P_FIN_CALCULATOR_PAGE_PATH_PATTERN,
} from "../pages/p-fin-calculator/PFinCalculatorPage";
import PFinCalculatorResultPage, {
  P_FIN_CALCULATOR_RESULT_PAGE_PATH_PATTERN,
} from "../pages/p-fin-calculator/PFinCalculatorResultPage";

interface Props {
  className?: string;
}

const AppRouter: React.FC<Props> = React.memo((props) => {
  const { className } = props;

  const { userState } = useUserContext();

  const fallbackPagePath = useMemo(
    () =>
      userState.loggedIn ? HOME_PAGE_PATH_PATTERN : LOGIN_PAGE_PATH_PATTERN,
    [userState.loggedIn]
  );

  const beforeLoginCollapsedLayoutRoutes = useMemo(
    () => (
      <>
        <Route path={LOGIN_PAGE_PATH_PATTERN} element={<LoginPage />} />
      </>
    ),
    []
  );

  const afterLoggedInCollapsedLayoutRoutes = useMemo(
    () => (
      <>
        <Route path={HOME_PAGE_PATH_PATTERN} element={<HomePage />} />
        <Route path={PURPOSE_PAGE_PATH_PATTERN} element={<PurposePage />} />
        <Route path={NEWS_PAGE_PATH_PATTERN} element={<NewsPage />} />
        <Route
          path={NEWS_DETAILS_PAGE_PATH_PATTERN}
          element={<NewsDetailsPage />}
        />
        <Route path={EVENTS_PAGE_PATH_PATTERN} element={<EventsPage />} />
        <Route path={BILLBOARD_PAGE_PATH_PATTERN} element={<BillboardPage />} />
        <Route
          path={LESSON_CATEGORIES_PAGE_PATH_PATTERN}
          element={<LessonCategoriesPage />}
        />
        <Route
          path={LESSON_CATEGORY_PAGE_PATH_PATTERN}
          element={<LessonCategoryPage />}
        />
        <Route path={QUIZ_PAGE_PATH_PATTERN} element={<QuizPage />} />
        <Route
          path={QUIZ_RESULT_PAGE_PATH_PATTERN}
          element={<QuizResultPage />}
        />
        <Route path={LEADS_PAGE_PATH_PATTERN} element={<LeadsPage />} />
        <Route
          path={INCENTIVES_PAGE_PATH_PATTERN}
          element={<IncentivesPage />}
        />
        <Route path={ALBUMS_PAGE_PATH_PATTERN} element={<AlbumsPage />} />
        <Route path={ALBUM_PAGE_PATH_PATTERN} element={<AlbumPage />} />
        <Route path={ACCOUNT_PAGE_PATH_PATTERN} element={<AccountPage />} />
        <Route
          path={P_FIN_CALCULATOR_PAGE_PATH_PATTERN}
          element={<PFinCalculatorPage />}
        />
      </>
    ),
    []
  );

  const afterLoggedInOverlayLayoutRoutes = useMemo(
    () => (
      <>
        <Route path={LESSON_PAGE_PATH_PATTERN} element={<LessonPage />} />
        <Route
          path={P_FIN_CALCULATOR_RESULT_PAGE_PATH_PATTERN}
          element={<PFinCalculatorResultPage />}
        />
      </>
    ),
    []
  );

  return (
    <div className={className}>
      <BrowserRouter>
        <Routes>
          {/* Collapsed layout */}
          <Route element={<AppLayout sideMenuType="collapsed" />}>
            {/* <Route path="/demo" element={<DemoPage />} /> */}
            {!userState.loggedIn && beforeLoginCollapsedLayoutRoutes}
            {userState.loggedIn && afterLoggedInCollapsedLayoutRoutes}
          </Route>

          {/* Overlay layout */}
          <Route element={<AppLayout sideMenuType="overlay" />}>
            {userState.loggedIn && afterLoggedInOverlayLayoutRoutes}
          </Route>

          {/* Routes without app layout */}
          <Route
            path={GOOGLE_AUTH_REDIRECT_PAGE_PATH_PATTERN}
            element={<GoogleAuthRedirectPage />}
          />

          {/* Redirect routes */}
          <Route
            path="/"
            element={<Navigate to={fallbackPagePath} replace={true} />}
          />
          <Route
            path="*"
            element={<Navigate to={fallbackPagePath} replace={true} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
});

export default AppRouter;
