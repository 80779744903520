import moment from "moment";
import { TIMEZONE_HK } from "../../constants/moment";
import { UserFetchBillBoardResult_userFetchBillBoardResult_billboardData } from "../../graphql/__generated__/UserFetchBillBoardResult";
import { BillboardRecord } from "../../models/billboard";

export function convertBillboardRecordFromGQLModel(
  value: UserFetchBillBoardResult_userFetchBillBoardResult_billboardData
): BillboardRecord {
  return {
    uuid: value.uuid,
    name: value.name,
    date: moment.tz(value.date, TIMEZONE_HK),
    submittedCases: value.submittedCases,
    submittedFYC: value.submittedFYC,
  };
}

export function createNameToBillboardRecordsMap(
  records: BillboardRecord[]
): Map<string, BillboardRecord[]> {
  const orderedRecords = records.sort(
    (a, b) => a.date.valueOf() - b.date.valueOf()
  );
  return orderedRecords.reduce((map, record) => {
    const newMap = new Map(map);
    const prevRecords = map.get(record.name) ?? [];
    newMap.set(record.name, [...prevRecords, record]);
    return newMap;
  }, new Map<string, BillboardRecord[]>());
}

export function findBillboardRecordByMonth(
  records: BillboardRecord[],
  monthDate: moment.Moment
): BillboardRecord | undefined {
  const monthStartDate = monthDate.clone().startOf("month");
  const monthEndDate = monthDate.clone().endOf("month");
  return records.find((record) =>
    record.date.isBetween(monthStartDate, monthEndDate, "month", "[]")
  );
}
