import moment from "moment";
import { UserFetchLeads_userFetchLeads_data } from "../../graphql/__generated__/UserFetchLeads";
import { Lead } from "../../models/lead";
import { optional } from "./gql-model-utils";

export function convertLeadFromGQLModel(
  value: UserFetchLeads_userFetchLeads_data
): Lead {
  return {
    name: value.name,
    uuid: value.uuid,
    phone: value.phone,
    status: value.status,
    remarks: optional(value.remarks),
    email: value.email,
    createdAt: moment(value.createdAt),
  };
}
