import { useCallback, useEffect, useState } from "react";
import {
  ErrorHandler,
  ErrorHandlerContext,
  useImperativeErrorHandler,
} from "./useErrorHandler";

interface ReturnTypeHandled {
  type: "handled";
}

interface ReturnTypeUnhandled {
  type: "unhandled";
}

interface ReturnTypeDerivedValue<T> {
  type: "derivedValue";
  value: T;
}

type ReturnType<T> =
  | ReturnTypeHandled
  | ReturnTypeUnhandled
  | ReturnTypeDerivedValue<T>;

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
interface ErrorHandlerWithDerivedValueContext<T = void> {
  showErrorToast: (message: string) => Promise<void>;
  handled: ReturnTypeHandled;
  unhandled: ReturnTypeUnhandled;
  deriveValue: (value: T) => ReturnTypeDerivedValue<T>;
}

export interface UseErrorHandlerWithDerivedValueOptions<T> {
  name?: string;
  unknownErrorMessage?: string;
  errorHandler?: (
    error: any,
    context: ErrorHandlerWithDerivedValueContext<T>
  ) => ReturnType<T>;
}

export function useImperativeErrorHandlerWithDerivedValue<T>(
  options: UseErrorHandlerWithDerivedValueOptions<T>
): [ErrorHandler, () => void, T | undefined] {
  const { name, unknownErrorMessage, errorHandler } = options;

  const [derivedValue, setDerivedValue] = useState<T | undefined>();

  const resetDerivedValue = useCallback(() => {
    setDerivedValue(undefined);
  }, []);

  const customErrorHandler = useCallback(
    // eslint-disable-next-line consistent-return
    (error: any, ctx: ErrorHandlerContext): boolean => {
      // Return unhandled if no errorHandler
      if (!errorHandler) {
        return false;
      }

      // Call options.errorHandler
      const returnedValue = errorHandler(error, {
        showErrorToast: ctx.showErrorToast,
        handled: { type: "handled" },
        unhandled: { type: "unhandled" },
        deriveValue: (value) => ({ type: "derivedValue", value }),
      });

      // Handle return value
      switch (returnedValue.type) {
        case "handled":
          return true;
        case "derivedValue":
          setDerivedValue(returnedValue.value);
          return true;
        case "unhandled":
          return false;
      }
    },
    [errorHandler]
  );

  const handleError = useImperativeErrorHandler({
    name,
    unknownErrorMessage,
    customErrorHandler,
  });

  return [handleError, resetDerivedValue, derivedValue];
}

export function useErrorHandlerWithDerivedValue<T>(
  error: any,
  options: UseErrorHandlerWithDerivedValueOptions<T>
): T | undefined {
  const [handleError, resetDerivedValue, derivedValue] =
    useImperativeErrorHandlerWithDerivedValue<T>(options);

  useEffect(() => {
    if (error) {
      handleError(error);
    } else {
      resetDerivedValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return derivedValue;
}
