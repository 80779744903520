import classNames from "classnames";
import FileSaver from "file-saver";
import React, { useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { USER_PROTECTED_ACCESS } from "../../graphql/queries";
import {
  UserProtectedAccess,
  UserProtectedAccessVariables,
} from "../../graphql/__generated__/UserProtectedAccess";
import { useImperativeErrorHandler } from "../../hooks/useErrorHandler";
import { useLazyQuery } from "../../hooks/useLazyQuery";
import { Incentive } from "../../models/incentive";
import FixedRatioImage from "../fixed-ratio-image/FixedRatioImage";
import IconButtonContent from "../icon-button-content/IconButtonContent";
import styles from "./IncentiveItem.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  incentive: Incentive | undefined;
}

const IncentiveItem: React.FC<Props> = React.memo((props) => {
  const { className, loading, incentive } = props;

  const { t } = useTranslation();

  const [requestProtectedAccess, { loading: loadingProtectedAccess }] =
    useLazyQuery<UserProtectedAccess, UserProtectedAccessVariables>(
      USER_PROTECTED_ACCESS
    );

  const handleRequestProtectedAccessError = useImperativeErrorHandler({
    name: "requestProtectedAccess",
  });

  const dateRangeText = useMemo(() => {
    if (!incentive) {
      return undefined;
    }
    return `${incentive.startDate.format("MMMDo")} - ${incentive.endDate.format(
      "MMMDo"
    )}`;
  }, [incentive]);

  const onActionButtonClick = useCallback(async () => {
    try {
      if (!incentive) {
        return;
      }
      const result = await requestProtectedAccess({
        variables: {
          uuid: incentive.protectedFile.uuid,
        },
      });
      if (result.data) {
        const readSignedUrl = result.data.userProtectedAccess;
        FileSaver.saveAs(
          readSignedUrl,
          incentive.protectedFile.originalFileName
        );
      }
    } catch (error: any) {
      handleRequestProtectedAccessError(error);
    }
  }, [handleRequestProtectedAccessError, incentive, requestProtectedAccess]);

  if (!incentive) {
    if (loading) {
      return (
        <Card className={classNames(styles.card, className)}>
          <Placeholder
            className="ratio"
            animation="glow"
            style={{
              "--bs-aspect-ratio": `${(106 / 193) * 100}%`,
            }}
          >
            <Placeholder xs={12} />
          </Placeholder>
          <Card.Body>
            <Placeholder className={styles.title} as="h4" animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
            <Placeholder className={styles.dateRange} as="div" animation="glow">
              <Placeholder xs={4} />
            </Placeholder>
            <Placeholder className={styles.description} as="p" animation="glow">
              <Placeholder xs={12} />
              <Placeholder xs={12} />
            </Placeholder>
            {/* TODO(nicolas 23Jun2022): adjust button placeholder style */}
            <Placeholder.Button
              className={styles.actionButton}
              variant="primary"
              size="sm"
              xs={6}
            />
          </Card.Body>
        </Card>
      );
    }
    return null;
  }

  return (
    <Card className={classNames(styles.card, className)}>
      <FixedRatioImage
        aspectRatio={193 / 106}
        src={incentive.thumbnail.readSignedUrl}
      />
      <Card.Body>
        <h4 className={styles.title}>{incentive.title}</h4>
        <div className={styles.dateRange}>{dateRangeText}</div>
        <p className={styles.description}>{incentive.description}</p>
        <Button
          className={styles.actionButton}
          variant="primary"
          size="sm"
          disabled={loadingProtectedAccess}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onActionButtonClick}
        >
          <IconButtonContent
            leftIcon={
              loadingProtectedAccess && <Spinner animation="border" size="sm" />
            }
            rightIcon={<MdOutlineArrowForwardIos />}
          >
            {t("components.incentiveItem.actionButton")}
          </IconButtonContent>
        </Button>
      </Card.Body>
    </Card>
  );
});

export default IncentiveItem;
