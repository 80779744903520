import { isApolloError, useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Placeholder } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { BsChevronRight } from "react-icons/bs";
import { generatePath, useNavigate } from "react-router-dom";
import IconButtonContent from "../../components/icon-button-content/IconButtonContent";
import PFinCalculatorCheckInput from "../../components/p-fin-calculator/forms/PFinCalculatorCheckInput";
import PFinCalculatorInputField from "../../components/p-fin-calculator/forms/PFinCalculatorInputField";
import PFinCalculatorPriceField from "../../components/p-fin-calculator/forms/PFinCalculatorPriceField";
import PFinCalculatorRadioField from "../../components/p-fin-calculator/forms/PFinCalculatorRadioField";
import PFinCalculatorSelectField from "../../components/p-fin-calculator/forms/PFinCalculatorSelectField";
import PFinCalculatorTermControl from "../../components/p-fin-calculator/PFinCalculatorTermControl";
import PageBackground from "../../components/page/PageBackground";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import { CURRENCY_HKD } from "../../constants/currency";
import { StrapiErrorMessage } from "../../constants/strapi";
import { LoadingModal } from "../../contexts/DialogContext";
import {
  USER_FETCH_P_FIN_POLICY_DATA,
  USER_FETCH_P_FIN_PRODUCTS,
  USER_FETCH_P_FIN_SIMULATE,
} from "../../graphql/queries";
import {
  UserFetchPFinPolicyData,
  UserFetchPFinPolicyDataVariables,
} from "../../graphql/__generated__/UserFetchPFinPolicyData";
import { UserFetchPFinProducts } from "../../graphql/__generated__/UserFetchPFinProducts";
import {
  UserFetchPFinSimulate,
  UserFetchPFinSimulateVariables,
} from "../../graphql/__generated__/UserFetchPFinSimulate";
import { useDebounce } from "../../hooks/useDebounce";
import {
  useErrorHandler,
  useImperativeErrorHandler,
  CustomErrorHandler,
} from "../../hooks/useErrorHandler";
import { useLazyQuery } from "../../hooks/useLazyQuery";
import { round } from "../../utils/math";
import {
  getPercentageDisplayText,
  getPFinIssuerDisplayText,
  getPriceDisplayText,
} from "../../utils/text";
import styles from "./PFinCalculatorPage.module.scss";
import {
  generatePFinCalculatorResultPagePath,
  PFinCalculatorResultPageLocationState,
} from "./PFinCalculatorResultPage";

const AVAILABLE_ISSUER_SLUGS = ["NCB", "CYB", "BOC"];
const ISSUER_SLUG_DEFAULT_VALUE = "";
const PRODUCT_SLUG_DEFAULT_VALUE = "";
const BASIC_PREMIUM_DEFAULT_VALUE = "100000";
const EXCLUDE_HANDLING_FEE_DEFAULT_VALUE = false;

export const P_FIN_CALCULATOR_PAGE_PATH_PATTERN = "/p-fin-calculator";

export function generatePFinCalculatorPagePath(): string {
  return generatePath(P_FIN_CALCULATOR_PAGE_PATH_PATTERN, {});
}

const PFinCalculatorPage: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: productsData,
    loading: loadingProductsData,
    error: fetchProductsDataError,
  } = useQuery<UserFetchPFinProducts>(USER_FETCH_P_FIN_PRODUCTS);

  const [
    fetchPolicyDataData,
    { data: policyDataData, loading: loadingPolicyDataData },
  ] = useLazyQuery<UserFetchPFinPolicyData, UserFetchPFinPolicyDataVariables>(
    USER_FETCH_P_FIN_POLICY_DATA
  );

  const [fetchPolicySimulation, { loading: loadingPolicySimulation }] =
    useLazyQuery<UserFetchPFinSimulate, UserFetchPFinSimulateVariables>(
      USER_FETCH_P_FIN_SIMULATE
    );

  useErrorHandler(fetchProductsDataError, {
    name: "fetchProductsData",
  });
  const handleFetchPolicyDataDataError = useImperativeErrorHandler({
    name: "fetchPolicyDataData",
    customErrorHandler: useCallback<CustomErrorHandler>((error, ctx) => {
      if (isApolloError(error)) {
        if (error.message === StrapiErrorMessage.NO_PRODUCT_MATCH_THE_BP) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          ctx.showErrorToast(
            ctx.t("pages.pFinCalculatorPage.errors.noProductMatchTheBP"),
            undefined,
            {
              autoDismissDuration: 3000,
            }
          );
          return true;
        }
      }
      return false;
    }, []),
  });
  const handleFetchPolicySimulation = useImperativeErrorHandler({
    name: "fetchPolicySimulation",
  });

  const [issuerSlug, setIssuerSlug] = useState(ISSUER_SLUG_DEFAULT_VALUE);
  const [productSlug, setProductSlug] = useState(PRODUCT_SLUG_DEFAULT_VALUE);
  const [basicPremium, setBasicPremium] = useState(BASIC_PREMIUM_DEFAULT_VALUE);
  const [excludeHandlingFee, setExcludeHandlingFee] = useState(
    EXCLUDE_HANDLING_FEE_DEFAULT_VALUE
  );
  const debouncedBasicPremium = useDebounce(basicPremium, 500);

  /**
   * (nicolas 05Aug2022)
   * User can type non-numeric text to number input field on some devices (e.g. iOS Safari).
   * So `basicPremiumValue` could be NaN.
   */
  const basicPremiumValue = useMemo(
    () => parseFloat(debouncedBasicPremium),
    [debouncedBasicPremium]
  );

  const isIssuerFieldValid = issuerSlug !== "";
  const isProductFieldValid = productSlug !== "";
  const isBasicPremiumFieldValid = !isNaN(basicPremiumValue);
  const isAllLoanAndPolicyFieldsValid =
    isIssuerFieldValid && isProductFieldValid && isBasicPremiumFieldValid;

  const ableToCalculate = isAllLoanAndPolicyFieldsValid && !!policyDataData;

  const selectedPolicyData = useMemo(() => {
    /**
     * (nicolas 05Aug2022)
     * Ignore obtained policy data when fields is invalid,
     * as previous policy data won't changed when fields become invalid.
     */
    if (!isAllLoanAndPolicyFieldsValid) {
      return undefined;
    }
    return policyDataData?.userFetchPFinPolicyData;
  }, [isAllLoanAndPolicyFieldsValid, policyDataData?.userFetchPFinPolicyData]);

  const premiumPayingTerm = useMemo(() => 10, []);

  const filteredProducts = useMemo(() => {
    if (!productsData) {
      return undefined;
    }
    return productsData.userFetchPFinProducts.filter(
      (product) => product.issuer === issuerSlug
    );
  }, [issuerSlug, productsData]);

  const issuerRadioOptions = useMemo(
    () =>
      AVAILABLE_ISSUER_SLUGS.map((issuerSlug) => ({
        id: `pfc-issuer-${issuerSlug}`,
        value: issuerSlug,
        label: getPFinIssuerDisplayText(t, issuerSlug),
      })),
    [t]
  );

  const productSelectOptions = useMemo(
    () => [
      {
        value: "",
        label: t("pages.pFinCalculatorPage.productField.placeholder"),
      },
      ...(filteredProducts ?? []).map((product) => ({
        value: product.productSlug,
        label: product.productName,
      })),
    ],
    [filteredProducts, t]
  );

  const onIssuerFieldChange = useCallback((issuerSlug: string) => {
    setIssuerSlug(issuerSlug);
    setProductSlug("");
  }, []);

  const onProductFieldChange = useCallback((productSlug: string) => {
    setProductSlug(productSlug);
  }, []);

  const onReset = useCallback(() => {
    setIssuerSlug(ISSUER_SLUG_DEFAULT_VALUE);
    setProductSlug(PRODUCT_SLUG_DEFAULT_VALUE);
    setBasicPremium(BASIC_PREMIUM_DEFAULT_VALUE);
    setExcludeHandlingFee(EXCLUDE_HANDLING_FEE_DEFAULT_VALUE);
  }, []);

  const onCalculate = useCallback(() => {
    if (!isAllLoanAndPolicyFieldsValid || !productsData) {
      return;
    }

    const selectedProduct = productsData.userFetchPFinProducts.find(
      (product) => product.productSlug === productSlug
    );
    if (!selectedProduct) {
      return;
    }

    fetchPolicySimulation({
      variables: {
        basicPremium: basicPremiumValue,
        productSlug: productSlug,
      },
    })
      .then((result) => {
        if (result.data) {
          const locationState: PFinCalculatorResultPageLocationState = {
            basicPremium: basicPremiumValue,
            premiumPayingTerm: premiumPayingTerm,
            product: selectedProduct,
            policyData: result.data.userFetchPFinSimulate.policyData,
            pFinReports: result.data.userFetchPFinSimulate.reports,
          };
          navigate(generatePFinCalculatorResultPagePath(), {
            state: locationState,
          });
        }
      })
      .catch((error) => {
        handleFetchPolicySimulation(error);
      });
  }, [
    basicPremiumValue,
    fetchPolicySimulation,
    handleFetchPolicySimulation,
    isAllLoanAndPolicyFieldsValid,
    navigate,
    premiumPayingTerm,
    productSlug,
    productsData,
  ]);

  const loanRatioValueComponent = useMemo(() => {
    if (loadingPolicyDataData) {
      return (
        <Placeholder className={styles.value} animation="glow">
          <Placeholder xs="6" />
        </Placeholder>
      );
    }
    const valueText = selectedPolicyData
      ? getPercentageDisplayText(selectedPolicyData.LRatio, 0)
      : "-";
    return <span className={styles.value}>{valueText}</span>;
  }, [loadingPolicyDataData, selectedPolicyData]);

  const loanAmountValueComponent = useMemo(() => {
    if (loadingPolicyDataData) {
      return (
        <Placeholder className={styles.value} animation="glow">
          <Placeholder xs="12" />
        </Placeholder>
      );
    }
    const valueText = selectedPolicyData
      ? getPriceDisplayText(t, CURRENCY_HKD, selectedPolicyData.LA, 2)
      : "-";
    return <span className={styles.value}>{valueText}</span>;
  }, [loadingPolicyDataData, selectedPolicyData, t]);

  const totalPremiumPriceFieldComponent = useMemo(() => {
    if (loadingPolicyDataData) {
      return (
        <Placeholder className={styles.value} animation="glow">
          <Placeholder xs="12" />
        </Placeholder>
      );
    }
    const valueText = selectedPolicyData ? round(selectedPolicyData.FP, 0) : "";
    return (
      <PFinCalculatorPriceField
        readOnly={true}
        currency={CURRENCY_HKD}
        placeholder="-"
        value={valueText}
      />
    );
  }, [loadingPolicyDataData, selectedPolicyData]);

  const loanGroupComponent = useMemo(() => {
    return (
      <section className={styles.fieldsGroup}>
        <h5 className={styles.title}>
          {t("pages.pFinCalculatorPage.loanGroupTitle")}
        </h5>

        {/* Issuer field */}
        <Form.Group>
          <Form.Label className={styles.fieldLabel}>
            {t("pages.pFinCalculatorPage.issuerField.label")}
          </Form.Label>
          <PFinCalculatorRadioField
            options={issuerRadioOptions}
            value={issuerSlug}
            onChange={onIssuerFieldChange}
          />
        </Form.Group>

        {/* Product field */}
        <Form.Group>
          <Form.Label className={styles.fieldLabel}>
            {t("pages.pFinCalculatorPage.productField.label")}
          </Form.Label>
          <PFinCalculatorSelectField
            options={productSelectOptions}
            value={productSlug}
            onChange={onProductFieldChange}
          />
        </Form.Group>

        {/* Loan details */}
        <div className={styles.loanDetails}>
          <div className={styles.loanDetailItem}>
            <span className={styles.label}>
              {t("pages.pFinCalculatorPage.loanRatio.label")}
            </span>
            {loanRatioValueComponent}
          </div>
          <div className={styles.loanDetailItem}>
            <span className={styles.label}>
              {t("pages.pFinCalculatorPage.loanAmount.label")}
            </span>
            {loanAmountValueComponent}
          </div>
        </div>
      </section>
    );
  }, [
    issuerRadioOptions,
    issuerSlug,
    loanAmountValueComponent,
    loanRatioValueComponent,
    onIssuerFieldChange,
    onProductFieldChange,
    productSelectOptions,
    productSlug,
    t,
  ]);

  const policyGroupComponent = useMemo(() => {
    return (
      <section className={styles.fieldsGroup}>
        <h5 className={styles.title}>
          {t("pages.pFinCalculatorPage.policyGroupTitle")}
        </h5>

        <Row className="gy-1 align-items-end">
          {/* Total premium field */}
          <Col xs={6}>
            <Form.Label className={styles.fieldLabel}>
              {t("pages.pFinCalculatorPage.totalPremiumField.label")}
            </Form.Label>
            {totalPremiumPriceFieldComponent}
          </Col>

          {/* Basic premium field */}
          <Col xs={6}>
            <Form.Label className={styles.fieldLabel}>
              {t("pages.pFinCalculatorPage.basicPremiumField.label")}
            </Form.Label>
            <PFinCalculatorPriceField
              isInvalid={!isBasicPremiumFieldValid}
              currency={CURRENCY_HKD}
              value={basicPremium}
              onChange={setBasicPremium}
            />
          </Col>

          {/* Premium paying term field */}
          <Col xs={6}>
            <Form.Label className={styles.fieldLabel}>
              {t("pages.pFinCalculatorPage.premiumPayingTermField.label")}
            </Form.Label>
            <PFinCalculatorInputField
              readOnly={true}
              value={premiumPayingTerm}
            />
          </Col>

          {/* Exclude handling fee field */}
          <Col xs={6}>
            <PFinCalculatorCheckInput
              id="pfc-exclude-handling-fee"
              label={t(
                "pages.pFinCalculatorPage.excludeHandlingFeeField.label"
              )}
              checked={excludeHandlingFee}
              onChange={setExcludeHandlingFee}
            />
          </Col>
        </Row>
      </section>
    );
  }, [
    basicPremium,
    excludeHandlingFee,
    isBasicPremiumFieldValid,
    premiumPayingTerm,
    t,
    totalPremiumPriceFieldComponent,
  ]);

  const reportSettingsGroupComponent = useMemo(() => {
    return (
      <section className={styles.fieldsGroup}>
        <h5 className={styles.title}>
          {t("pages.pFinCalculatorPage.reportSettingsGroupTitle")}
        </h5>

        {/* Term control */}
        <div className={styles.termContainer}>
          <Form.Label className={styles.fieldLabel}>
            {t("pages.pFinCalculatorPage.termField.label")}
          </Form.Label>
          <PFinCalculatorTermControl
            className={styles.termControl}
            termA={"6"}
            termB={"7"}
            termC={"9"}
          />
        </div>
      </section>
    );
  }, [t]);

  // Fetch policy data when related fields changed
  useEffect(() => {
    if (!isAllLoanAndPolicyFieldsValid) {
      return;
    }

    fetchPolicyDataData({
      variables: {
        basicPremium: basicPremiumValue,
        productSlug: productSlug,
      },
    }).catch((error) => {
      handleFetchPolicyDataDataError(error);
    });
  }, [
    basicPremiumValue,
    fetchPolicyDataData,
    handleFetchPolicyDataDataError,
    isAllLoanAndPolicyFieldsValid,
    productSlug,
  ]);

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        title={t("pages.pFinCalculatorPage.header.title")}
        backgroundImageSrc="/img/p-fin-calculator/p-fin-calculator-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        <div className={styles.instructionRow}>
          <span className={styles.instruction}>
            {t("pages.pFinCalculatorPage.instruction")}
          </span>
          {/* TODO: view interest rate history */}
          {/* <Button
            className={styles.viewInterestRateHistoryButton}
            variant="outline-primary"
          >
            <IconButtonContent leftIcon={<BsSearch />}>
              {t("pages.pFinCalculatorPage.viewInterestRateHistory")}
            </IconButtonContent>
          </Button> */}
        </div>
        <Row className="g-3">
          <Col md={6}>{loanGroupComponent}</Col>
          <Col md={6}>{policyGroupComponent}</Col>
          <Col md={12}>{reportSettingsGroupComponent}</Col>
        </Row>
        <Row className="justify-content-end g-1 mt-3">
          <Col xs="auto">
            <Button
              className="btn-rounded"
              variant="outline-bombay"
              onClick={onReset}
            >
              {t("pages.pFinCalculatorPage.reset")}
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              className="btn-rounded"
              variant="primary"
              disabled={!ableToCalculate}
              onClick={onCalculate}
            >
              <IconButtonContent rightIcon={<BsChevronRight />}>
                {t("pages.pFinCalculatorPage.calculate")}
              </IconButtonContent>
            </Button>
          </Col>
        </Row>

        <LoadingModal show={loadingProductsData || loadingPolicySimulation} />
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default PFinCalculatorPage;
