import classNames from "classnames";
import React from "react";
import moment from "moment-timezone";
import styles from "./SimpleNewsItem.module.scss";
import { Link, To } from "react-router-dom";

interface Props {
  className?: string;
  date: moment.Moment;
  title: string;
  pinned: boolean;
  to: To;
}

const SimpleNewsItem: React.FC<Props> = React.memo((props) => {
  const { className, date, title, pinned, to } = props;
  return (
    <Link className={classNames(styles.root, className)} to={to}>
      <span
        className={classNames(styles.statusIndicator, {
          [styles.pinned]: pinned,
        })}
      />
      <div className={styles.date}>{date.format("MMMDo")}</div>
      <div className={styles.title}>
        <span>{title}</span>
      </div>
    </Link>
  );
});

export default SimpleNewsItem;
