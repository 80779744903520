import { ApolloError, GraphQLErrors } from "@apollo/client/errors";
import { ErrorResponse } from "@apollo/client/link/error";
import { StrapiErrorName } from "../constants/strapi";
import { StrapiValidationErrorHandlerOptions } from "../models/strapi-error";
import { StrapiGraphQLErrorExtensions } from "../models/strapi-gql";
import { isApolloServerError } from "./apollo";
import { tryHandleStrapiValidationError } from "./strapi-error";

// eslint-disable-next-line complexity
export function isStrapiGraphQLErrorExtensions(
  value: any
): value is StrapiGraphQLErrorExtensions {
  return (
    !!value &&
    typeof value === "object" &&
    "code" in value &&
    "error" in value &&
    typeof value["code"] === "string" &&
    typeof value["error"] === "object" &&
    "name" in value["error"] &&
    "message" in value["error"] &&
    "details" in value["error"] &&
    typeof value["error"]["name"] === "string" &&
    typeof value["error"]["message"] === "string" &&
    typeof value["error"]["details"] === "object"
  );
}

export function isGraphQLErrorsContainStrapiErrorName(
  graphQLErrors: GraphQLErrors,
  strapiErrorName: string
): boolean {
  for (const gqlError of graphQLErrors) {
    if (
      isStrapiGraphQLErrorExtensions(gqlError.extensions) &&
      gqlError.extensions.error.name === strapiErrorName
    ) {
      return true;
    }
  }
  return false;
}

export function isGraphQLErrorsContainStrapiErrorNameAndMessage(
  graphQLErrors: GraphQLErrors,
  strapiErrorName: string,
  strapiErrorMessage: string
): boolean {
  for (const gqlError of graphQLErrors) {
    if (
      isStrapiGraphQLErrorExtensions(gqlError.extensions) &&
      gqlError.extensions.error.name === strapiErrorName &&
      gqlError.extensions.error.message === strapiErrorMessage
    ) {
      return true;
    }
  }
  return false;
}

/**
 * Occurs when authorization token is invalid.
 */
export function isHttp401Error(error: ApolloError | ErrorResponse): boolean {
  return (
    !!error.networkError &&
    isApolloServerError(error.networkError) &&
    error.networkError.statusCode === 401
  );
}

/**
 * Occurs when request protected resources without authorization token.
 */
export function isForbiddenError(error: ApolloError | ErrorResponse): boolean {
  return (
    !!error.graphQLErrors &&
    isGraphQLErrorsContainStrapiErrorName(error.graphQLErrors, "ForbiddenError")
  );
}

/**
 * Occurs when request protected resources with valid authorization token but has not enough privileges.
 */
export function isPolicyError(error: ApolloError | ErrorResponse): boolean {
  return (
    !!error.graphQLErrors &&
    isGraphQLErrorsContainStrapiErrorName(error.graphQLErrors, "PolicyError")
  );
}

export function findStrapiGraphQLErrorExtensionsByName(
  graphQLErrors: GraphQLErrors,
  strapiErrorName: string
): StrapiGraphQLErrorExtensions | undefined {
  for (const gqlError of graphQLErrors) {
    if (
      isStrapiGraphQLErrorExtensions(gqlError.extensions) &&
      gqlError.extensions.error.name === strapiErrorName
    ) {
      return gqlError.extensions;
    }
  }
  return undefined;
}

export function tryHandleStrapiGQLValidationError(
  graphQLErrors: GraphQLErrors,
  options: StrapiValidationErrorHandlerOptions
): boolean {
  const validationErrorExtensions = findStrapiGraphQLErrorExtensionsByName(
    graphQLErrors,
    StrapiErrorName.VALIDATION_ERROR
  );
  if (!validationErrorExtensions) {
    return false;
  }
  return tryHandleStrapiValidationError(
    validationErrorExtensions.error,
    options
  );
}
