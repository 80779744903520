import React, { useCallback, useContext, useMemo, useState } from "react";
import { FetchMe_fetchMe } from "../graphql/__generated__/FetchMe";
import { UserPermissionLevel } from "../models/user-permission-level";
import { UserState } from "../models/user-state";
import { getUserPermissionLevelFromUser } from "../utils/permission-level";
import { isUserObjectChanged } from "../utils/user";

export interface UserContextValue {
  userState: UserState;
  /**
   * Set user & accessToken. Should be called when user log in.
   */
  setUserData: (accessToken: string, user: FetchMe_fetchMe) => void;
  /**
   * Unset user & accessToken. Should be called when user log out.
   */
  unsetUserData: () => void;
  /**
   * Update user and permissionLevel. Works only if logged in.
   */
  updateUser: (user: FetchMe_fetchMe) => void;
}

interface UserContextProviderProps {
  children: React.ReactNode;
}

const UserContext = React.createContext<UserContextValue | null>(null);

export const UserContextProvider: React.FC<UserContextProviderProps> =
  React.memo((props) => {
    const { children } = props;

    const [userState, setUserState] = useState<UserState>({
      loggedIn: false,
      user: undefined,
      accessToken: undefined,
      permissionLevel: UserPermissionLevel.Anonymous,
    });

    const setUserData = useCallback(
      (accessToken: string, user: FetchMe_fetchMe) => {
        setUserState({
          loggedIn: true,
          user,
          accessToken,
          permissionLevel: getUserPermissionLevelFromUser(user.permissionLevel),
        });
      },
      []
    );

    const unsetUserData = useCallback(() => {
      setUserState({
        loggedIn: false,
        user: undefined,
        accessToken: undefined,
        permissionLevel: UserPermissionLevel.Anonymous,
      });
    }, []);

    const updateUser = useCallback((user: FetchMe_fetchMe) => {
      // eslint-disable-next-line complexity
      setUserState((prev) => {
        // Skip if user haven't logged in
        if (!prev.loggedIn) {
          return prev;
        }

        // Update userState only if user object has changed
        const userHasChanged = isUserObjectChanged(user, prev.user);
        if (!userHasChanged) {
          return prev;
        }

        return {
          ...prev,
          user,
          permissionLevel: getUserPermissionLevelFromUser(user.permissionLevel),
        };
      });
    }, []);

    const contextValue = useMemo<UserContextValue>(() => {
      return {
        userState,
        setUserData,
        unsetUserData,
        updateUser,
      };
    }, [userState, setUserData, unsetUserData, updateUser]);

    return (
      <UserContext.Provider value={contextValue}>
        {children}
      </UserContext.Provider>
    );
  });

export function useUserContext(): UserContextValue {
  const contextValue = useContext(UserContext);

  if (!contextValue) {
    throw Error("useUserContext should be used inside UserContextProvider");
  }

  return contextValue;
}
