import React, { useMemo } from "react";
import Image, { ImageProps } from "react-bootstrap/Image";
import { getResponsiveImageProps } from "../../utils/image";

type Props = Omit<ImageProps, "srcSet">;

/**
 * Bootstrap image component with responsive support.
 * Assume following set of images exist:
 *  - `[/path/]name.ext`
 *  - `[/path/]name@2x.ext`
 *  - `[/path/]name@3x.ext`
 */
const ResponsiveImage: React.FC<Props> = React.memo((props) => {
  const { src, ...otherProps } = props;

  const responsiveProps = useMemo(() => {
    if (src === undefined) {
      return {};
    }
    return getResponsiveImageProps(src);
  }, [src]);

  return <Image {...responsiveProps} {...otherProps} />;
});

export default ResponsiveImage;
