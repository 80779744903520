import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./FreeLessonLabel.module.scss";

interface Props {
  className?: string;
}

const FreeLessonLabel: React.FC<Props> = React.memo((props) => {
  const { className } = props;
  const { t } = useTranslation();
  return (
    <span className={classNames(styles.root, className)}>
      {t("components.freeLessonLabel.free")}
    </span>
  );
});

export default FreeLessonLabel;
