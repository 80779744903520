import moment from "moment-timezone";
import { TIMEZONE_HK } from "../constants/moment";

export function createDateListByDateRange(
  startDate: moment.Moment,
  endDate: moment.Moment
): moment.Moment[] {
  const dateList: moment.Moment[] = [];
  for (
    let date = startDate.clone().tz(TIMEZONE_HK).endOf("month");
    date.valueOf() <= endDate.valueOf();
    date.add(1, "day").endOf("month")
  ) {
    dateList.push(date.clone());
  }
  return dateList;
}
