import classNames from "classnames";
import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";
import styles from "./PFinCalculatorCheckInput.module.scss";

interface Props {
  className?: string;
  id: string;
  disabled?: boolean;
  label: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
}

const PFinCalculatorCheckInput: React.FC<Props> = React.memo((props) => {
  const { className, id, label, checked, onChange } = props;

  const _onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.checked);
    },
    [onChange]
  );

  return (
    <Form.Check
      className={classNames(styles.formCheck, className)}
      inline={true}
      type="checkbox"
      id={id}
      label={label}
      checked={checked}
      onChange={_onChange}
    />
  );
});

export default PFinCalculatorCheckInput;
