import React from "react";
import styles from "./PFinCalculatorResultDetailsTableSeparator.module.scss";

interface Props {
  className?: string;
}

const PFinCalculatorResultDetailsTableSeparator: React.FC<Props> = React.memo(
  (props) => {
    const { className } = props;
    return (
      <tr className={className}>
        <td className={styles.td}>
          <hr className={styles.hr} />
        </td>
        <td className={styles.td}>
          <hr className={styles.hr} />
        </td>
      </tr>
    );
  }
);

export default PFinCalculatorResultDetailsTableSeparator;
