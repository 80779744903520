import classNames from "classnames";
import React from "react";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";
import { Link } from "react-router-dom";
import FixedRatioImage from "../../components/fixed-ratio-image/FixedRatioImage";
import { FetchCategories_fetchCategories } from "../../graphql/__generated__/FetchCategories";
import { generateLessonCategoryPagePath } from "../../pages/lesson-category/LessonCategoryPage";
import LessonCountDisplay from "../lesson-count-display/LessonCountDisplay";
import styles from "./LessonCategoryItem.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  category: FetchCategories_fetchCategories | undefined;
}

const LessonCategoryItem: React.FC<Props> = React.memo((props) => {
  const { className, loading, category } = props;

  if (!category) {
    if (loading) {
      return (
        <div className={styles.root}>
          <Card className={classNames(styles.card, className)}>
            <Placeholder
              className="ratio"
              animation="glow"
              style={{
                "--bs-aspect-ratio": `${(106 / 193) * 100}%`,
              }}
            >
              <Placeholder xs={12} />
            </Placeholder>
            <Card.Body>
              <Placeholder className={styles.title} as="div" animation="glow">
                <Placeholder xs={4} />
              </Placeholder>
              <Placeholder className={styles.text} animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
            </Card.Body>
          </Card>
        </div>
      );
    }
    return null;
  }

  return (
    <Link
      className={styles.root}
      to={generateLessonCategoryPagePath(category.slug)}
    >
      <Card className={classNames(styles.card, className)}>
        <FixedRatioImage aspectRatio={193 / 106} src={category.thumbnail.url} />
        <Card.Body>
          <div className={styles.title}>{category.title}</div>
          <LessonCountDisplay
            className={styles.text}
            lessonCount={category.lessonCount}
          />
        </Card.Body>
      </Card>
    </Link>
  );
});

export default LessonCategoryItem;
