import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  defaultDataIdFromObject,
  DefaultOptions,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { TYPE_POLICIES } from "../graphql/type-policies";
import { LocalStorage } from "../utils/local-storage";
import { isHttp401Error } from "../utils/strapi-gql";
import { useUserContext } from "./UserContext";

export interface OperationContext {
  headers?: Record<string, string>;
  skipLogoutForUnauthorizedRequest?: boolean;
}

interface CreateApolloClientContext {
  logoutRef: React.MutableRefObject<() => void>;
  getAccessTokenRef: React.MutableRefObject<() => string | undefined>;
}

function crateApolloClient(uri: string, ctx: CreateApolloClientContext) {
  const errorLink = onError((errorResp) => {
    const operationContext: OperationContext = errorResp.operation.getContext();

    if (
      isHttp401Error(errorResp) &&
      !operationContext.skipLogoutForUnauthorizedRequest
    ) {
      ctx.logoutRef.current();
    }
  });

  const authLink = setContext((_operation, prevContext: OperationContext) => {
    const token = ctx.getAccessTokenRef.current();
    const authHeaderOfAccessToken = token ? `Bearer ${token}` : undefined;
    return {
      headers: {
        ...prevContext.headers,
        authorization:
          // Auth header can be overrode by setting request's context.headers.authorization
          prevContext.headers?.authorization ?? authHeaderOfAccessToken,
      },
    };
  });

  const httpLink = createHttpLink({
    uri,
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "cache-first",
      errorPolicy: "none",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
  };

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: TYPE_POLICIES,
      dataIdFromObject: (object, ctx) => {
        // Return default data id if exists
        const defaultDataId = defaultDataIdFromObject(object, ctx);
        if (defaultDataId !== undefined) {
          return defaultDataId;
        }
        // Return data id from uuid if exists
        if ("uuid" in object && typeof object.uuid === "string") {
          return `${object.__typename}:${object.uuid}`;
        }
        // No id
        return undefined;
      },
    }),
    defaultOptions,
  });

  return client;
}

function useAuthUtils() {
  const { unsetUserData, userState } = useUserContext();

  const logout = useCallback((): void => {
    LocalStorage.accessToken.remove();
    unsetUserData();
  }, [unsetUserData]);

  const getAccessToken = useCallback((): string | undefined => {
    return userState.accessToken;
  }, [userState.accessToken]);

  const logoutRef = useRef(logout);
  const getAccessTokenRef = useRef(getAccessToken);

  useEffect(() => {
    // Bind logoutRef
    logoutRef.current = logout;
  }, [logout]);

  useEffect(() => {
    // Bind getAccessTokenRef
    getAccessTokenRef.current = getAccessToken;
  }, [getAccessToken]);

  return {
    logoutRef,
    getAccessTokenRef,
  };
}

interface Props {
  uri: string;
  children: React.ReactNode;
}

export const ApolloServiceProvider: React.FC<Props> = React.memo((props) => {
  const { uri, children } = props;

  const { logoutRef, getAccessTokenRef } = useAuthUtils();

  const client = useMemo(() => {
    return crateApolloClient(uri, {
      logoutRef,
      getAccessTokenRef,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
});
