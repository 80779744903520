export function getOffset(page: number, itemsPerPage: number): number {
  return (page - 1) * itemsPerPage;
}

export function getMaxPage(itemsCount: number, itemsPerPage: number): number {
  return Math.ceil(itemsCount / itemsPerPage);
}

export function paginateList<T>(
  list: T[],
  page: number,
  itemsPerPage: number
): T[] {
  const offset = getOffset(page, itemsPerPage);
  return list.slice(offset, offset + itemsPerPage);
}
