import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import DataTable, { ColumnDict } from "../../components/table/DataTable";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import SortingMethodSelect from "../../components/sorting/SortingMethodSelect";
import { USER_FETCH_ARTICLES } from "../../graphql/queries";
import {
  UserFetchArticles,
  UserFetchArticlesVariables,
  UserFetchArticles_userFetchArticles_data,
} from "../../graphql/__generated__/UserFetchArticles";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { usePaginatedQuery } from "../../hooks/usePaginatedQuery";
import { generateNewsDetailsPagePath } from "./NewsDetailsPage";
import styles from "./NewsPage.module.scss";
import classNames from "classnames";

enum SortingMethod {
  Latest = "latest",
  Oldest = "oldest",
}

interface NewsData {
  createdAt: moment.Moment;
  author: string;
  title: string;
  link: string;
  pinned: boolean;
}

const ITEMS_PER_PAGE = 10;

const sortingMethodToOrderByObjectDict = {
  [SortingMethod.Latest]: [{ pinned: "desc" }, { createdAt: "desc" }],
  [SortingMethod.Oldest]: [{ pinned: "desc" }, { createdAt: "asc" }],
};

export const NEWS_PAGE_PATH_PATTERN = "/news";

export function generateNewsPagePath(): string {
  return generatePath(NEWS_PAGE_PATH_PATTERN, {});
}

const NewsPage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const [sortingMethod, setSortingMethod] = useState<SortingMethod>(
    SortingMethod.Latest
  );
  const [page, setPage] = useState(1);

  const {
    maxPage,
    paginatedData: articlesData,
    loading: loadingArticlesData,
    error: fetchArticlesError,
  } = usePaginatedQuery<
    UserFetchArticles,
    UserFetchArticles_userFetchArticles_data,
    UserFetchArticlesVariables
  >(USER_FETCH_ARTICLES, {
    pagination: {
      page,
      itemsPerPage: ITEMS_PER_PAGE,
    },
    getVariables: useMemo(
      () => (offset, limit) => ({
        offset,
        limit,
        orderBy: sortingMethodToOrderByObjectDict[sortingMethod],
      }),
      [sortingMethod]
    ),
    getListViewFromData: (data) => data.userFetchArticles,
    onFetchMoreError: useImperativeErrorHandler({ name: "fetchMoreArticles" }),
  });

  useErrorHandler(fetchArticlesError, {
    name: "fetchArticles",
  });

  const sortingMethodOptions = useMemo(
    () => [
      {
        value: SortingMethod.Latest,
        label: t("pages.newsPage.sortingOptions.latest"),
      },
      {
        value: SortingMethod.Oldest,
        label: t("pages.newsPage.sortingOptions.oldest"),
      },
    ],
    [t]
  );

  const newsData = useMemo<NewsData[] | undefined>(() => {
    return articlesData?.map((item) => ({
      createdAt: moment(item.createdAt),
      author: item.author,
      title: item.title,
      link: generateNewsDetailsPagePath(encodeURIComponent(item.uuid)),
      pinned: item.pinned,
    }));
  }, [articlesData]);

  const columns = useMemo<(keyof NewsData)[]>(() => {
    return ["createdAt", "author", "title"];
  }, []);

  const dataTableColumnDict = useMemo<ColumnDict<NewsData>>(() => {
    return {
      createdAt: {
        width: 100,
        headerCell: {
          renderChildren: () => t("pages.newsPage.table.createdAt"),
        },
        dataCell: {
          renderChildren: (data) => (
            <>
              <span
                className={classNames(styles.dataTablePinnedIndicator, {
                  [styles.pinned]: data.pinned,
                })}
              />
              {data.createdAt.format("MMMDo")}
            </>
          ),
        },
      },
      author: {
        width: 100,
        hideBreakpoint: "sm",
        headerCell: {
          renderChildren: () => t("pages.newsPage.table.author"),
        },
        dataCell: {
          renderChildren: (data) => data.author,
        },
      },
      title: {
        headerCell: {
          renderChildren: () => t("pages.newsPage.table.title"),
        },
        dataCell: {
          props: {
            className: styles.dataTableTitleCell,
          },
          renderChildren: (data) => data.title,
        },
      },
    };
  }, [t]);

  const onPreviousPageButtonClick = useCallback(() => {
    setPage((prev) => prev - 1);
  }, []);

  const onNextPageButtonClick = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const onSortingMethodChange = useCallback((sortingMethod: SortingMethod) => {
    // (nicolas 04Jul2022) Reset to page 1 when changing sorting method to prevent invalid paginated data (empty data)
    setPage(1);
    setSortingMethod(sortingMethod);
  }, []);

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        title={t("pages.newsPage.header.title")}
        backgroundImageSrc="/img/news/news-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        <div className={styles.sortingRow}>
          <SortingMethodSelect
            options={sortingMethodOptions}
            value={sortingMethod}
            onChange={onSortingMethodChange}
          />
        </div>
        <DataTable<NewsData>
          tableClass={styles.dataTableTable}
          columns={columns}
          columnDict={dataTableColumnDict}
          data={newsData}
          loading={loadingArticlesData}
          currentPage={page}
          totalPage={maxPage}
          itemsPerPage={ITEMS_PER_PAGE}
          onPreviousPageButtonClick={onPreviousPageButtonClick}
          onNextPageButtonClick={onNextPageButtonClick}
        />
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default NewsPage;
