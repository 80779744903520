import {
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from "@apollo/client";
import { DocumentNode } from "graphql";
import { useEffect, useMemo, useRef } from "react";
import { PaginationListView } from "../models/pagination";
import { getMaxPage, getOffset, paginateList } from "../utils/pagination";

interface PaginationOptions {
  page: number;
  itemsPerPage: number;
}

interface Options<TData, TDataItem, TVariables>
  extends Omit<
    QueryHookOptions<TData, TVariables>,
    "variables" | "notifyOnNetworkStatusChange"
  > {
  pagination: PaginationOptions;
  getVariables: (offset: number, limit: number) => TVariables;
  getListViewFromData: (data: TData) => PaginationListView<TDataItem>;
  onFetchMoreError?: (error: any) => void;
}

interface Result<TData, TDataItem, TVariables>
  extends Pick<QueryResult<TData, TVariables>, "data" | "error" | "loading"> {
  maxPage: number | undefined;
  paginatedData: TDataItem[] | undefined;
}

export function usePaginatedQuery<TData, TDataItem, TVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: Options<TData, TDataItem, TVariables>
): Result<TData, TDataItem, TVariables> {
  const { getVariables, getListViewFromData, onFetchMoreError } = options;

  const offset = getOffset(
    options.pagination.page,
    options.pagination.itemsPerPage
  );

  const onFetchMoreErrorRef = useRef(onFetchMoreError);

  const variables = useMemo<TVariables>(
    () => getVariables(offset, options.pagination.itemsPerPage),
    [offset, getVariables, options.pagination.itemsPerPage]
  );

  const { fetchMore, data, error, loading } = useQuery<TData, TVariables>(
    query,
    {
      ...options,
      notifyOnNetworkStatusChange: true,
      variables: variables,
    }
  );

  const paginatedListView = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return getListViewFromData(data);
  }, [data, getListViewFromData]);

  const maxPage = useMemo<number | undefined>(() => {
    if (!paginatedListView) {
      return undefined;
    }
    return getMaxPage(paginatedListView.count, options.pagination.itemsPerPage);
  }, [paginatedListView, options.pagination.itemsPerPage]);

  const paginatedData = useMemo<TDataItem[] | undefined>(() => {
    if (!paginatedListView) {
      return undefined;
    }
    return paginateList(
      paginatedListView.data,
      options.pagination.page,
      options.pagination.itemsPerPage
    );
  }, [
    paginatedListView,
    options.pagination.itemsPerPage,
    options.pagination.page,
  ]);

  // Fetch more when page changed
  useEffect(() => {
    if (!paginatedListView) {
      return;
    }
    const shouldFetchMore = offset >= paginatedListView.current;
    if (!shouldFetchMore) {
      return;
    }
    fetchMore({
      variables: variables,
    }).catch((error) => {
      if (onFetchMoreErrorRef.current) {
        onFetchMoreErrorRef.current(error);
      } else {
        console.error(error);
      }
    });
  }, [fetchMore, offset, paginatedListView, variables]);

  useEffect(() => {
    onFetchMoreErrorRef.current = onFetchMoreError;
  }, [onFetchMoreError]);

  return {
    error,
    loading,
    maxPage,
    data,
    paginatedData,
  };
}
