import classNames from "classnames";
import React from "react";
import { getPercentageDisplayText } from "../../utils/text";
import styles from "./PFinCalculatorPercentageIndicator.module.scss";

interface Props {
  className?: string;
  fractionDigits?: number;
  value: number;
}

const PFinCalculatorPercentageIndicator: React.FC<Props> = React.memo(
  (props) => {
    const { className, fractionDigits = 1, value } = props;
    return (
      <span
        className={classNames(styles.root, className, {
          [styles.positive]: value > 0,
          [styles.zero]: value === 0,
          [styles.negative]: value < 0,
        })}
      >
        {getPercentageDisplayText(value, fractionDigits, true)}
      </span>
    );
  }
);

export default PFinCalculatorPercentageIndicator;
