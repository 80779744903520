import { useState, useCallback, useMemo } from "react";

interface UseSetValue<T> {
  add: (id: T) => void;
  remove: (id: T) => void;
  value: Set<T>;
}

export function useSet<T>(): UseSetValue<T> {
  const [value, setValue] = useState(() => new Set<T>());

  const add = useCallback((item: T) => {
    setValue((prev) => {
      // Prevent updating set reference when value doesn't changed
      if (prev.has(item)) {
        return prev;
      }
      const newSet = new Set(prev);
      newSet.add(item);
      return newSet;
    });
  }, []);

  const remove = useCallback((item: T) => {
    setValue((prev) => {
      // Prevent updating set reference when value doesn't changed
      if (!prev.has(item)) {
        return prev;
      }
      const newSet = new Set(prev);
      newSet.delete(item);
      return newSet;
    });
  }, []);

  const returnValue = useMemo(
    () => ({
      add,
      remove,
      value,
    }),
    [add, value, remove]
  );

  return returnValue;
}
