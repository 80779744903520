import React, { useCallback, useMemo, useState } from "react";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useIdGenerator } from "../../hooks/useIdGenerator";
import { modifyListItem, removeListItem } from "../../utils/list";
import styles from "./ToastStackContainer.module.scss";

export interface ToastOptions {
  autoDismissDuration?: number;
}

type ShowToast<T> = (
  type: T,
  message: string,
  title?: string,
  options?: ToastOptions
) => Promise<void>;

interface ToastData<T extends string> {
  id: number;
  type: T;
  title?: string;
  message?: string;
  show: boolean;
  onRequestClose: () => void;
}

interface Props {
  children: React.ReactNode;
}

interface UseToastStackContainerValues<T> {
  showToast: ShowToast<T>;
  toastComponents: React.ReactNode[];
}

const FADE_OUT_ANIMATION_DURATION = 150; // in ms

function modifyToastDataListItem<T extends string>(
  list: ToastData<T>[],
  id: number,
  partialValue: Partial<ToastData<T>>
): ToastData<T>[] {
  return modifyListItem(
    list,
    (data) => data.id === id,
    (data) => ({
      ...data,
      ...partialValue,
    })
  );
}

export function useToastStackContainer<T extends string>(
  renderToast: (data: ToastData<T>) => React.ReactNode
): UseToastStackContainerValues<T> {
  const genErrorToastId = useIdGenerator();

  const [toastDataList, setToastDataList] = useState<ToastData<T>[]>([]);

  const toastComponents = useMemo(() => {
    return toastDataList.map((data) => renderToast(data));
  }, [toastDataList, renderToast]);

  const showToast = useCallback<ShowToast<T>>(
    async (type, message, title, options) => {
      return new Promise((resolve) => {
        const id = genErrorToastId();
        const onRequestClose = () => {
          // Fade out
          setToastDataList((prev) =>
            modifyToastDataListItem(prev, id, { show: false })
          );

          // Resolve promise
          resolve(undefined);

          // Remove toast data after fading out
          setTimeout(() => {
            setToastDataList((prev) =>
              removeListItem(prev, (data) => data.id === id)
            );
          }, FADE_OUT_ANIMATION_DURATION);
        };

        // Append new toast data
        setToastDataList((prev) => [
          ...prev,
          {
            id,
            type,
            title,
            message,
            show: false,
            onRequestClose,
          },
        ]);

        // Auto dismiss
        if (options?.autoDismissDuration !== undefined) {
          setTimeout(() => {
            onRequestClose();
          }, options.autoDismissDuration);
        }

        // Show toast after mounting
        setTimeout(() => {
          setToastDataList((prev) =>
            modifyToastDataListItem(prev, id, { show: true })
          );
        }, 0);
      });
    },
    [genErrorToastId]
  );

  return { showToast, toastComponents };
}

const ToastStackContainer: React.FC<Props> = React.memo((props) => {
  const { children } = props;

  return <ToastContainer className={styles.root}>{children}</ToastContainer>;
});

export default ToastStackContainer;
