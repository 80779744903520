import React, { useEffect, useRef } from "react";

interface Props {
  className?: string;
  ableToAccess: boolean;
  posterUrl: string;
  url: string;
}

const VideoPlayer: React.FC<Props> = React.memo((props) => {
  const { className, ableToAccess, posterUrl, url } = props;

  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    ref.current?.load();
  }, [url]);

  return (
    <video className={className} ref={ref} controls={true} poster={posterUrl}>
      {ableToAccess && <source src={url} />}
    </video>
  );
});

export default VideoPlayer;
