import { useQuery } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import IncentiveItem from "../../components/incentive/IncentiveItem";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import SortingMethodSelect from "../../components/sorting/SortingMethodSelect";
import { USER_FETCH_INCENTIVES } from "../../graphql/queries";
import {
  UserFetchIncentives,
  UserFetchIncentivesVariables,
} from "../../graphql/__generated__/UserFetchIncentives";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { createNumberList } from "../../utils/list";
import { convertIncentiveFromGQLModel } from "../../utils/models/incentive";
import styles from "./IncentivesPage.module.scss";

enum SortingMethod {
  Latest = "latest",
  Oldest = "oldest",
}

const sortingMethodToOrderByObjectDict = {
  [SortingMethod.Latest]: { createdAt: "desc" },
  [SortingMethod.Oldest]: { createdAt: "asc" },
};

export const INCENTIVES_PAGE_PATH_PATTERN = "/incentives";

export function generateIncentivesPagePath(): string {
  return generatePath(INCENTIVES_PAGE_PATH_PATTERN, {});
}

const IncentivesPage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const [sortingMethod, setSortingMethod] = useState<SortingMethod>(
    SortingMethod.Latest
  );

  const {
    data: incentivesData,
    loading: loadingIncentives,
    error: incentivesError,
  } = useQuery<UserFetchIncentives, UserFetchIncentivesVariables>(
    USER_FETCH_INCENTIVES,
    {
      variables: {
        orderBy: sortingMethodToOrderByObjectDict[sortingMethod],
      },
    }
  );

  useErrorHandler(incentivesError, {
    name: "incentivesData",
  });

  const sortingMethodOptions = useMemo(
    () => [
      {
        value: SortingMethod.Latest,
        label: t("pages.incentivesPage.sortingOptions.latest"),
      },
      {
        value: SortingMethod.Oldest,
        label: t("pages.incentivesPage.sortingOptions.oldest"),
      },
    ],
    [t]
  );

  const onSortingMethodChange = useCallback((sortingMethod: SortingMethod) => {
    setSortingMethod(sortingMethod);
  }, []);

  const incentiveItemColComponents = useMemo(() => {
    if (loadingIncentives) {
      return createNumberList(4).map((index) => (
        <Col key={index} lg={3} md={4} sm={6} xs={12}>
          <IncentiveItem loading={true} incentive={undefined} />
        </Col>
      ));
    }
    if (!incentivesData) {
      return null;
    }
    return incentivesData.userFetchIncentives.data.map((_incentive) => {
      const incentive = convertIncentiveFromGQLModel(_incentive);
      return (
        <Col key={incentive.uuid} lg={3} md={4} sm={6} xs={12}>
          <IncentiveItem incentive={incentive} />
        </Col>
      );
    });
  }, [incentivesData, loadingIncentives]);

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        title={t("pages.incentivesPage.header.title")}
        backgroundImageSrc="/img/incentives/incentives-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        <div className={styles.sortingRow}>
          <SortingMethodSelect
            options={sortingMethodOptions}
            value={sortingMethod}
            onChange={onSortingMethodChange}
          />
        </div>
        <Row className="g-3">{incentiveItemColComponents}</Row>
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default IncentivesPage;
