import classNames from "classnames";
import FileSaver from "file-saver";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsDownload } from "react-icons/bs";
import { generatePath, Navigate, useLocation } from "react-router-dom";
import IconButtonContent from "../../components/icon-button-content/IconButtonContent";
import PFinCalculatorColoredValue from "../../components/p-fin-calculator/PFinCalculatorColoredValue";
import PFinCalculatorPercentageIndicator from "../../components/p-fin-calculator/PFinCalculatorPercentageIndicator";
import PFinCalculatorTermsBarChart from "../../components/p-fin-calculator/PFinCalculatorTermsBarChart";
import PFinCalculatorTermsTable from "../../components/p-fin-calculator/PFinCalculatorTermsTable";
import PFinCalculatorResultDetailsTable from "../../components/p-fin-calculator/result-details-table/PFinCalculatorResultDetailsTable";
import PFinCalculatorResultDetailsTableRow from "../../components/p-fin-calculator/result-details-table/PFinCalculatorResultDetailsTableRow";
import PFinCalculatorResultDetailsTableSeparator from "../../components/p-fin-calculator/result-details-table/PFinCalculatorResultDetailsTableSeparator";
import PageContentContainer from "../../components/page/PageContentContainer";
import PageNavHeader from "../../components/page/PageNavHeader";
import PageToolbar from "../../components/page/PageToolbar";
import { CURRENCY_HKD } from "../../constants/currency";
import { LoadingModal } from "../../contexts/DialogContext";
import { UserFetchPFinProducts_userFetchPFinProducts } from "../../graphql/__generated__/UserFetchPFinProducts";
import {
  UserFetchPFinSimulate_userFetchPFinSimulate_policyData,
  UserFetchPFinSimulate_userFetchPFinSimulate_reports,
} from "../../graphql/__generated__/UserFetchPFinSimulate";
import { useImperativeErrorHandler } from "../../hooks/useErrorHandler";
import { enhancedHtmlToBlob } from "../../utils/html-to-image";
import {
  getPercentageDisplayText,
  getPriceDisplayText,
} from "../../utils/text";
import { delay, waitUntilNextRepaintBegin } from "../../utils/timer";
import { generateHomePagePath } from "../home/HomePage";
import styles from "./PFinCalculatorResultPage.module.scss";

export interface PFinCalculatorResultPageLocationState {
  basicPremium: number;
  premiumPayingTerm: number;
  product: UserFetchPFinProducts_userFetchPFinProducts;
  policyData: UserFetchPFinSimulate_userFetchPFinSimulate_policyData;
  pFinReports: UserFetchPFinSimulate_userFetchPFinSimulate_reports[];
}

const IGNORE_FOR_RENDERING_CLASS = "hide-during-rendering";

export const P_FIN_CALCULATOR_RESULT_PAGE_PATH_PATTERN =
  "/p-fin-calculator-result";

export function generatePFinCalculatorResultPagePath(): string {
  return generatePath(P_FIN_CALCULATOR_RESULT_PAGE_PATH_PATTERN, {});
}

const PFinCalculatorResultPage: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const location = useLocation();

  const locationState = location.state as
    | PFinCalculatorResultPageLocationState
    | undefined;

  const handleDownloadReportError = useImperativeErrorHandler({
    name: "downloadReport",
  });

  const reportRenderContainerRef = useRef<HTMLDivElement>(null);
  const [
    barChartInitialAnimationCompleted,
    setBarChartInitialAnimationCompleted,
  ] = useState(false);
  const [renderingReport, setGeneratingImage] = useState(false);

  const onBarChartInitialAnimationComplete = useCallback(() => {
    setBarChartInitialAnimationCompleted(true);
  }, []);

  const onDownload = useCallback(async () => {
    try {
      const reportRenderContainer = reportRenderContainerRef.current;
      if (!reportRenderContainer) {
        return;
      }

      setGeneratingImage(true);

      // Wait until DOM updated
      await waitUntilNextRepaintBegin();
      await waitUntilNextRepaintBegin();
      await delay(100);

      const nodeFilterFunc = (node: Node) => {
        if (!(node instanceof HTMLElement)) {
          return true;
        }
        return !node.classList.contains(IGNORE_FOR_RENDERING_CLASS);
      };

      const blob = await enhancedHtmlToBlob(reportRenderContainer, true, {
        pixelRatio: 2,
        filter: nodeFilterFunc,
      });

      if (blob) {
        FileSaver.saveAs(blob, "report.png");
      }
    } catch (error: any) {
      handleDownloadReportError(error);
    } finally {
      setGeneratingImage(false);
    }
  }, [handleDownloadReportError]);

  const loanDetailsComponent = useMemo(() => {
    if (!locationState) {
      return null;
    }

    const { product, policyData } = locationState;

    return (
      <PFinCalculatorResultDetailsTable
        className={styles.resultDetailsTable}
        title={t("pages.pFinCalculatorResultPage.loanDetails.title", {
          productName: product.productName,
        })}
      >
        <PFinCalculatorResultDetailsTableRow
          labelClass={styles.labelCell}
          valueClass={styles.valueCell}
          label={t("pages.pFinCalculatorResultPage.loanDetails.day1SV")}
          value={getPriceDisplayText(t, CURRENCY_HKD, policyData.Day1Sv, 0)}
        />
        <PFinCalculatorResultDetailsTableRow
          labelClass={styles.labelCell}
          valueClass={styles.valueCell}
          label={t("pages.pFinCalculatorResultPage.loanDetails.loanAmount")}
          value={getPriceDisplayText(t, CURRENCY_HKD, policyData.LA, 0)}
        />
      </PFinCalculatorResultDetailsTable>
    );
  }, [locationState, t]);

  const returnDetailsComponent = useMemo(() => {
    if (!locationState) {
      return null;
    }

    const { policyData, pFinReports } = locationState;
    const lastReport = pFinReports[pFinReports.length - 1];

    const cashValue = lastReport.cashValue;
    const loanAmount = policyData.LA;
    const totalHandlingFee = lastReport.accumulatedRepaidCharge;
    const totalInterest = lastReport.accumulatedRepaidInterest;
    const surrenderValue = lastReport.surrenderValAftRepayPrincipalNInterestNC;
    const netPremium = policyData.FP - policyData.LA;
    const totalNetGrowthAmount = lastReport.netGrowthAmount;
    const netGrowthRate = lastReport.netGrowthRate;

    return (
      <PFinCalculatorResultDetailsTable
        className={styles.resultDetailsTable}
        title={t("pages.pFinCalculatorResultPage.returnDetails.title")}
      >
        <PFinCalculatorResultDetailsTableRow
          labelClass={classNames(styles.labelCell, styles.bold)}
          valueClass={classNames(styles.valueCell, styles.bold)}
          label={t("pages.pFinCalculatorResultPage.returnDetails.cashValue")}
          value={getPriceDisplayText(t, CURRENCY_HKD, cashValue, 0)}
        />
        <PFinCalculatorResultDetailsTableRow
          labelClass={styles.labelCell}
          valueClass={classNames(styles.valueCell, styles.negative)}
          label={
            <>
              <span className={styles.minus}>
                {t("pages.pFinCalculatorResultPage.minus")}
              </span>
              <span className={styles.subLabel}>
                {t("pages.pFinCalculatorResultPage.returnDetails.loanAmount")}
              </span>
            </>
          }
          value={getPriceDisplayText(t, CURRENCY_HKD, loanAmount, 0)}
        />
        <PFinCalculatorResultDetailsTableRow
          labelClass={styles.labelCell}
          valueClass={classNames(styles.valueCell, styles.negative)}
          label={
            <>
              <span className={styles.minus}>
                {t("pages.pFinCalculatorResultPage.minus")}
              </span>
              <span className={styles.subLabel}>
                {t(
                  "pages.pFinCalculatorResultPage.returnDetails.totalHandlingFee"
                )}
              </span>
            </>
          }
          value={getPriceDisplayText(t, CURRENCY_HKD, -totalHandlingFee, 0)}
        />
        <PFinCalculatorResultDetailsTableRow
          labelClass={styles.labelCell}
          valueClass={classNames(styles.valueCell, styles.negative)}
          label={
            <>
              <span className={styles.minus}>
                {t("pages.pFinCalculatorResultPage.minus")}
              </span>
              <span className={styles.subLabel}>
                {t(
                  "pages.pFinCalculatorResultPage.returnDetails.totalInterest"
                )}
              </span>
            </>
          }
          value={getPriceDisplayText(t, CURRENCY_HKD, -totalInterest, 0)}
        />

        <PFinCalculatorResultDetailsTableSeparator />

        <PFinCalculatorResultDetailsTableRow
          labelClass={classNames(styles.labelCell, styles.bold)}
          valueClass={classNames(styles.valueCell, styles.bold)}
          label={t(
            "pages.pFinCalculatorResultPage.returnDetails.surrenderValue"
          )}
          value={getPriceDisplayText(t, CURRENCY_HKD, surrenderValue, 0)}
        />
        <PFinCalculatorResultDetailsTableRow
          labelClass={styles.labelCell}
          valueClass={classNames(styles.valueCell, styles.negative)}
          label={
            <>
              <span className={styles.minus}>
                {t("pages.pFinCalculatorResultPage.minus")}
              </span>
              <span className={styles.subLabel}>
                {t("pages.pFinCalculatorResultPage.returnDetails.netPremium")}
              </span>
            </>
          }
          value={getPriceDisplayText(t, CURRENCY_HKD, netPremium, 0)}
        />

        <PFinCalculatorResultDetailsTableSeparator />

        <PFinCalculatorResultDetailsTableRow
          labelClass={classNames(styles.labelCell, styles.bold)}
          valueClass={classNames(styles.valueCell, styles.bold)}
          label={t(
            "pages.pFinCalculatorResultPage.returnDetails.totalNetGrowthAmount"
          )}
          value={
            <PFinCalculatorColoredValue value={totalNetGrowthAmount}>
              {getPriceDisplayText(t, CURRENCY_HKD, totalNetGrowthAmount, 0)}
            </PFinCalculatorColoredValue>
          }
        />
        <PFinCalculatorResultDetailsTableRow
          valueClass={styles.valueCell}
          value={<PFinCalculatorPercentageIndicator value={netGrowthRate} />}
        />
      </PFinCalculatorResultDetailsTable>
    );
  }, [locationState, t]);

  const summaryTableComponent = useMemo(() => {
    if (!locationState) {
      return null;
    }

    const { premiumPayingTerm, pFinReports } = locationState;
    const lastReport = pFinReports[pFinReports.length - 1];

    const yearlyFlatRate = lastReport.netGrowthRate / premiumPayingTerm;
    const leveragedIRR = lastReport.leveragedIRR;
    const grossIRR = lastReport.grossIRR;

    return (
      <div className={styles.summaryTableWrapper}>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td className={classNames(styles.labelCell, styles.bold)}>
                {t(
                  "pages.pFinCalculatorResultPage.summaryTable.yearlyFlatRate"
                )}
              </td>
              <td className={styles.valueCell}>
                <PFinCalculatorColoredValue value={yearlyFlatRate}>
                  {getPercentageDisplayText(yearlyFlatRate, 2)}
                </PFinCalculatorColoredValue>
              </td>
            </tr>
            <tr>
              <td className={classNames(styles.labelCell, styles.bold)}>
                {t("pages.pFinCalculatorResultPage.summaryTable.leveragedIRR")}
              </td>
              <td className={styles.valueCell}>
                {leveragedIRR !== null ? (
                  <PFinCalculatorColoredValue value={leveragedIRR}>
                    {getPercentageDisplayText(leveragedIRR, 2)}
                  </PFinCalculatorColoredValue>
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <td className={styles.labelCell}>
                {t("pages.pFinCalculatorResultPage.summaryTable.grossIRR")}
              </td>
              <td className={styles.valueCell}>
                <PFinCalculatorColoredValue value={grossIRR}>
                  {getPercentageDisplayText(grossIRR, 2)}
                </PFinCalculatorColoredValue>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }, [locationState, t]);

  if (!locationState) {
    return <Navigate to={generateHomePagePath()} replace={true} />;
  }

  return (
    <div className={styles.root}>
      <PageNavHeader
        backButtonText={t("pages.pFinCalculatorResultPage.back")}
      />
      <div
        className={classNames(styles.reportRenderContainer, {
          [styles.rendering]: renderingReport,
        })}
        ref={reportRenderContainerRef}
      >
        <div
          className={classNames(
            styles.reportRenderOverlay,
            IGNORE_FOR_RENDERING_CLASS
          )}
        />
        <PageToolbar contentClass={styles.toolbarContent}>
          <h4 className={styles.title}>
            {t("pages.pFinCalculatorResultPage.toolbar.title")}
          </h4>
          <span className={styles.note}>
            {t("pages.pFinCalculatorResultPage.toolbar.note")}
          </span>
        </PageToolbar>
        <PageContentContainer className={styles.mainContent}>
          <div className={styles.chartCol}>
            <div className={styles.barChartScrollContainer}>
              <PFinCalculatorTermsBarChart
                className={styles.barChart}
                disableTooltips={renderingReport}
                basicPremium={locationState.basicPremium}
                pFinReports={locationState.pFinReports}
                onInitialAnimationComplete={onBarChartInitialAnimationComplete}
              />
            </div>
            <div className={styles.termsTableScrollContainer}>
              <PFinCalculatorTermsTable
                className={styles.termsTable}
                basicPremium={locationState.basicPremium}
                pFinReports={locationState.pFinReports}
              />
            </div>
          </div>
          <div className={styles.detailsCol}>
            <div className={styles.detailsColHeader}>
              <h4 className={styles.title}>
                {t("pages.pFinCalculatorResultPage.detailsCol.title")}
              </h4>
              <p className={styles.description}>
                {t("pages.pFinCalculatorResultPage.detailsCol.description", {
                  terms: locationState.premiumPayingTerm,
                })}
              </p>
            </div>
            {loanDetailsComponent}
            {returnDetailsComponent}
            {summaryTableComponent}
            <Button
              className={classNames("btn-rounded", IGNORE_FOR_RENDERING_CLASS)}
              variant="primary"
              disabled={!barChartInitialAnimationCompleted || renderingReport}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onDownload}
            >
              <IconButtonContent leftIcon={<BsDownload />}>
                {t("pages.pFinCalculatorResultPage.download")}
              </IconButtonContent>
            </Button>
          </div>
        </PageContentContainer>
      </div>
      <LoadingModal show={renderingReport} />
    </div>
  );
});

export default PFinCalculatorResultPage;
