import { useCallback, useEffect, useMemo, useRef } from "react";
import { useIdGenerator } from "./useIdGenerator";

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export interface SharedResourceData<P = void, D = void> {
  join: () => number;
  leave: (id: number) => void;
  use: (id: number, param: P) => Promise<D>;
  release: (id: number) => void;
}

interface UseSharedResourceControllerOptions<P, D> {
  onJoin?: (id: number) => void;
  onLeave?: (id: number) => void;
  onUse: (id: number, param: P) => Promise<D>;
  onRelease: (id: number) => void;
}

interface UseSharedResourceClientValue<P, D> {
  use: (param: P) => Promise<D>;
  release: () => void;
}

export class NoSharedResourceDataError extends Error {
  name = "NoSharedResourceDataError";
}

export class NoIdError extends Error {
  name = "NoIdError";
}

/**
 * Controller hook of shared resource.
 */
export function useSharedResourceController<P, D>(
  options: UseSharedResourceControllerOptions<P, D>
): SharedResourceData<P, D> {
  const { onJoin, onLeave, onUse, onRelease } = options;

  const generateId = useIdGenerator();

  const join = useCallback(() => {
    const id = generateId();
    onJoin?.(id);
    return id;
  }, [generateId, onJoin]);

  const leave = useCallback(
    (id: number) => {
      onLeave?.(id);
    },
    [onLeave]
  );

  const use = useCallback(
    async (id: number, param: P): Promise<D> => {
      return onUse(id, param);
    },
    [onUse]
  );

  const release = useCallback(
    (id: number) => {
      onRelease(id);
    },
    [onRelease]
  );

  // value changed when either options changed
  const value = useMemo<SharedResourceData<P, D>>(() => {
    return {
      join,
      leave,
      use,
      release,
    };
  }, [join, release, leave, use]);

  return value;
}

/**
 * Client hook of shared resource.
 */
export function useSharedResourceClient<P, D>(
  sharedResourceData?: SharedResourceData<P, D>
): UseSharedResourceClientValue<P, D> {
  const idRef = useRef<number | null>(null);
  // Use reference of sharedResourceData to prevent generating new use & release instances
  const sharedResourceDataRef = useRef<SharedResourceData<P, D> | null>(
    sharedResourceData ?? null
  );

  const use = useCallback(async (param: P): Promise<D> => {
    if (!sharedResourceDataRef.current) {
      throw new NoSharedResourceDataError();
    }
    if (idRef.current === null) {
      throw new NoIdError();
    }
    return sharedResourceDataRef.current.use(idRef.current, param);
  }, []);

  const release = useCallback(() => {
    if (!sharedResourceDataRef.current) {
      throw new NoSharedResourceDataError();
    }
    if (idRef.current === null) {
      throw new NoIdError();
    }
    sharedResourceDataRef.current.release(idRef.current);
  }, []);

  // Bind sharedResourceDataRef
  useEffect(() => {
    sharedResourceDataRef.current = sharedResourceData ?? null;
  }, [sharedResourceData]);

  // Handle join & leave life cycle
  useEffect(() => {
    if (!sharedResourceDataRef.current) {
      return undefined;
    }
    const { join, leave } = sharedResourceDataRef.current;
    const id = join();
    idRef.current = id;
    return () => {
      leave(id);
      idRef.current = null;
    };
  }, []);

  // value would't changed
  const value = useMemo(() => {
    return {
      use,
      release,
    };
  }, [release, use]);

  return value;
}
