import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import React, { useCallback, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import Calendar from "../../components/calendar/Calendar";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import CalendarEventCard from "../../components/events/CalendarEventCard";
import SuggestedEventCard from "../../components/events/SuggestedEventCard";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import { TIMEZONE_HK } from "../../constants/moment";
import {
  USER_FETCH_EVENTS,
  USER_FETCH_EVENT_CALENDAR,
} from "../../graphql/queries";
import {
  UserFetchEvents,
  UserFetchEventsVariables,
} from "../../graphql/__generated__/UserFetchEvents";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { createNumberList } from "../../utils/list";
import { convertEventFromGQLModel } from "../../utils/models/event";
import styles from "./EventsPage.module.scss";
import { useLazyQuery } from "../../hooks/useLazyQuery";
import {
  UserFetchEventCalendar,
  UserFetchEventCalendarVariables,
} from "../../graphql/__generated__/UserFetchEventCalendar";

const MAX_LATEST_EVENTS = 12;

export const EVENTS_PAGE_PATH_PATTERN = "/events";

export function generateEventsPagePath(): string {
  return generatePath(EVENTS_PAGE_PATH_PATTERN, {});
}

const EventsPage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(() =>
    moment().tz(TIMEZONE_HK).startOf("day")
  );

  const {
    data: calendarEventsData,
    loading: loadingCalendarEventsData,
    error: calendarEventsError,
  } = useQuery<UserFetchEvents, UserFetchEventsVariables>(USER_FETCH_EVENTS, {
    variables: useMemo(
      () => ({
        startTime: selectedDate.clone().startOf("day").toISOString(),
        endTime: selectedDate.clone().endOf("day").toISOString(),
      }),
      [selectedDate]
    ),
  });

  const {
    data: latestEventsData,
    loading: loadingLatestEventsData,
    error: latestEventsError,
  } = useQuery<UserFetchEvents, UserFetchEventsVariables>(USER_FETCH_EVENTS, {
    variables: useMemo(
      () => ({
        startTime: moment().startOf("day").toISOString(),
        endTime: moment().add(1, "month").endOf("day").toISOString(),
        limit: MAX_LATEST_EVENTS,
      }),
      []
    ),
  });

  const [
    fetchEventCalendar,
    { data: eventCalendarData, loading: loadingEventCalendar },
  ] = useLazyQuery<UserFetchEventCalendar, UserFetchEventCalendarVariables>(
    USER_FETCH_EVENT_CALENDAR
  );

  useErrorHandler(calendarEventsError, {
    name: "calendarEventsData",
  });

  useErrorHandler(latestEventsError, {
    name: "latestEventsData",
  });

  const handleFetchEventCalendarError = useImperativeErrorHandler({
    name: "fetchEventCalendar",
  });

  const selectedDateDisplayString = useMemo(
    () => selectedDate.format("YYYY MMMDo dddd"),
    [selectedDate]
  );

  const calendarEventComponents = useMemo(() => {
    if (!calendarEventsData) {
      if (loadingCalendarEventsData) {
        return createNumberList(2).map((index) => (
          <CalendarEventCard
            key={index}
            className={styles.eventCard}
            loading={true}
            event={undefined}
          />
        ));
      }
      return null;
    }
    if (calendarEventsData.userFetchEvents.data.length === 0) {
      return (
        <div className={styles.noResult}>
          <span>{t("pages.eventsPage.calendarEvents.noResult")}</span>
        </div>
      );
    }
    return calendarEventsData.userFetchEvents.data.map((event) => (
      <CalendarEventCard
        key={event.uuid}
        className={styles.eventCard}
        event={convertEventFromGQLModel(event)}
      />
    ));
  }, [calendarEventsData, loadingCalendarEventsData, t]);

  const upcomingEventColComponents = useMemo(() => {
    if (!latestEventsData) {
      if (loadingLatestEventsData) {
        return createNumberList(MAX_LATEST_EVENTS).map((index) => (
          <Col key={index} md={4} sm={6} xs={12}>
            <SuggestedEventCard
              className={styles.eventCard}
              loading={true}
              event={undefined}
            />
          </Col>
        ));
      }
      return null;
    }
    if (latestEventsData.userFetchEvents.data.length === 0) {
      return (
        <Col md={12}>
          <div className={styles.noItemsMessage}>
            {t("pages.eventsPage.upcomingEvents.noItems")}
          </div>
        </Col>
      );
    }
    return latestEventsData.userFetchEvents.data.map((event) => (
      <Col key={event.uuid} md={4} sm={6} xs={12}>
        <SuggestedEventCard
          className={styles.eventCard}
          event={convertEventFromGQLModel(event)}
        />
      </Col>
    ));
  }, [latestEventsData, loadingLatestEventsData, t]);

  const onCalendarMonthViewChange = useCallback(
    (monthStartDate: moment.Moment) => {
      fetchEventCalendar({
        variables: {
          startDate: monthStartDate.toISOString(true),
          endDate: monthStartDate.clone().endOf("month").toISOString(true),
        },
      }).catch((error) => {
        handleFetchEventCalendarError(error);
      });
    },
    [fetchEventCalendar, handleFetchEventCalendarError]
  );

  const highlightedCalendarDates = useMemo(() => {
    if (!eventCalendarData) {
      return undefined;
    }
    return eventCalendarData.userFetchEventCalendar.dates.map((date) =>
      moment(date)
    );
  }, [eventCalendarData]);

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        title={t("pages.eventsPage.header.title")}
        backgroundImageSrc="/img/events/events-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        <section className={styles.calendarSection}>
          <Calendar
            className={styles.calendar}
            loading={loadingEventCalendar}
            highlightedDates={highlightedCalendarDates}
            date={selectedDate}
            onDateChange={setSelectedDate}
            onMonthViewChange={onCalendarMonthViewChange}
          />
          <div className={styles.calendarEventsContainer}>
            <h4 className={styles.dateLabel}>{selectedDateDisplayString}</h4>
            <div className={styles.eventCardsContainer}>
              {calendarEventComponents}
            </div>
          </div>
        </section>
        <section className={styles.upcomingEventSection}>
          <h3 className={styles.title}>
            {t("pages.eventsPage.upcomingEvents.title")}
          </h3>
          <Row className="g-3">{upcomingEventColComponents}</Row>
        </section>
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default EventsPage;
