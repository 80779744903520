import {
  ChartData,
  ChartOptions,
  Color,
  TickOptions,
  TooltipCallbacks,
  TooltipOptions,
} from "chart.js";
import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { UserFetchPFinSimulate_userFetchPFinSimulate_reports } from "../../graphql/__generated__/UserFetchPFinSimulate";
import pFinCalStyleVariables from "../../styles/p-fin-calculator/variables.module.scss";
import {
  getNumberDisplayTextWithChineseNumberUnit,
  getPercentageDisplayText,
  getTermDisplayText,
} from "../../utils/text";

interface Props {
  className?: string;
  disableTooltips?: boolean;
  basicPremium: number;
  pFinReports: UserFetchPFinSimulate_userFetchPFinSimulate_reports[];
  onInitialAnimationComplete?: () => void;
}

const TOOLTIP_ITEM_H_SPACING = 20;

const PFinCalculatorTermsBarChart: React.FC<Props> = React.memo((props) => {
  const {
    className,
    disableTooltips,
    basicPremium,
    pFinReports,
    onInitialAnimationComplete,
  } = props;

  const { t } = useTranslation();

  const sumList = useMemo(
    () =>
      pFinReports.map(
        (item) =>
          basicPremium +
          item.loanAmount +
          -item.accumulatedRepaidIC +
          0 +
          item.surrenderValAftRepayPrincipalNInterestNC
      ),
    [basicPremium, pFinReports]
  );

  const chartData = useMemo<ChartData<"bar">>(
    () => ({
      labels: pFinReports.map((item) => getTermDisplayText(t, item.policyYear)),
      datasets: [
        {
          label: t("components.pFinCalculatorTermsBarChart.basicPremium"),
          data: pFinReports.map((_) => basicPremium),
          backgroundColor: pFinCalStyleVariables.chartPrincipalColorWithOpacity,
        },
        {
          label: t("components.pFinCalculatorTermsBarChart.loanAmount"),
          data: pFinReports.map((item) => item.loanAmount),
          backgroundColor: pFinCalStyleVariables.chartLoanColorWithOpacity,
        },
        {
          label: t("components.pFinCalculatorTermsBarChart.expense"),
          data: pFinReports.map((item) => -item.accumulatedRepaidIC),
          backgroundColor: pFinCalStyleVariables.chartExpenseColorWithOpacity,
        },
        // {
        //   label: t("components.pFinCalculatorTermsBarChart.discount"),
        //   // TODO(nicolas 08Aug2022): obtain 優惠 value
        //   data: pFinReports.map((_) => 0),
        //   backgroundColor: pFinCalStyleVariables.chartDiscountColorWithOpacity,
        // },
        {
          label: t("components.pFinCalculatorTermsBarChart.return"),
          data: pFinReports.map(
            (item) => item.surrenderValAftRepayPrincipalNInterestNC
          ),
          backgroundColor: pFinCalStyleVariables.chartReturnColorWithOpacity,
        },
      ],
    }),
    [basicPremium, pFinReports, t]
  );

  const chartTooltipTitleCallback = useCallback<
    TooltipCallbacks<"bar">["title"]
  >(
    (ctx) => {
      const { dataIndex } = ctx[0];
      const label = t("components.pFinCalculatorTermsBarChart.total");
      const total = sumList[dataIndex];
      const valText = getNumberDisplayTextWithChineseNumberUnit(t, total, 1);
      const space = " ".repeat(TOOLTIP_ITEM_H_SPACING + 4);
      return `${label}${space}${valText}`;
    },
    [sumList, t]
  );

  const chartTooltipLabelCallback = useCallback<
    TooltipCallbacks<"bar">["label"]
  >(
    (ctx) => {
      const val = ctx.raw as number;
      const valText = getNumberDisplayTextWithChineseNumberUnit(t, val, 1);
      const space = " ".repeat(TOOLTIP_ITEM_H_SPACING);
      return `${ctx.dataset.label ?? ""}${space}${valText}`;
    },
    [t]
  );

  const chartTooltipFooterCallback = useCallback<
    TooltipCallbacks<"bar">["footer"]
  >(
    (ctx) => {
      const { dataIndex } = ctx[0];
      const val = pFinReports[dataIndex].netGrowthRate;
      const valText = getPercentageDisplayText(val, 1, true);
      const space = " ".repeat(TOOLTIP_ITEM_H_SPACING + 11);
      return `${space}${valText}`;
    },
    [pFinReports]
  );

  const chartTooltipFooterColorCallback = useCallback<
    Exclude<TooltipOptions<"bar">["footerColor"], Color>
  >(
    (ctx) => {
      if (!ctx.tooltipItems[0]) {
        return undefined;
      }
      const { dataIndex } = ctx.tooltipItems[0];
      const growthRate = pFinReports[dataIndex].netGrowthRate;
      if (growthRate > 0) {
        return pFinCalStyleVariables.positiveColor;
      }
      if (growthRate < 0) {
        return pFinCalStyleVariables.negativeColor;
      }
      return pFinCalStyleVariables.zeroColor;
    },
    [pFinReports]
  );

  const chartYScaleTicksCallback = useCallback<TickOptions["callback"]>(
    (tickValue) =>
      getNumberDisplayTextWithChineseNumberUnit(t, tickValue as number),
    [t]
  );

  const chartOptions = useMemo<ChartOptions<"bar">>(
    () => ({
      responsive: true,
      aspectRatio: 4 / 3,
      devicePixelRatio: 3,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            boxHeight: 12 / 2,
            boxPadding: 120,
            padding: 24,
            usePointStyle: true,
          },
        },
        tooltip: {
          enabled: !(disableTooltips ?? true),
          usePointStyle: true,
          xAlign: "center",
          yAlign: "bottom",
          padding: {
            top: 15,
            bottom: 15,
            left: 12,
            right: 12,
          },
          backgroundColor: "#6D717B",
          cornerRadius: 2,
          caretSize: 10,
          titleFont: {
            family: "Nunito Sans",
            size: 12,
            weight: "600",
          },
          bodyFont: {
            family: "Nunito Sans",
            size: 12,
          },
          bodySpacing: 4,
          footerFont: {
            family: "Nunito Sans",
            size: 12,
          },
          footerColor: chartTooltipFooterColorCallback,
          callbacks: {
            title: chartTooltipTitleCallback,
            label: chartTooltipLabelCallback,
            footer: chartTooltipFooterCallback,
          },
        },
      },
      interaction: {
        mode: "index",
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          ticks: {
            callback: chartYScaleTicksCallback,
          },
        },
      },
      animation: {
        onComplete: (event) => {
          if (event.initial && onInitialAnimationComplete) {
            onInitialAnimationComplete();
          }
        },
      },
    }),
    [
      chartTooltipFooterCallback,
      chartTooltipFooterColorCallback,
      chartTooltipLabelCallback,
      chartTooltipTitleCallback,
      chartYScaleTicksCallback,
      disableTooltips,
      onInitialAnimationComplete,
    ]
  );

  return (
    <div className={classNames("ratio", "ratio-4x3", className)}>
      <Bar options={chartOptions} data={chartData} />
    </div>
  );
});

export default PFinCalculatorTermsBarChart;
