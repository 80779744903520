import { useRef, useCallback } from "react";

type UseIdGeneratorValue = () => number;

export function useIdGenerator(): UseIdGeneratorValue {
  const latestIdRef = useRef(0);

  const generateId = useCallback(() => {
    const id = latestIdRef.current;
    latestIdRef.current = id + 1;
    return id;
  }, []);

  return generateId;
}
