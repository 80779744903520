import { PermissionLevel } from "../graphql/__generated__/globalTypes";
import { UserPermissionLevel } from "../models/user-permission-level";
import { comparePermissionLevel } from "./permission-level";

export function isUserAbleToAccessLesson(
  userPermissionLevel: UserPermissionLevel,
  lessonPermissionLevel: PermissionLevel
): boolean {
  return (
    comparePermissionLevel(userPermissionLevel, lessonPermissionLevel) >= 0
  );
}
