import { FetchMe_fetchMe } from "../graphql/__generated__/FetchMe";

export function isUserObjectChanged(
  newValue: FetchMe_fetchMe,
  prevValue: FetchMe_fetchMe
): boolean {
  return (
    newValue.id !== prevValue.id ||
    newValue.username !== prevValue.username ||
    newValue.email !== prevValue.email ||
    newValue.firstName !== prevValue.firstName ||
    newValue.lastName !== prevValue.lastName ||
    newValue.gender !== prevValue.gender ||
    newValue.permissionLevel !== prevValue.permissionLevel ||
    newValue.phone !== prevValue.phone ||
    newValue.district !== prevValue.district ||
    newValue.createdAt !== prevValue.createdAt
  );
}
