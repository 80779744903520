import React from "react";
import { useLocation, matchPath } from "react-router-dom";

export function useMatchPathPatterns(patterns: string[]): boolean {
  const { pathname } = useLocation();

  const matched = React.useMemo(
    () =>
      patterns.some((pattern) => {
        const match = matchPath(pattern, pathname);
        return !!match;
      }),
    [patterns, pathname]
  );

  return matched;
}
