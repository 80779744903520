const KEY_ACCESS_TOKEN = "accessToken";

export const LocalStorage = {
  accessToken: {
    get(): string | null {
      return localStorage.getItem(KEY_ACCESS_TOKEN);
    },
    set(value: string): void {
      localStorage.setItem(KEY_ACCESS_TOKEN, value);
    },
    remove(): void {
      localStorage.removeItem(KEY_ACCESS_TOKEN);
    },
  },
};
