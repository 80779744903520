import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Outlet } from "react-router-dom";

import SideMenu from "../components/side-bar/SideMenu";
import { BREAKPOINTS } from "../constants/layout";
import {
  AppLayoutContextProvider,
  AppLayoutContextValue,
} from "../contexts/AppLayoutContext";
import { useBodyScrollLock } from "../hooks/useBodyScrollLock";
import { useEffectSkipFirst } from "../hooks/useEffectSkipFirst";
import { useWindowSize } from "../hooks/useWindowSize";

import styles from "./AppLayout.module.scss";

export type SideMenuType = "collapsed" | "overlay";

interface Props {
  sideMenuType: SideMenuType;
}

const AppLayout: React.FC<Props> = (props) => {
  const { sideMenuType } = props;

  const { width: windowInnerWidth } = useWindowSize();

  const sidebarLayoutRef = useRef<HTMLElement | null>(null);
  const [isCollapsedSideMenuOpened, setIsCollapsedSideMenuOpened] =
    useState(true);
  const [isOverlaySideMenuOpened, setIsOverlaySideMenuOpened] = useState(false);

  const breakpointUpLG = useMemo<boolean>(
    () => windowInnerWidth >= BREAKPOINTS.LG,
    [windowInnerWidth]
  );

  const finalMenuType = useMemo<SideMenuType>(() => {
    if (!breakpointUpLG) {
      return "overlay";
    }
    return sideMenuType;
  }, [sideMenuType, breakpointUpLG]);

  const finalMenuTypeRef = useRef<SideMenuType>(finalMenuType);

  const isSideMenuOpened = useMemo(
    () =>
      finalMenuType === "collapsed"
        ? isCollapsedSideMenuOpened
        : isOverlaySideMenuOpened,
    [finalMenuType, isCollapsedSideMenuOpened, isOverlaySideMenuOpened]
  );

  const contentWithOffset = useMemo<boolean>(() => {
    return finalMenuType === "collapsed" && isCollapsedSideMenuOpened;
  }, [finalMenuType, isCollapsedSideMenuOpened]);

  const backdropVisibility = useMemo<boolean>(() => {
    return finalMenuType === "overlay" && isOverlaySideMenuOpened;
  }, [finalMenuType, isOverlaySideMenuOpened]);

  const onSideMenuRef = useCallback((ref: HTMLElement | null) => {
    sidebarLayoutRef.current =
      ref?.querySelector(".pro-sidebar-layout") ?? null;
  }, []);

  const onBackdropClick = useCallback(() => {
    setIsOverlaySideMenuOpened(false);
  }, []);

  const setIsSideMenuOpened = useCallback<
    React.Dispatch<React.SetStateAction<boolean>>
  >((value) => {
    if (finalMenuTypeRef.current === "collapsed") {
      setIsCollapsedSideMenuOpened(value);
    } else {
      setIsOverlaySideMenuOpened(value);
    }
  }, []);

  const contextValue = useMemo<AppLayoutContextValue>(
    () => ({
      isSideMenuOpened,
      contentWithOffset,
      finalMenuType,
      setIsSideMenuOpened,
    }),
    [isSideMenuOpened, contentWithOffset, finalMenuType, setIsSideMenuOpened]
  );

  // Bind finalMenuTypeRef to finalMenuType
  useEffect(() => {
    finalMenuTypeRef.current = finalMenuType;
  }, [finalMenuType]);

  // Close overlay side menu when menu changed to other type
  useEffectSkipFirst(() => {
    if (finalMenuType !== "overlay") {
      setIsOverlaySideMenuOpened(false);
    }
  }, [finalMenuType]);

  useBodyScrollLock(sidebarLayoutRef.current, isOverlaySideMenuOpened);

  return (
    <div className={classNames(styles.appLayout)}>
      <AppLayoutContextProvider value={contextValue}>
        <SideMenu
          ref={onSideMenuRef}
          className={styles.sideMenu}
          isOpened={isSideMenuOpened}
          setIsOpened={setIsSideMenuOpened}
        />
        <div
          className={classNames(styles.contentContainer, {
            [styles.offset]: contentWithOffset,
          })}
        >
          <Outlet />
        </div>
        <div
          className={classNames(styles.backdrop, {
            [styles.visible]: backdropVisibility,
          })}
          onClick={onBackdropClick}
        />
      </AppLayoutContextProvider>
    </div>
  );
};

export default AppLayout;
