import { gql } from "@apollo/client";

export const FETCH_CATEGORIES = gql`
  query FetchCategories {
    fetchCategories {
      id
      slug
      title
      description
      thumbnail {
        url
      }
      coverImage {
        url
      }
      lessonCount
      totalVideoLength
    }
  }
`;

export const FETCH_CATEGORY = gql`
  query FetchCategory($slug: String!) {
    fetchCategory(slug: $slug) {
      id
      slug
      title
      description
      thumbnail {
        url
      }
      coverImage {
        url
      }
      lessonCount
      totalVideoLength
    }
  }
`;

export const FETCH_LESSONS_BY_CATEGORY = gql`
  query FetchLessonsByCategory(
    $categorySlug: String!
    $offset: Int
    $limit: Int
    $orderBy: JSON
  ) {
    fetchLessonsByCategory(
      categorySlug: $categorySlug
      listQuery: { offset: $offset, limit: $limit, orderBy: $orderBy }
    ) {
      title
      slug
      lessonNumber
      description
      permissionLevel
      thumbnail {
        url
      }
      url
      isPermitted
      videoLength
      quizzes {
        uuid
      }
    }
  }
`;

export const FETCH_CATEGORY_AND_LESSONS_BY_LESSON = gql`
  query FetchCategoryAndLessonsByLesson($lessonSlug: String!) {
    fetchCategoryAndLessonsByLesson(lessonSlug: $lessonSlug) {
      category {
        id
        slug
        title
        description
        thumbnail {
          url
        }
        coverImage {
          url
        }
        lessonCount
        totalVideoLength
      }
      lessons {
        title
        slug
        lessonNumber
        description
        permissionLevel
        thumbnail {
          url
        }
        url
        isPermitted
        videoLength
        quizzes {
          uuid
        }
      }
    }
  }
`;

export const FETCH_ME = gql`
  query FetchMe {
    fetchMe {
      id
      username
      email
      firstName
      lastName
      gender
      permissionLevel
      phone
      district
      createdAt
    }
  }
`;

export const USER_FETCH_ALBUMS = gql`
  query UserFetchAlbums($listQuery: ListQueryInput!) {
    userFetchAlbums(listQuery: $listQuery) {
      count
      current
      data {
        title
        uuid
        description
        albumPhotosCount
        albumCoverPhoto {
          uuid
          image {
            uuid
            readSignedUrl
          }
        }
      }
    }
  }
`;

export const USER_FETCH_ALBUM = gql`
  query UserFetchAlbum($uuid: ID!) {
    userFetchAlbum(uuid: $uuid) {
      uuid
      title
      description
      albumPhotosCount
      albumCoverPhoto {
        uuid
        image {
          uuid
          readSignedUrl
        }
      }
    }
  }
`;

export const USER_FETCH_ALBUM_PHOTOS = gql`
  query UserFetchAlbumPhotos($albumUuid: ID!, $listQuery: ListQueryInput!) {
    userFetchAlbumPhotos(albumUuid: $albumUuid, listQuery: $listQuery) {
      count
      current
      data {
        uuid
        alt
        image {
          readSignedUrl
        }
      }
    }
  }
`;

export const USER_FETCH_ARTICLES = gql`
  query UserFetchArticles($offset: Int!, $limit: Int!, $orderBy: JSON) {
    userFetchArticles(
      listQuery: { offset: $offset, limit: $limit, orderBy: $orderBy }
    ) {
      count
      current
      data {
        uuid
        title
        status
        author
        content
        pinned
        createdAt
      }
    }
  }
`;

export const USER_FETCH_ARTICLE = gql`
  query UserFetchArticle($uuid: ID!) {
    userFetchArticle(uuid: $uuid) {
      uuid
      title
      status
      author
      content
      pinned
      createdAt
    }
  }
`;

export const USER_FETCH_EVENTS = gql`
  query UserFetchEvents(
    $offset: Int
    $limit: Int
    $orderBy: JSON
    $startTime: String
    $endTime: String
  ) {
    userFetchEvents(
      listQuery: { offset: $offset, limit: $limit, orderBy: $orderBy }
      timeQuery: { start: $startTime, end: $endTime }
    ) {
      count
      current
      data {
        title
        uuid
        location
        remarks
        startTime
        endTime
      }
    }
  }
`;

export const USER_FETCH_EVENT_CALENDAR = gql`
  query UserFetchEventCalendar($startDate: String!, $endDate: String!) {
    userFetchEventCalendar(startDate: $startDate, endDate: $endDate) {
      startTime
      endTime
      dates
    }
  }
`;

export const USER_FETCH_BILL_BOARD_RESULT = gql`
  query UserFetchBillBoardResult($startDate: String!, $endDate: String!) {
    userFetchBillBoardResult(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      billboardData {
        uuid
        name
        date
        submittedCases
        submittedFYC
      }
    }
  }
`;

export const USER_FETCH_QUIZ = gql`
  query UserFetchQuiz($uuid: ID!) {
    userFetchQuiz(uuid: $uuid) {
      title
      questions {
        uuid
        content
        answerChoices
      }
    }
  }
`;

export const USER_FETCH_LEADS = gql`
  query UserFetchLeads(
    $offset: Int
    $limit: Int
    $orderBy: JSON
    $status: [String!]
  ) {
    userFetchLeads(
      listQuery: { offset: $offset, limit: $limit, orderBy: $orderBy }
      status: $status
    ) {
      count
      current
      data {
        name
        uuid
        phone
        status
        remarks
        email
        createdAt
      }
    }
  }
`;

export const USER_FETCH_INCENTIVES = gql`
  query UserFetchIncentives($offset: Int, $limit: Int, $orderBy: JSON) {
    userFetchIncentives(
      listQuery: { offset: $offset, limit: $limit, orderBy: $orderBy }
    ) {
      count
      data {
        uuid
        title
        description
        startDate
        endDate
        thumbnail {
          uuid
          readSignedUrl
        }
        protectedFile {
          uuid
          originalFileName
        }
      }
    }
  }
`;

export const USER_PROTECTED_ACCESS = gql`
  query UserProtectedAccess($uuid: ID!) {
    userProtectedAccess(uuid: $uuid)
  }
`;

export const USER_FETCH_P_FIN_PRODUCTS = gql`
  query UserFetchPFinProducts {
    userFetchPFinProducts {
      productSlug
      productName
      issuer
    }
  }
`;

export const USER_FETCH_P_FIN_POLICY_DATA = gql`
  query UserFetchPFinPolicyData($basicPremium: Float!, $productSlug: String!) {
    userFetchPFinPolicyData(
      basicPremium: $basicPremium
      productSlug: $productSlug
    ) {
      LA
      LRatio
      FP
      IR
    }
  }
`;

export const USER_FETCH_P_FIN_SIMULATE = gql`
  query UserFetchPFinSimulate($basicPremium: Float!, $productSlug: String!) {
    userFetchPFinSimulate(
      basicPremium: $basicPremium
      productSlug: $productSlug
    ) {
      policyData {
        LA
        LRatio
        FP
        IR
        Day1Sv
      }
      reports {
        policyYear
        day1Sv
        cashValue
        surrenderValAftRepayPrincipalNInterestNC
        netGrowthRate
        netGrowthAmount
        accumulatedRepaidIC
        accumulatedRepaidCharge
        accumulatedRepaidInterest
        leveragedIRR
        grossIRR
        loanAmount
      }
    }
  }
`;
