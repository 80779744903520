import classNames from "classnames";
import React from "react";
import styles from "./PFinCalculatorColoredValue.module.scss";

interface Props {
  className?: string;
  value: number;
  children?: React.ReactNode;
}

const PFinCalculatorColoredValue: React.FC<Props> = React.memo((props) => {
  const { className, value, children } = props;
  return (
    <span
      className={classNames(className, {
        [styles.positive]: value > 0,
        [styles.zero]: value === 0,
        [styles.negative]: value < 0,
      })}
    >
      {children}
    </span>
  );
});

export default PFinCalculatorColoredValue;
