import React, { useCallback, useContext, useEffect, useMemo } from "react";
import InternalLoadingModal from "../components/modals/InternalLoadingModal";
import ErrorToast from "../components/toast/ErrorToast";
import MessageToast from "../components/toast/MessageToast";
import ToastStackContainer, {
  ToastOptions,
  useToastStackContainer,
} from "../components/toast/ToastStackContainer";
import { useSet } from "../hooks/useSet";
import {
  SharedResourceData,
  useSharedResourceClient,
  useSharedResourceController,
} from "../hooks/useSharedResource";

type ToastType = "error" | "message";

export type ShowErrorToast = (
  message: string,
  title?: string,
  options?: ToastOptions
) => Promise<void>;

export type ShowMessageToast = (
  message: string,
  title?: string,
  options?: ToastOptions
) => Promise<void>;

interface DialogContextValue {
  showErrorToast: ShowErrorToast;
  showMessageToast: ShowMessageToast;
  loadingSharedResourceData: SharedResourceData;
}

interface DialogContextProviderProps {
  children: React.ReactNode;
}

interface LoadingModalProps {
  show: boolean;
}

interface UseErrorToastValue {
  showErrorToast: ShowErrorToast;
}

interface UseAlertToastValue {
  showMessageToast: ShowMessageToast;
}

const DialogContext = React.createContext<DialogContextValue | null>(null);

export const DialogContextProvider: React.FC<DialogContextProviderProps> =
  React.memo((props) => {
    const { children } = props;

    const loadingIdSet = useSet();
    const loadingSharedResourceData = useSharedResourceController(
      useMemo(
        () => ({
          onLeave: (id) => {
            loadingIdSet.remove(id);
          },
          onUse: async (id) => {
            loadingIdSet.add(id);
          },
          onRelease: (id) => {
            loadingIdSet.remove(id);
          },
        }),
        [loadingIdSet]
      )
    );

    const { showToast, toastComponents } = useToastStackContainer<ToastType>(
      useCallback((data) => {
        if (data.type === "error") {
          return (
            <ErrorToast
              key={data.id}
              title={data.title}
              message={data.message}
              show={data.show}
              onRequestClose={data.onRequestClose}
            />
          );
        }
        return (
          <MessageToast
            key={data.id}
            title={data.title}
            message={data.message}
            show={data.show}
            onRequestClose={data.onRequestClose}
          />
        );
      }, [])
    );

    const shouldShowLoadingModal = useMemo(
      () => loadingIdSet.value.size > 0,
      [loadingIdSet]
    );

    const showErrorToast = useCallback<ShowErrorToast>(
      async (message, title, options) => {
        return showToast("error", message, title, options);
      },
      [showToast]
    );

    const showMessageToast = useCallback<ShowMessageToast>(
      async (message, title, options) => {
        return showToast("message", message, title, options);
      },
      [showToast]
    );

    const contextValue = useMemo<DialogContextValue>(() => {
      return {
        loadingSharedResourceData,
        showErrorToast,
        showMessageToast,
      };
    }, [loadingSharedResourceData, showErrorToast, showMessageToast]);

    return (
      <DialogContext.Provider value={contextValue}>
        <InternalLoadingModal show={shouldShowLoadingModal} />
        <ToastStackContainer>{toastComponents}</ToastStackContainer>
        {children}
      </DialogContext.Provider>
    );
  });

export const LoadingModal: React.FC<LoadingModalProps> = React.memo((props) => {
  const { show } = props;

  const contextValue = useContext(DialogContext);

  const loading = useSharedResourceClient(
    contextValue?.loadingSharedResourceData
  );

  useEffect(() => {
    if (show) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loading.use();
    } else {
      loading.release();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (!contextValue) {
    throw Error("LoadingModal should be used inside DialogContextProvider");
  }

  return null;
});

export const useErrorToast = (): UseErrorToastValue => {
  const value = useContext(DialogContext);
  if (!value) {
    throw Error("useErrorToast should be used inside DialogContextProvider");
  }
  return {
    showErrorToast: value.showErrorToast,
  };
};

export const useMessageToast = (): UseAlertToastValue => {
  const value = useContext(DialogContext);
  if (!value) {
    throw Error("useMessageToast should be used inside DialogContextProvider");
  }
  return {
    showMessageToast: value.showMessageToast,
  };
};
