import classNames from "classnames";
import React from "react";
import { Image } from "react-bootstrap";
import Placeholder from "react-bootstrap/Placeholder";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserFetchAlbums_userFetchAlbums_data } from "../../graphql/__generated__/UserFetchAlbums";
import { generateAlbumPagePath } from "../../pages/album/AlbumPage";
import FixedRatioContainer from "../fixed-ratio-container/FixedRatioContainer";
import ResponsiveImage from "../image/ResponsiveImage";
import styles from "./AlbumItem.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  album: UserFetchAlbums_userFetchAlbums_data | undefined;
}

const AlbumItem: React.FC<Props> = React.memo((props) => {
  const { className, loading, album } = props;

  const { t } = useTranslation();

  if (!album) {
    if (loading) {
      return (
        <div className={classNames(styles.root, className)}>
          <Placeholder
            className={classNames(styles.coverImage, "d-block ratio ratio-1x1")}
            animation="glow"
          >
            <Placeholder xs={12} />
          </Placeholder>
          <Placeholder className={styles.title} as="h4" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
          <Placeholder className={styles.photosCount} as="div" animation="glow">
            <Placeholder xs={4} />
          </Placeholder>
        </div>
      );
    }
    return null;
  }

  return (
    <Link
      className={classNames(styles.root, className)}
      to={generateAlbumPagePath(album.uuid)}
    >
      <FixedRatioContainer
        className={styles.coverImageContainer}
        aspectRatio={1}
      >
        {album.albumCoverPhoto ? (
          <Image
            className={styles.coverImage}
            src={album.albumCoverPhoto.image.readSignedUrl}
          />
        ) : (
          <ResponsiveImage
            className={styles.coverImage}
            src="/img/albums/album-cover-placeholder.png"
          />
        )}
      </FixedRatioContainer>
      <h4 className={styles.title}>{album.title}</h4>
      <div className={styles.photosCount}>
        {t("components.albumItem.photosCount", {
          count: album.albumPhotosCount,
        })}
      </div>
    </Link>
  );
});

export default AlbumItem;
