import { StrapiRestAPIErrorObject } from "../strapi-rest";

export class HTTPError extends Error {
  name = "HTTPError";

  details: {
    status: number;
  };

  constructor(status: number) {
    super(`(${status})`);
    this.details = {
      status,
    };
  }
}

export class StrapiAPIError extends Error {
  name = "StrapiAPIError";

  details: {
    status: number;
    name: string;
    message: string;
    details: Record<string, any>;
  };

  constructor(
    status: number,
    name: string,
    message: string,
    details: Record<string, any>
  ) {
    super(`(${status}) ${name}: ${message} ${JSON.stringify(details)}`);
    this.details = {
      status,
      name,
      message,
      details,
    };
  }

  static fromErrorObject(errorObj: StrapiRestAPIErrorObject): StrapiAPIError {
    return new StrapiAPIError(
      errorObj.status,
      errorObj.name,
      errorObj.message,
      errorObj.details
    );
  }
}
