import classNames from "classnames";
import React from "react";
import { Placeholder } from "react-bootstrap";
import { UserFetchAlbumPhotos_userFetchAlbumPhotos_data } from "../../graphql/__generated__/UserFetchAlbumPhotos";
import FixedRatioImage from "../fixed-ratio-image/FixedRatioImage";
import styles from "./AlbumPhotoItem.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  albumPhoto: UserFetchAlbumPhotos_userFetchAlbumPhotos_data | undefined;
  onClick?: () => void;
}

const AlbumPhotoItem: React.FC<Props> = React.memo((props) => {
  const { className, loading, albumPhoto, onClick } = props;

  if (!albumPhoto) {
    if (loading) {
      return (
        <div className={classNames(styles.root, className)}>
          <Placeholder
            className={classNames(styles.image, "ratio ratio-1x1")}
            animation="glow"
          >
            <Placeholder xs={12} />
          </Placeholder>
        </div>
      );
    }
    return null;
  }

  return (
    <div className={classNames(styles.root, className)} onClick={onClick}>
      <FixedRatioImage
        className={styles.image}
        src={albumPhoto.image.readSignedUrl}
        aspectRatio={1}
      />
    </div>
  );
});

export default AlbumPhotoItem;
