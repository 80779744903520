import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTermDisplayText } from "../../utils/text";
import PFinCalculatorSelectField from "./forms/PFinCalculatorSelectField";
import styles from "./PFinCalculatorTermControl.module.scss";

interface Props {
  className?: string;
  termA: string;
  termB: string;
  termC: string;
}

const PFinCalculatorTermControl: React.FC<Props> = React.memo((props) => {
  const { className, termA, termB, termC } = props;

  const { t } = useTranslation();

  const termSelectItemComponents = useMemo(() => {
    return [termA, termB, termC].map((term) => (
      <div key={term} className={styles.termItem}>
        <PFinCalculatorSelectField
          className={styles.termSelect}
          options={[
            {
              value: term,
              label: getTermDisplayText(t, parseInt(term, 10)),
            },
          ]}
          value={term}
        />
      </div>
    ));
  }, [t, termA, termB, termC]);

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.termItem}>
        <span className={styles.termLabel}>{getTermDisplayText(t, 1)}</span>
      </div>
      {termSelectItemComponents}
      <div className={styles.termItem}>
        <span className={styles.termLabel}>{getTermDisplayText(t, 10)}</span>
      </div>
    </div>
  );
});

export default PFinCalculatorTermControl;
