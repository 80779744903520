import classNames from "classnames";
import React, { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import IconButtonContent from "../icon-button-content/IconButtonContent";
import styles from "./BackButton.module.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const BackButton: React.FC<Props> = React.memo((props) => {
  const { className, children } = props;

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Button
      className={classNames(styles.root, className)}
      variant="outline-primary"
      onClick={onClick}
    >
      <IconButtonContent leftIcon={<BsChevronLeft />}>
        {children}
      </IconButtonContent>
    </Button>
  );
});

export default BackButton;
