import classNames from "classnames";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import _Calendar, {
  CalendarProps,
  CalendarTileProperties,
  FormatterCallback,
} from "react-calendar";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useEffectSkipFirst } from "../../hooks/useEffectSkipFirst";
import styles from "./Calendar.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  highlightedDates?: moment.Moment[];
  date: moment.Moment;
  onDateChange: (date: moment.Moment) => void;
  onMonthViewChange?: (monthStartDate: moment.Moment) => void;
}

const Calendar: React.FC<Props> = React.memo((props) => {
  const {
    className,
    loading,
    highlightedDates,
    date,
    onDateChange,
    onMonthViewChange,
  } = props;

  const [internalDate, setInternalDate] = useState(() => date.toDate());
  const monthViewStartDateRef = useRef<moment.Moment | undefined>(undefined);

  const getTileClassName = useCallback(
    (props: CalendarTileProperties): string | string[] | null => {
      if (props.view === "month") {
        const tileDate = moment(props.date);
        if (highlightedDates?.some((date) => date.isSame(tileDate, "day"))) {
          return styles.tileHighlighted;
        }
      }
      return null;
    },
    [highlightedDates]
  );

  const formatShortWeekday = useCallback<FormatterCallback>((_locale, date) => {
    return moment(date).locale("en").format("dd").slice(0, 1);
  }, []);

  // Listen month view change event via navigationLabel callback
  // as react-calendar doesn't expose month view change callback
  const renderNavigationLabel = useCallback<
    NonNullable<CalendarProps["navigationLabel"]>
  >(
    (props) => {
      if (props.view === "month") {
        // Invoke onMonthViewChange when month changed
        const prevVal = monthViewStartDateRef.current;
        const newVal = moment(props.date);
        if (!prevVal || !prevVal.isSame(newVal, "day")) {
          // Invoke onMonthViewChange after current rendering cycle
          setTimeout(() => {
            onMonthViewChange?.(newVal);
          }, 0);
          monthViewStartDateRef.current = newVal;
        }
      }
      return props.label;
    },
    [onMonthViewChange]
  );

  const onChange = useCallback(
    (_value: Date) => {
      setInternalDate(_value);
      onDateChange(moment(_value));
    },
    [onDateChange]
  );

  useEffectSkipFirst(() => {
    setInternalDate((prev) => {
      // Prevent unnecessary rendering after setting internal date
      if (prev.getTime() === date.valueOf()) {
        return prev;
      }
      return date.toDate();
    });
  }, [date]);

  return (
    <div className={classNames(styles.root, className)}>
      <_Calendar
        locale="en-US"
        calendarType="US"
        value={internalDate}
        prevLabel={<BsChevronLeft />}
        nextLabel={<BsChevronRight />}
        tileClassName={getTileClassName}
        formatShortWeekday={formatShortWeekday}
        navigationLabel={renderNavigationLabel}
        onChange={onChange}
      />
      {loading && (
        <div className={styles.loadingOverlay}>
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
});

export default Calendar;
