import React from "react";
import Button from "react-bootstrap/Button";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import styles from "./Pager.module.scss";

interface Props {
  className?: string;
  currentPage?: number;
  totalPage?: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

const Pager: React.FC<Props> = React.memo((props) => {
  const { className, currentPage, totalPage, onPreviousPage, onNextPage } =
    props;

  return (
    <div className={className}>
      {currentPage !== undefined && totalPage !== undefined && totalPage > 0 && (
        <span className={styles.pagerLabel}>
          Page {currentPage} of {totalPage}
        </span>
      )}
      <Button
        variant="link"
        size="sm"
        disabled={currentPage === undefined || currentPage <= 1}
        onClick={onPreviousPage}
      >
        <BsChevronLeft />
      </Button>
      <Button
        variant="link"
        size="sm"
        disabled={
          currentPage === undefined ||
          totalPage === undefined ||
          currentPage >= totalPage
        }
        onClick={onNextPage}
      >
        <BsChevronRight />
      </Button>
    </div>
  );
});

export default Pager;
