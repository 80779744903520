import React, { useMemo } from "react";
import Toast from "react-bootstrap/Toast";
import styles from "./MessageToast.module.scss";

const AUTO_DISMISS_DURATION = 5000;

interface Props {
  show: boolean;
  title?: string;
  message?: string;
  onRequestClose: () => void;
}

const MessageToast: React.FC<Props> = React.memo((props) => {
  const { show, title, message, onRequestClose } = props;

  const content = useMemo(() => {
    if (title === undefined) {
      return (
        <div className="d-flex">
          <Toast.Body className={styles.toastBody}>{message}</Toast.Body>
          <button
            type="button"
            className="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          />
        </div>
      );
    }
    return (
      <>
        <Toast.Header>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body className={styles.toastBody}>{message}</Toast.Body>
      </>
    );
  }, [message, title]);

  return (
    <Toast
      className="text-white"
      bg="secondary"
      animation={true}
      autohide={true}
      delay={AUTO_DISMISS_DURATION}
      show={show}
      onClose={onRequestClose}
    >
      {content}
    </Toast>
  );
});

export default MessageToast;
