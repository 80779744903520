import { useQuery } from "@apollo/client";
import { ChartData } from "chart.js";
import classNames from "classnames";
import moment from "moment-timezone";
import React, { useMemo } from "react";
import Col from "react-bootstrap/Col";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import BillboardItem from "../../components/billboard/BillboardItem";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import { TIMEZONE_HK } from "../../constants/moment";
import { USER_FETCH_BILL_BOARD_RESULT } from "../../graphql/queries";
import {
  UserFetchBillBoardResult,
  UserFetchBillBoardResultVariables,
} from "../../graphql/__generated__/UserFetchBillBoardResult";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { createDateListByDateRange } from "../../utils/date";
import { createNumberList } from "../../utils/list";
import {
  convertBillboardRecordFromGQLModel,
  createNameToBillboardRecordsMap,
  findBillboardRecordByMonth,
} from "../../utils/models/billboard";
import styles from "./BillboardPage.module.scss";

export const BILLBOARD_PAGE_PATH_PATTERN = "/billboard";

export function generateBillboardPagePath(): string {
  return generatePath(BILLBOARD_PAGE_PATH_PATTERN, {});
}

function generateOrderedChartColor(index: number, totalNumber: number): string {
  const hue = Math.round((index / totalNumber) * 360);
  return `hsl(${hue}, 86%, 41%)`;
}

const BillboardPage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const { chartStartDate, chartEndDate } = useMemo(() => {
    const today = moment().tz(TIMEZONE_HK);
    return {
      chartStartDate: today.clone().startOf("month").subtract(5, "months"),
      chartEndDate: today.clone().endOf("month"),
    };
  }, []);

  const { currentMonthStartDate, currentMonthEndDate } = useMemo(() => {
    const today = moment().tz(TIMEZONE_HK);
    return {
      currentMonthStartDate: today.clone().startOf("month"),
      currentMonthEndDate: today.clone().endOf("month"),
    };
  }, []);

  const {
    data: billboardData,
    loading: loadingBillboardData,
    error: billboardError,
  } = useQuery<UserFetchBillBoardResult, UserFetchBillBoardResultVariables>(
    USER_FETCH_BILL_BOARD_RESULT,
    {
      variables: useMemo(
        () => ({
          startDate: chartStartDate.toISOString(),
          endDate: chartEndDate.toISOString(),
        }),
        [chartEndDate, chartStartDate]
      ),
    }
  );

  useErrorHandler(billboardError, {
    name: "billboardData",
  });

  const chartDateRangeDisplayText = useMemo(() => {
    const start = chartStartDate.format("LL");
    const end = chartEndDate.format("LL");
    return `${start} - ${end}`;
  }, [chartStartDate, chartEndDate]);

  const currentMonthDisplayText = useMemo(() => {
    const start = currentMonthStartDate.format("LL");
    const end = currentMonthEndDate.format("LL");
    return `${start} - ${end}`;
  }, [currentMonthStartDate, currentMonthEndDate]);

  const billboardRecords = useMemo(
    () =>
      billboardData?.userFetchBillBoardResult.billboardData.map(
        convertBillboardRecordFromGQLModel
      ),
    [billboardData?.userFetchBillBoardResult.billboardData]
  );

  const currentMonthRecords = useMemo(
    () =>
      billboardRecords?.filter((record) =>
        record.date.isBetween(
          currentMonthStartDate,
          currentMonthEndDate,
          "month",
          "[]"
        )
      ),
    [billboardRecords, currentMonthEndDate, currentMonthStartDate]
  );

  const chartData = useMemo(() => {
    if (!billboardRecords) {
      return undefined;
    }
    const monthDateList = createDateListByDateRange(
      chartStartDate,
      chartEndDate
    );
    const labels = monthDateList.map((monthDate) => monthDate.format("MMM-YY"));
    const nameToRecordsMap = createNameToBillboardRecordsMap(billboardRecords);
    const numOfPeople = nameToRecordsMap.size;

    const submittedCase: ChartData<"line"> = {
      labels,
      datasets: Array.from(nameToRecordsMap.entries()).map(
        ([name, records], index) => {
          return {
            label: name,
            data: monthDateList.map(
              (monthDate) =>
                findBillboardRecordByMonth(records, monthDate)
                  ?.submittedCases ?? null
            ),
            borderColor: generateOrderedChartColor(index, numOfPeople),
          };
        }
      ),
    };

    const submittedFYC: ChartData<"line"> = {
      labels,
      datasets: Array.from(nameToRecordsMap.entries()).map(
        ([name, records], index) => {
          return {
            label: name,
            data: monthDateList.map(
              (monthDate) =>
                findBillboardRecordByMonth(records, monthDate)?.submittedFYC ??
                null
            ),
            borderColor: generateOrderedChartColor(index, numOfPeople),
          };
        }
      ),
    };

    return {
      submittedCase,
      submittedFYC,
    };
  }, [billboardRecords, chartEndDate, chartStartDate]);

  const submittedCasesChartComponent = useMemo(() => {
    if (!chartData) {
      if (loadingBillboardData) {
        return (
          <Placeholder animation="glow">
            <Placeholder className="ratio ratio-4x3" xs="12" />
          </Placeholder>
        );
      }
      return null;
    }
    return (
      <div className="ratio ratio-4x3">
        <Line
          className={styles.chart}
          options={{
            responsive: true,
            aspectRatio: 4 / 3,
            plugins: {
              title: {
                display: true,
                text: t("pages.billboardPage.submittedCasesChart.title"),
              },
            },
          }}
          data={chartData.submittedCase}
        />
        {chartData.submittedCase.datasets.length === 0 && (
          <div className={styles.noDataMessage}>
            {t("pages.billboardPage.submittedCasesChart.noData")}
          </div>
        )}
      </div>
    );
  }, [chartData, loadingBillboardData, t]);

  const submittedFYCChartComponent = useMemo(() => {
    if (!chartData) {
      if (loadingBillboardData) {
        return (
          <Placeholder animation="glow">
            <Placeholder className="ratio ratio-4x3" xs="12" />
          </Placeholder>
        );
      }
      return null;
    }
    return (
      <div className="ratio ratio-4x3">
        <Line
          className={styles.chart}
          options={{
            responsive: true,
            aspectRatio: 4 / 3,
            plugins: {
              title: {
                display: true,
                text: t("pages.billboardPage.submittedFYCChart.title"),
              },
            },
          }}
          data={chartData.submittedFYC}
        />
        {chartData.submittedFYC.datasets.length === 0 && (
          <div className={styles.noDataMessage}>
            {t("pages.billboardPage.submittedCasesChart.noData")}
          </div>
        )}
      </div>
    );
  }, [chartData, loadingBillboardData, t]);

  const billboardItemComponents = useMemo(() => {
    if (!currentMonthRecords) {
      if (loadingBillboardData) {
        return createNumberList(3).map((index) => (
          <BillboardItem
            key={index}
            className={styles.billboardItem}
            loading={true}
            ranking={undefined}
            billboardRecord={undefined}
          />
        ));
      }
      return null;
    }
    if (currentMonthRecords.length === 0) {
      return (
        <div className={styles.noItemsMessage}>
          {t("pages.billboardPage.noItems")}
        </div>
      );
    }
    return currentMonthRecords.map((record, index) => (
      <BillboardItem
        key={record.uuid}
        className={styles.billboardItem}
        ranking={index + 1}
        billboardRecord={record}
      />
    ));
  }, [currentMonthRecords, loadingBillboardData, t]);

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        title={t("pages.billboardPage.header.title")}
        backgroundImageSrc="/img/billboard/billboard-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        <section className={styles.chartSection}>
          <h4 className={styles.sectionTitle}>{chartDateRangeDisplayText}</h4>
          <Row className="g-3">
            <Col md="6" xs="12">
              {submittedCasesChartComponent}
            </Col>
            <Col md="6" xs="12">
              {submittedFYCChartComponent}
            </Col>
          </Row>
        </section>
        <section>
          <h4 className={styles.sectionTitle}>{currentMonthDisplayText}</h4>
          <div className={styles.billboardItemsContainer}>
            <div className={styles.billboardHeader}>
              <span className={classNames(styles.headerItem, styles.ranking)}>
                {t("pages.billboardPage.billboardHeader.ranking")}
              </span>
              <span className={classNames(styles.headerItem, styles.spacer)} />
              <span
                className={classNames(styles.headerItem, styles.submittedFYC)}
              >
                {t("pages.billboardPage.billboardHeader.submittedFYC")}
              </span>
              <span
                className={classNames(styles.headerItem, styles.submittedCases)}
              >
                {t("pages.billboardPage.billboardHeader.submittedCases")}
              </span>
            </div>
            {billboardItemComponents}
          </div>
        </section>
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default BillboardPage;
