import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import DataTable, { ColumnDict } from "../../components/table/DataTable";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import SortingMethodSelect from "../../components/sorting/SortingMethodSelect";
import { USER_FETCH_LEADS } from "../../graphql/queries";
import {
  UserFetchLeads,
  UserFetchLeadsVariables,
  UserFetchLeads_userFetchLeads_data,
} from "../../graphql/__generated__/UserFetchLeads";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { usePaginatedQuery } from "../../hooks/usePaginatedQuery";
import { Lead } from "../../models/lead";
import { convertLeadFromGQLModel } from "../../utils/models/lead";
import styles from "./LeadsPage.module.scss";

enum SortingMethod {
  Latest = "latest",
  Oldest = "oldest",
}

const ITEMS_PER_PAGE = 10;

const sortingMethodToOrderByObjectDict = {
  [SortingMethod.Latest]: { createdAt: "desc" },
  [SortingMethod.Oldest]: { createdAt: "asc" },
};

export const LEADS_PAGE_PATH_PATTERN = "/leads";

export function generateLeadsPagePath(): string {
  return generatePath(LEADS_PAGE_PATH_PATTERN, {});
}

const LeadsPage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const [sortingMethod, setSortingMethod] = useState<SortingMethod>(
    SortingMethod.Latest
  );
  const [page, setPage] = useState(1);

  const {
    maxPage,
    paginatedData: rawLeadsData,
    loading: loadingLeadsData,
    error: fetchLeadsError,
  } = usePaginatedQuery<
    UserFetchLeads,
    UserFetchLeads_userFetchLeads_data,
    UserFetchLeadsVariables
  >(USER_FETCH_LEADS, {
    pagination: {
      page,
      itemsPerPage: ITEMS_PER_PAGE,
    },
    getVariables: useMemo(
      () => (offset, limit) => ({
        offset,
        limit,
        orderBy: sortingMethodToOrderByObjectDict[sortingMethod],
      }),
      [sortingMethod]
    ),
    getListViewFromData: (data) => data.userFetchLeads,
    onFetchMoreError: useImperativeErrorHandler({ name: "fetchMoreLeads" }),
  });

  useErrorHandler(fetchLeadsError, {
    name: "fetchLeads",
  });

  const sortingMethodOptions = useMemo(
    () => [
      {
        value: SortingMethod.Latest,
        label: t("pages.leadsPage.sortingOptions.latest"),
      },
      {
        value: SortingMethod.Oldest,
        label: t("pages.leadsPage.sortingOptions.oldest"),
      },
    ],
    [t]
  );

  const leadsData = useMemo<Lead[] | undefined>(() => {
    return rawLeadsData?.map(convertLeadFromGQLModel);
  }, [rawLeadsData]);

  const columns = useMemo<(keyof Lead)[]>(() => {
    return ["createdAt", "name", "phone", "email", "status"];
  }, []);

  const dataTableColumnDict = useMemo<ColumnDict<Lead>>(() => {
    return {
      createdAt: {
        width: 100,
        headerCell: {
          renderChildren: () => t("pages.leadsPage.table.createdAt"),
        },
        dataCell: {
          renderChildren: (data) => data.createdAt.format("MMMDo"),
        },
      },
      name: {
        headerCell: {
          renderChildren: () => t("pages.leadsPage.table.name"),
        },
        dataCell: {
          renderChildren: (data) => data.name,
        },
      },
      phone: {
        headerCell: {
          renderChildren: () => t("pages.leadsPage.table.phone"),
        },
        dataCell: {
          renderChildren: (data) => data.phone,
        },
      },
      email: {
        hideBreakpoint: "sm",
        headerCell: {
          renderChildren: () => t("pages.leadsPage.table.email"),
        },
        dataCell: {
          renderChildren: (data) => data.email,
        },
      },
      status: {
        hideBreakpoint: "md",
        headerCell: {
          renderChildren: () => t("pages.leadsPage.table.status"),
        },
        dataCell: {
          renderChildren: (data) => data.status,
        },
      },
    };
  }, [t]);

  const onPreviousPageButtonClick = useCallback(() => {
    setPage((prev) => prev - 1);
  }, []);

  const onNextPageButtonClick = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const onSortingMethodChange = useCallback((sortingMethod: SortingMethod) => {
    // (nicolas 04Jul2022) Reset to page 1 when changing sorting method to prevent invalid paginated data (empty data)
    setPage(1);
    setSortingMethod(sortingMethod);
  }, []);

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        title={t("pages.leadsPage.header.title")}
        backgroundImageSrc="/img/leads/leads-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        <div className={styles.sortingRow}>
          <SortingMethodSelect
            options={sortingMethodOptions}
            value={sortingMethod}
            onChange={onSortingMethodChange}
          />
        </div>
        <DataTable<Lead>
          columns={columns}
          columnDict={dataTableColumnDict}
          data={leadsData}
          loading={loadingLeadsData}
          currentPage={page}
          totalPage={maxPage}
          itemsPerPage={ITEMS_PER_PAGE}
          onPreviousPageButtonClick={onPreviousPageButtonClick}
          onNextPageButtonClick={onNextPageButtonClick}
        />
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default LeadsPage;
