import React, { useCallback } from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";
import { To, useHref, useLinkClickHandler } from "react-router-dom";

/**
 * Reference: https://github.com/remix-run/react-router/blob/v6.0.0/packages/react-router-dom/index.tsx#L208-L238
 */

interface Props extends Omit<ButtonProps, "as" | "onClick" | "href"> {
  reloadDocument?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  replace?: boolean;
  state?: any;
  to: To;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const LinkButton: React.FC<Props> = React.memo((props) => {
  const { to, replace, state, target, reloadDocument, onClick, ...restProps } =
    props;

  const href = useHref(to);
  const internalOnClick = useLinkClickHandler(to, { replace, state, target });

  const handleClick = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement>) => {
      const event: React.MouseEvent<HTMLAnchorElement> = _event as any;
      if (onClick) {
        onClick(event);
      }
      if (!event.defaultPrevented && !reloadDocument) {
        internalOnClick(event);
      }
    },
    [internalOnClick, onClick, reloadDocument]
  );

  return <Button as="a" {...restProps} href={href} onClick={handleClick} />;
});

export default LinkButton;
