import React, { useCallback, useMemo, useState } from "react";

import AppRouter from "./router/AppRouter";

import styles from "./App.module.scss";
import { ApolloServiceProvider } from "./contexts/ApolloProvider";
import { DialogContextProvider } from "./contexts/DialogContext";
import { EnvironmentVariables } from "./utils/env";
import { APIClientContextProvider } from "./contexts/APIClientContext";
import { UserContextProvider } from "./contexts/UserContext";
import InitUser from "./components/user/InitUser";
import FullScreenLoading from "./components/full-sceen-loading/FullScreenLoading";
import UserUpdater from "./components/user/UserUpdater";

const graphqlEndpointUri = EnvironmentVariables.GRAPHQL_ENDPOINT_URI;
const graphqlRESTAPIEndpointUri = EnvironmentVariables.API_ENDPOINT_URI;

interface AppInitializationState {
  userInitialized: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
  const [initState, setInitState] = useState<AppInitializationState>({
    userInitialized: false,
  });

  const appInitialized = useMemo(
    () =>
      !Object.values(initState).some((initialized: boolean) => !initialized),
    [initState]
  );

  const onUserInitialized = useCallback(() => {
    setInitState((prev) => ({
      ...prev,
      userInitialized: true,
    }));
  }, []);

  return (
    <UserContextProvider>
      <DialogContextProvider>
        <APIClientContextProvider baseUri={graphqlRESTAPIEndpointUri}>
          <ApolloServiceProvider uri={graphqlEndpointUri}>
            <InitUser onInitialized={onUserInitialized} />
            <UserUpdater />
            {appInitialized ? (
              <AppRouter className={styles.appRouter} />
            ) : (
              <FullScreenLoading />
            )}
          </ApolloServiceProvider>
        </APIClientContextProvider>
      </DialogContextProvider>
    </UserContextProvider>
  );
}

export default App;
