import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { FETCH_ME } from "../../graphql/queries";
import { FetchMe } from "../../graphql/__generated__/FetchMe";
import { useErrorHandler } from "../../hooks/useErrorHandler";

/**
 * Sync user data with Apollo cache.
 */
const UserUpdater: React.FC = React.memo(() => {
  const { updateUser } = useUserContext();

  const { data, error } = useQuery<FetchMe>(FETCH_ME, {
    fetchPolicy: "cache-only",
  });

  useErrorHandler(error, {
    name: "UserUpdater",
  });

  useEffect(() => {
    if (data) {
      updateUser(data.fetchMe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return null;
});

export default UserUpdater;
