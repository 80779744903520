import classNames from "classnames";
import React, { useMemo } from "react";
import styles from "./FixedRatioContainer.module.scss";

interface Props {
  className?: string;
  aspectRatio: number;
  children?: React.ReactNode;
}

const FixedRatioContainer: React.FC<Props> = React.memo((props) => {
  const { className, aspectRatio, children } = props;

  const rootStyle = useMemo(
    () => ({
      paddingTop: `${100 / aspectRatio}%`,
    }),
    [aspectRatio]
  );

  return (
    <div className={classNames(styles.root, className)} style={rootStyle}>
      <div className={styles.contentWrapper}>{children}</div>
    </div>
  );
});

export default FixedRatioContainer;
