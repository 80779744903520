import classNames from "classnames";
import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";
import Placeholder from "react-bootstrap/Placeholder";
import { UserFetchQuiz_userFetchQuiz_questions } from "../../graphql/__generated__/UserFetchQuiz";
import styles from "./QuizQuestionItem.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  questionNumber: number | undefined;
  question: UserFetchQuiz_userFetchQuiz_questions | undefined;
  selectedAnswer: string | undefined;
  onChange: (answer: string) => void;
}

const QuizQuestionItem: React.FC<Props> = React.memo((props) => {
  const {
    className,
    loading,
    questionNumber,
    question,
    selectedAnswer,
    onChange,
  } = props;

  const onCheckChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const dataIndex = event.target.dataset["index"];
      if (!question || dataIndex === undefined) {
        return;
      }
      const index = parseInt(dataIndex, 10);
      const selectedAnswer = question.answerChoices[index];
      onChange(selectedAnswer);
    },
    [question, onChange]
  );

  if (!question || questionNumber === undefined) {
    if (loading) {
      return (
        <div className={classNames(styles.root, className)}>
          <Placeholder className={styles.title} as="h4" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
          <Placeholder
            className="d-flex justify-content-between"
            animation="glow"
          >
            <Placeholder xs={2} />
            <Placeholder xs={2} />
            <Placeholder xs={2} />
            <Placeholder xs={2} />
          </Placeholder>
        </div>
      );
    }
    return null;
  }

  return (
    <div className={classNames(styles.root, className)}>
      <h4
        className={styles.title}
      >{`${questionNumber}. ${question.content}`}</h4>
      {question.answerChoices.map((choice, index) => (
        <Form.Check
          key={index}
          data-index={index}
          inline={true}
          type="radio"
          name={`question-${questionNumber}`}
          label={choice}
          checked={selectedAnswer === choice}
          onChange={onCheckChange}
        />
      ))}
    </div>
  );
});

export default QuizQuestionItem;
