import classNames from "classnames";
import React from "react";
import PageContentContainer from "./PageContentContainer";
import ResponsiveImage from "../image/ResponsiveImage";
import styles from "./PageCoverHeader.module.scss";

interface Props {
  className?: string;
  title?: string;
  backgroundImageSrc: string;
}

const PageCoverHeader: React.FC<Props> = React.memo((props) => {
  const { className, title, backgroundImageSrc } = props;
  return (
    <header className={classNames(styles.root, className)}>
      <ResponsiveImage
        className={styles.backgroundImage}
        src={backgroundImageSrc}
      />
      <PageContentContainer className={styles.contentContainer}>
        {title && <h1 className={styles.title}>{title}</h1>}
      </PageContentContainer>
    </header>
  );
});

export default PageCoverHeader;
