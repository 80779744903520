import classNames from "classnames";
import React from "react";
import { BsList } from "react-icons/bs";
import ResponsiveImage from "../../image/ResponsiveImage";
import styles from "./ToggleMenuItemContent.module.scss";

interface Props {
  className?: string;
}

const ToggleMenuItemContent: React.FC<Props> = React.memo((props) => {
  const { className } = props;

  return (
    <div className={classNames(styles.root, className)}>
      <span className={styles.iconWrapper}>
        <BsList />
      </span>
      <div className={styles.imageWrapper}>
        <ResponsiveImage
          className={styles.image}
          src="/img/common/logo-small.png"
        />
      </div>
    </div>
  );
});

export default ToggleMenuItemContent;
