import { HTTPError, StrapiAPIError } from "../models/errors/api";
import {
  AuthResponseData,
  StrapiRestAPIErrorObject,
} from "../models/strapi-rest";

export function isStrapiRestAPIErrorObject(
  value: any
): value is StrapiRestAPIErrorObject {
  return (
    value &&
    typeof value === "object" &&
    "status" in value &&
    "name" in value &&
    "message" in value &&
    "details" in value &&
    typeof value["status"] === "number" &&
    typeof value["name"] === "string" &&
    typeof value["message"] === "string" &&
    typeof value["details"] === "object"
  );
}

async function throwResponseErrorIfNeeded(resp: Response): Promise<void> {
  if (resp.ok) {
    return;
  }

  let result: any;
  try {
    result = await resp.json();
  } catch (_parsingError: unknown) {}

  if (result) {
    const apiError = result?.error;
    if (apiError && isStrapiRestAPIErrorObject(apiError)) {
      throw StrapiAPIError.fromErrorObject(apiError);
    }
  }

  throw new HTTPError(resp.status);
}

export class StrapiRestAPIClient {
  baseUri: string;

  constructor(baseUri: string) {
    this.baseUri = baseUri;
  }

  async requestAuthProviderCallback(
    providerName: string,
    search: string
  ): Promise<AuthResponseData> {
    const resp = await fetch(
      `${this.baseUri}/api/auth/${providerName}/callback${search}`
    );
    await throwResponseErrorIfNeeded(resp);
    return resp.json();
  }
}
