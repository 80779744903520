import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import LessonCategoryItem from "../../components/lesson-category-item/LessonCategoryItem";
import PBreadcrumb, {
  PBreadcrumbActiveItemLabel,
} from "../../components/p-breadcrumb/PBreadcrumb";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import { FETCH_CATEGORIES } from "../../graphql/queries";
import { FetchCategories } from "../../graphql/__generated__/FetchCategories";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { createNumberList } from "../../utils/list";
import styles from "./LessonCategoriesPage.module.scss";

export const LESSON_CATEGORIES_PAGE_PATH_PATTERN = "/lesson-categories";

export function generateLessonCategoriesPagePath(): string {
  return generatePath(LESSON_CATEGORIES_PAGE_PATH_PATTERN, {});
}

const LessonCategoriesPage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const {
    data: categoriesData,
    loading: loadingCategoriesData,
    error: categoriesError,
  } = useQuery<FetchCategories>(FETCH_CATEGORIES);

  useErrorHandler(categoriesError, {
    name: "categoriesData",
  });

  const lessonCategoryColComponents = useMemo(() => {
    if (!categoriesData) {
      if (loadingCategoriesData) {
        return createNumberList(3).map((index) => (
          <Col key={index} md={4} sm={6} xs={12}>
            <LessonCategoryItem loading={true} category={undefined} />
          </Col>
        ));
      }
      return null;
    }
    return categoriesData.fetchCategories.map((category) => (
      <Col key={category.slug} md={4} sm={6} xs={12}>
        <LessonCategoryItem category={category} />
      </Col>
    ));
  }, [loadingCategoriesData, categoriesData]);

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        title={t("pages.lessonCategoriesPage.header.title")}
        backgroundImageSrc="/img/lesson-categories/lesson-categories-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        <div className={styles.toolbar}>
          <PBreadcrumb
            parentItems={[]}
            activeItemContent={
              <PBreadcrumbActiveItemLabel
                label={t("pages.lessonCategoriesPage.breadcrumbLabel")}
              />
            }
          />
        </div>
        <section>
          <Row className="g-3">{lessonCategoryColComponents}</Row>
        </section>
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default LessonCategoriesPage;
