import { useQuery } from "@apollo/client";
import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { generatePath, useParams } from "react-router-dom";
import AlbumPhotoItem from "../../components/album/AlbumPhotoItem";
import PageContentContainer from "../../components/page/PageContentContainer";
import IconButtonContent from "../../components/icon-button-content/IconButtonContent";
import AlbumPhotoViewerModal, {
  useAlbumPhotoViewerModal,
} from "../../components/modals/AlbumPhotoViewerModal";
import PageNavHeader from "../../components/page/PageNavHeader";
import PageToolbar from "../../components/page/PageToolbar";
import {
  USER_FETCH_ALBUM,
  USER_FETCH_ALBUM_PHOTOS,
} from "../../graphql/queries";
import {
  UserFetchAlbum,
  UserFetchAlbumVariables,
} from "../../graphql/__generated__/UserFetchAlbum";
import {
  UserFetchAlbumPhotos,
  UserFetchAlbumPhotosVariables,
} from "../../graphql/__generated__/UserFetchAlbumPhotos";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { createNumberList } from "../../utils/list";
import styles from "./AlbumPage.module.scss";

type PageParamKey = "uuid";

const ITEMS_PER_PAGE = 10;

export const ALBUM_PAGE_PATH_PATTERN = "/album/:uuid";

export function generateAlbumPagePath(uuid: string): string {
  return generatePath(ALBUM_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const AlbumPage: React.FC = React.memo(() => {
  const pageParams = useParams<PageParamKey>();
  const { t } = useTranslation();

  const [fetchingMoreAlbumPhotos, setFetchingMoreAlbumPhotos] = useState(false);
  const { open: openPhotoViewerModal, props: photoViewerModalProps } =
    useAlbumPhotoViewerModal();

  const {
    data: albumData,
    loading: loadingAlbum,
    error: fetchAlbumError,
  } = useQuery<UserFetchAlbum, UserFetchAlbumVariables>(USER_FETCH_ALBUM, {
    variables: {
      uuid: pageParams.uuid!,
    },
  });

  const {
    data: albumPhotosData,
    loading: loadingAlbumPhotos,
    error: fetchAlbumPhotosError,
    fetchMore: fetchMorePhotos,
  } = useQuery<UserFetchAlbumPhotos, UserFetchAlbumPhotosVariables>(
    USER_FETCH_ALBUM_PHOTOS,
    {
      variables: {
        albumUuid: pageParams.uuid!,
        listQuery: {
          offset: 0,
          limit: ITEMS_PER_PAGE,
        },
      },
    }
  );

  useErrorHandler(fetchAlbumError, {
    name: "albumData",
  });

  useErrorHandler(fetchAlbumPhotosError, {
    name: "albumPhotosData",
  });

  const handleFetchMoreAlbumPhotosError = useImperativeErrorHandler({
    name: "fetchMoreAlbumPhotos",
  });

  const hasMorePhotos = useMemo(() => {
    if (!albumPhotosData) {
      return undefined;
    }
    const { current, count } = albumPhotosData.userFetchAlbumPhotos;
    return count > current;
  }, [albumPhotosData]);

  const onLoadMorePhotos = useCallback(async () => {
    if (!albumPhotosData) {
      return;
    }

    try {
      setFetchingMoreAlbumPhotos(true);
      await fetchMorePhotos({
        variables: {
          listQuery: {
            offset: albumPhotosData.userFetchAlbumPhotos.current,
            limit: ITEMS_PER_PAGE,
          },
        },
      });
    } catch (error: any) {
      handleFetchMoreAlbumPhotosError(error);
    } finally {
      setFetchingMoreAlbumPhotos(false);
    }
  }, [albumPhotosData, fetchMorePhotos, handleFetchMoreAlbumPhotosError]);

  const albumTitleComponent = useMemo(() => {
    if (!albumData) {
      if (loadingAlbum) {
        return (
          <Placeholder animation="glow" xs={3}>
            <Placeholder className={styles.albumTitle} xs={12} />
          </Placeholder>
        );
      }
      return null;
    }
    const { title, albumPhotosCount } = albumData.userFetchAlbum;
    return (
      <span className={styles.albumTitle}>
        {`${title} (${albumPhotosCount})`}
      </span>
    );
  }, [albumData, loadingAlbum]);

  const albumDescriptionComponent = useMemo(() => {
    if (!albumData) {
      if (loadingAlbum) {
        return (
          <Placeholder animation="glow" xs={3}>
            <Placeholder className={styles.albumDescription} as="p" xs={12} />
          </Placeholder>
        );
      }
      return null;
    }

    const { description } = albumData.userFetchAlbum;
    if (!description) {
      return null;
    }
    return <p className={styles.albumDescription}>{description}</p>;
  }, [albumData, loadingAlbum]);

  const albumPhotoItemColComponents = useMemo(() => {
    if (!albumPhotosData) {
      if (loadingAlbumPhotos) {
        return createNumberList(ITEMS_PER_PAGE).map((index) => (
          <Col key={index} lg={3} md={4} sm={6} xs={12}>
            <AlbumPhotoItem loading={true} albumPhoto={undefined} />
          </Col>
        ));
      }
      return null;
    }
    return albumPhotosData.userFetchAlbumPhotos.data.map((albumPhoto) => {
      const onClick = () => {
        openPhotoViewerModal({
          albumTitle: albumData?.userFetchAlbum.title ?? "",
          albumPhoto,
        });
      };
      return (
        <Col key={albumPhoto.uuid} lg={3} md={4} sm={6} xs={12}>
          <AlbumPhotoItem
            albumPhoto={albumPhoto}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={onClick}
          />
        </Col>
      );
    });
  }, [
    albumData?.userFetchAlbum.title,
    albumPhotosData,
    loadingAlbumPhotos,
    openPhotoViewerModal,
  ]);

  const loadMoreButtonComponent = useMemo(() => {
    if (!albumPhotosData || hasMorePhotos === false) {
      return null;
    }

    const leftIcon = fetchingMoreAlbumPhotos && (
      <Spinner animation="border" size="sm" />
    );
    return (
      <Button
        className={classNames(styles.loadMoreBtn, "btn-rounded")}
        variant="secondary"
        disabled={fetchingMoreAlbumPhotos}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={onLoadMorePhotos}
      >
        <IconButtonContent leftIcon={leftIcon}>
          {t("pages.albumPage.loadMorePhotos")}
        </IconButtonContent>
      </Button>
    );
  }, [
    albumPhotosData,
    fetchingMoreAlbumPhotos,
    hasMorePhotos,
    onLoadMorePhotos,
    t,
  ]);

  return (
    <div className={styles.root}>
      <PageNavHeader backButtonText={t("pages.albumPage.back")} />
      <PageToolbar contentClass={styles.toolbarContent}>
        {albumTitleComponent}
      </PageToolbar>
      <PageContentContainer className={styles.mainContent}>
        <div className={styles.photosContainer}>
          {albumDescriptionComponent}
          <Row className="g-3">{albumPhotoItemColComponents}</Row>
          <div className={styles.loadMoreButtonContainer}>
            {albumPhotosData?.userFetchAlbumPhotos.count === 0 && (
              <div className={styles.noItemsMessage}>
                {t("pages.albumPage.noItems")}
              </div>
            )}
            {loadMoreButtonComponent}
          </div>
        </div>
      </PageContentContainer>

      <AlbumPhotoViewerModal {...photoViewerModalProps} />
    </div>
  );
});

export default AlbumPage;
