import classNames from "classnames";
import React from "react";
import BackButton from "../button/BackButton";
import PageContentContainer from "./PageContentContainer";
import styles from "./PageNavHeader.module.scss";

interface Props {
  className?: string;
  backButtonText: string;
}

const PageNavHeader: React.FC<Props> = React.memo((props) => {
  const { className, backButtonText } = props;

  return (
    <header className={classNames(styles.root, className)}>
      <PageContentContainer>
        <BackButton>{backButtonText}</BackButton>
      </PageContentContainer>
    </header>
  );
});

export default PageNavHeader;
