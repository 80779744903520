import classNames from "classnames";
import React from "react";
import Image from "react-bootstrap/Image";
import ResponsiveImage from "../image/ResponsiveImage";
import styles from "./PageBackground.module.scss";

interface Props {
  topLeftBGClass?: string;
  bottomRightBGClass?: string;
}

const PageBackground: React.FC<Props> = React.memo((props) => {
  const { topLeftBGClass, bottomRightBGClass } = props;
  return (
    <>
      <ResponsiveImage
        className={classNames(styles.backgroundTopLeft, topLeftBGClass)}
        src="/img/common/page-background-top-left.png"
      />
      <Image
        className={classNames(styles.backgroundBottomRight, bottomRightBGClass)}
        src="/img/common/page-background-bottom-right.png"
      />
    </>
  );
});

export default PageBackground;
