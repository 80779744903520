export const StrapiErrorName = {
  NOT_FOUND_ERROR: "NotFoundError",
  APPLICATION_ERROR: "ApplicationError",
  VALIDATION_ERROR: "ValidationError",
};

export const StrapiErrorMessage = {
  EMAIL_IS_ALREADY_TAKEN: "Email is already taken.",
  INVALID_ID_OR_PW: "Invalid identifier or password",
  INVALID_CURRENT_PASSWORD: "Invalid current password",
  NO_PRODUCT_MATCH_THE_BP: "No product match the BP",
};
