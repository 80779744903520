import classNames from "classnames";
import React from "react";
import styles from "./IconButtonContent.module.scss";

interface Props {
  className?: string;
  contentWrapperClass?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children: React.ReactNode;
}

const IconButtonContent: React.FC<Props> = React.memo((props) => {
  const { className, contentWrapperClass, leftIcon, rightIcon, children } =
    props;
  return (
    <div className={classNames(styles.root, className)}>
      {leftIcon && <div className={styles.leftIconWrapper}>{leftIcon}</div>}
      <div className={contentWrapperClass}>{children}</div>
      {rightIcon && <div className={styles.rightIconWrapper}>{rightIcon}</div>}
    </div>
  );
});

export default IconButtonContent;
