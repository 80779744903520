import classNames from "classnames";
import React from "react";
import styles from "./PFinCalculatorResultDetailsTable.module.scss";

interface Props {
  className?: string;
  title: string;
  children?: React.ReactNode;
}

const PFinCalculatorResultDetailsTable: React.FC<Props> = React.memo(
  (props) => {
    const { className, title, children } = props;

    return (
      <div className={classNames(styles.root, className)}>
        <div className={styles.title}>{title}</div>
        <table className={styles.table}>
          <tbody className={styles.tableBody}>{children}</tbody>
        </table>
      </div>
    );
  }
);

export default PFinCalculatorResultDetailsTable;
