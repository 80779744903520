import {
  StrapiYupMinStringValidationErrorData,
  StrapiYupValidationErrorInfo,
} from "../models/strapi-yup";

/**
 * @see {@link https://github.com/jquense/yup/blob/v0.32.9/src/locale.ts#L80}
 */
function parseStrapiYupMinStringValidationErrorMessage(
  message: string
): StrapiYupMinStringValidationErrorData | undefined {
  const results = /([\w.]+) must be at least (\d+) characters/.exec(message);
  if (!results) {
    return undefined;
  }
  const [_, pathStr, min] = results;
  return {
    pathStr,
    min: parseInt(min, 10),
  };
}

export function parseStrapiYupValidationErrorMessage(
  message: string
): StrapiYupValidationErrorInfo | undefined {
  const minStr = parseStrapiYupMinStringValidationErrorMessage(message);
  if (minStr) {
    return {
      type: "string.min",
      data: minStr,
    };
  }
  return undefined;
}
