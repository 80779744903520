import classNames from "classnames";
import React from "react";
import PageContentContainer from "./PageContentContainer";
import styles from "./PageToolbar.module.scss";

interface Props {
  className?: string;
  contentClass?: string;
  children?: React.ReactNode;
}

const PageToolbar: React.FC<Props> = React.memo((props) => {
  const { className, contentClass, children } = props;

  return (
    <div className={classNames(styles.root, className)}>
      <PageContentContainer className={contentClass}>
        {children}
      </PageContentContainer>
    </div>
  );
});

export default PageToolbar;
