import { TFunction } from "i18next";
import { CURRENCY_HKD } from "../constants/currency";
import { round } from "./math";

function optionalRound(value: number, fractionDigits?: number): number {
  return fractionDigits === undefined ? value : round(value, fractionDigits);
}

export function getLessonDurationDisplayText(
  t: TFunction,
  durationInSec: number,
  spaceBetweenValueAndUnit: boolean = true
): string {
  const absVal = Math.abs(durationInSec);
  let unit: string;
  let shiftedVal: number;
  if (absVal < 60) {
    unit = t("units.time.second.short");
    shiftedVal = durationInSec;
  } else if (absVal < 3600) {
    unit = t("units.time.minute.short");
    shiftedVal = Math.ceil(durationInSec / 60);
  } else {
    unit = t("units.time.hour.short");
    shiftedVal = Math.ceil(durationInSec / 3600);
  }
  return `${shiftedVal}${spaceBetweenValueAndUnit ? " " : ""}${unit}`;
}

export function getNumberDisplayText(
  t: TFunction,
  value: number,
  fractionDigits?: number
): string {
  const absVal = Math.abs(value);
  let unit: string;
  let shiftedVal: number;
  if (absVal < 1000) {
    unit = "";
    shiftedVal = value;
  } else if (absVal < 1000 * 1000) {
    unit = t("units.number.kilo.short");
    shiftedVal = value / 1000;
  } else {
    unit = t("units.number.mega.short");
    shiftedVal = value / 1000 / 1000;
  }
  return `${optionalRound(shiftedVal, fractionDigits)}${unit}`;
}

export function getNumberDisplayTextWithThousandsSeparator(
  value: number,
  fractionDigits?: number
): string {
  const rawNumberText = `${optionalRound(value, fractionDigits)}`;
  return rawNumberText.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getNumberDisplayTextWithChineseNumberUnit(
  t: TFunction,
  value: number,
  fractionDigits?: number
): string {
  const absVal = Math.abs(value);
  let unit: string;
  let shiftedVal: number;
  if (absVal < 10_000) {
    unit = "";
    shiftedVal = value;
  } else if (absVal < 10_000 * 10_000) {
    unit = t("units.chineseNumber.tenThousand");
    shiftedVal = value / 10_000;
  } else if (absVal < 10_000 * 10_000 * 10_000) {
    unit = t("units.chineseNumber.hundredMillion");
    shiftedVal = value / 10_000 / 10_000;
  } else {
    unit = t("units.chineseNumber.trillion");
    shiftedVal = value / 10_000 / 10_000 / 10_000;
  }
  return `${optionalRound(shiftedVal, fractionDigits)}${unit}`;
}

export function getPercentageDisplayText(
  value: number,
  fractionDigits?: number,
  displayPositiveSign?: boolean
): string {
  const percentageVal = optionalRound(value * 100, fractionDigits);
  return `${displayPositiveSign && value > 0 ? "+" : ""}${percentageVal}%`;
}

export function getCurrencyDisplayText(t: TFunction, currency: string): string {
  switch (currency) {
    case CURRENCY_HKD:
      return t("currency.hkd");
    default:
      console.warn(`Unknown currency: ${currency}`);
      return "";
  }
}

export function getPriceDisplayText(
  t: TFunction,
  currency: string,
  amount: number,
  fractionDigits?: number
): string {
  const currencyText = getCurrencyDisplayText(t, currency);
  const amountText = getNumberDisplayTextWithThousandsSeparator(
    optionalRound(amount, fractionDigits)
  );
  return `${currencyText} ${amountText}`;
}

export function getPFinIssuerDisplayText(
  t: TFunction,
  issuerSlug: string
): string {
  switch (issuerSlug) {
    case "NCB":
      return t("pFinIssuer.NCB");
    case "CYB":
      return t("pFinIssuer.CYB");
    case "BOC":
      return t("pFinIssuer.BOC");
    default:
      console.warn(`Unknown p fin issuer: ${issuerSlug}`);
      return "";
  }
}

export function getTermDisplayText(t: TFunction, term: number): string {
  if (term === 1) {
    return t("term.firstTerm");
  }
  return t("term.otherTerm", {
    term,
  });
}
