import { separateExtFromFilePath } from "./url";

/**
 * Create image props for responsive images.
 * Assume following set of images exist:
 *  - `[/path/]name.ext`
 *  - `[/path/]name@2x.ext`
 *  - `[/path/]name@3x.ext`
 */
export function getResponsiveImageProps(imagePath: string): {
  src: string;
  srcSet: string;
} {
  const { pathWithoutExt, ext } = separateExtFromFilePath(imagePath);
  const encodedPathWithoutExt = encodeURI(pathWithoutExt);
  const srcSet = [
    `${encodedPathWithoutExt}.${ext} 1x`,
    `${encodedPathWithoutExt}@2x.${ext} 2x`,
    `${encodedPathWithoutExt}@3x.${ext} 3x`,
  ].join(", ");
  return {
    src: imagePath,
    srcSet,
  };
}
