import React, { useCallback, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import AlbumItem from "../../components/album/AlbumItem";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import Pager from "../../components/pager/Pager";
import SortingMethodSelect from "../../components/sorting/SortingMethodSelect";
import { USER_FETCH_ALBUMS } from "../../graphql/queries";
import {
  UserFetchAlbums,
  UserFetchAlbumsVariables,
  UserFetchAlbums_userFetchAlbums_data,
} from "../../graphql/__generated__/UserFetchAlbums";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { usePaginatedQuery } from "../../hooks/usePaginatedQuery";
import { createNumberList } from "../../utils/list";
import styles from "./AlbumsPage.module.scss";

enum SortingMethod {
  Latest = "latest",
  Oldest = "oldest",
}

const sortingMethodToOrderByObjectDict = {
  [SortingMethod.Latest]: { createdAt: "desc" },
  [SortingMethod.Oldest]: { createdAt: "asc" },
};

const ITEMS_PER_PAGE = 8;

export const ALBUMS_PAGE_PATH_PATTERN = "/albums";

export function generateAlbumsPagePath(): string {
  return generatePath(ALBUMS_PAGE_PATH_PATTERN, {});
}

const AlbumsPage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const [sortingMethod, setSortingMethod] = useState<SortingMethod>(
    SortingMethod.Latest
  );
  const [page, setPage] = useState(1);

  const {
    maxPage,
    paginatedData: albumsData,
    loading: loadingAlbums,
    error: fetchAlbumsError,
  } = usePaginatedQuery<
    UserFetchAlbums,
    UserFetchAlbums_userFetchAlbums_data,
    UserFetchAlbumsVariables
  >(USER_FETCH_ALBUMS, {
    pagination: {
      page,
      itemsPerPage: ITEMS_PER_PAGE,
    },
    getVariables: useCallback(
      (offset, limit) => ({
        listQuery: {
          offset,
          limit,
          orderBy: sortingMethodToOrderByObjectDict[sortingMethod],
        },
      }),
      [sortingMethod]
    ),
    getListViewFromData: (data) => data.userFetchAlbums,
    onFetchMoreError: useImperativeErrorHandler({ name: "fetchMoreAlbums" }),
  });

  useErrorHandler(fetchAlbumsError, {
    name: "albumsData",
  });

  const sortingMethodOptions = useMemo(
    () => [
      {
        value: SortingMethod.Latest,
        label: t("pages.albumsPage.sortingOptions.latest"),
      },
      {
        value: SortingMethod.Oldest,
        label: t("pages.albumsPage.sortingOptions.oldest"),
      },
    ],
    [t]
  );

  const onSortingMethodChange = useCallback((sortingMethod: SortingMethod) => {
    setSortingMethod(sortingMethod);
  }, []);

  const onPreviousPage = useCallback(() => {
    setPage((prev) => prev - 1);
  }, []);

  const onNextPage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const albumItemColComponents = useMemo(() => {
    if (loadingAlbums) {
      return createNumberList(4).map((index) => (
        <Col key={index} lg={3} md={4} sm={6} xs={12}>
          <AlbumItem loading={true} album={undefined} />
        </Col>
      ));
    }
    if (!albumsData) {
      return null;
    }
    return albumsData.map((album) => {
      return (
        <Col key={album.uuid} lg={3} md={4} sm={6} xs={12}>
          <AlbumItem album={album} />
        </Col>
      );
    });
  }, [albumsData, loadingAlbums]);

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        title={t("pages.albumsPage.header.title")}
        backgroundImageSrc="/img/albums/albums-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        <div className={styles.sortingRow}>
          <SortingMethodSelect
            options={sortingMethodOptions}
            value={sortingMethod}
            onChange={onSortingMethodChange}
          />
          <Pager
            currentPage={page}
            totalPage={maxPage}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
          />
        </div>
        <Row className="g-3">{albumItemColComponents}</Row>
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default AlbumsPage;
