import { isApolloError, useQuery } from "@apollo/client";
import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { generatePath, useParams } from "react-router-dom";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import LessonItem from "../../components/lesson-item/LessonItem";
import PBreadcrumb, {
  PBreadcrumbActiveItemLabel,
} from "../../components/p-breadcrumb/PBreadcrumb";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import SortingMethodSelect from "../../components/sorting/SortingMethodSelect";
import { StrapiErrorName } from "../../constants/strapi";
import {
  FETCH_CATEGORY,
  FETCH_LESSONS_BY_CATEGORY,
} from "../../graphql/queries";
import {
  FetchCategory,
  FetchCategoryVariables,
} from "../../graphql/__generated__/FetchCategory";
import {
  FetchLessonsByCategory,
  FetchLessonsByCategoryVariables,
} from "../../graphql/__generated__/FetchLessonsByCategory";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { useErrorHandlerWithDerivedValue } from "../../hooks/useErrorHandlerWithDerivedValue";
import { createNumberList } from "../../utils/list";
import { isGraphQLErrorsContainStrapiErrorName } from "../../utils/strapi-gql";
import { generateLessonCategoriesPagePath } from "../lesson-categories/LessonCategoriesPage";
import styles from "./LessonCategoryPage.module.scss";

type PageParamKey = "slug";

type DerivedCategoryDataErrorValue = "notFound";

enum SortingMethod {
  Default = "default",
  Latest = "latest",
  Oldest = "oldest",
}

const sortingMethodToOrderByObjectDict = {
  [SortingMethod.Default]: { seq: "asc" },
  [SortingMethod.Latest]: { createdAt: "desc" },
  [SortingMethod.Oldest]: { createdAt: "asc" },
};

export const LESSON_CATEGORY_PAGE_PATH_PATTERN = "/lesson-category/:slug";

export function generateLessonCategoryPagePath(slug: string): string {
  return generatePath(LESSON_CATEGORY_PAGE_PATH_PATTERN, {
    slug,
  });
}

const LessonCategoryPage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const pageParams = useParams<PageParamKey>();

  const [sortingMethod, setSortingMethod] = useState<SortingMethod>(
    SortingMethod.Default
  );

  const {
    data: categoryData,
    loading: loadingCategoryData,
    error: fetchCategoryDataError,
  } = useQuery<FetchCategory, FetchCategoryVariables>(FETCH_CATEGORY, {
    errorPolicy: "all",
    variables: {
      slug: pageParams.slug!,
    },
  });
  const {
    data: lessonsData,
    loading: loadingLessonsData,
    error: fetchLessonsDataError,
  } = useQuery<FetchLessonsByCategory, FetchLessonsByCategoryVariables>(
    FETCH_LESSONS_BY_CATEGORY,
    {
      errorPolicy: "all",
      variables: {
        categorySlug: pageParams.slug!,
        orderBy: sortingMethodToOrderByObjectDict[sortingMethod],
      },
    }
  );

  const derivedCategoryDataErrorValue =
    useErrorHandlerWithDerivedValue<DerivedCategoryDataErrorValue>(
      fetchCategoryDataError,
      {
        name: "fetchCategoryData",
        errorHandler: (error, ctx) => {
          if (isApolloError(error)) {
            if (
              isGraphQLErrorsContainStrapiErrorName(
                error.graphQLErrors,
                StrapiErrorName.NOT_FOUND_ERROR
              )
            ) {
              return ctx.deriveValue("notFound");
            }
          }
          return ctx.unhandled;
        },
      }
    );
  useErrorHandler(fetchLessonsDataError, {
    name: "fetchLessonsData",
  });

  const sortingMethodOptions = useMemo(
    () => [
      {
        value: SortingMethod.Default,
        label: t("pages.lessonCategoryPage.sortingOptions.default"),
      },
      {
        value: SortingMethod.Latest,
        label: t("pages.lessonCategoryPage.sortingOptions.latest"),
      },
      {
        value: SortingMethod.Oldest,
        label: t("pages.lessonCategoryPage.sortingOptions.oldest"),
      },
    ],
    [t]
  );

  const onSortingMethodChange = useCallback((sortingMethod: SortingMethod) => {
    setSortingMethod(sortingMethod);
  }, []);

  const lessonColComponents = useMemo(() => {
    if (!lessonsData) {
      if (loadingLessonsData) {
        return createNumberList(3).map((index) => (
          <Col
            key={index}
            className={styles.lessonCol}
            lg={3}
            md={4}
            sm={6}
            xs={12}
          >
            <LessonItem loading={true} lesson={undefined} />
          </Col>
        ));
      }
      return null;
    }
    return lessonsData.fetchLessonsByCategory.map((lesson) => (
      <Col
        key={lesson.slug}
        className={styles.lessonCol}
        lg={3}
        md={4}
        sm={6}
        xs={12}
      >
        <LessonItem lesson={lesson} />
      </Col>
    ));
  }, [loadingLessonsData, lessonsData]);

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        title={t("pages.lessonCategoryPage.header.title")}
        backgroundImageSrc="/img/lesson-category/lesson-category-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        {derivedCategoryDataErrorValue === "notFound" && (
          <h2>{t("pages.lessonCategoryPage.notFoundMessage")}</h2>
        )}
        <div className={styles.toolbar}>
          {categoryData ? (
            <PBreadcrumb
              parentItems={[
                {
                  label: t("pages.lessonCategoriesPage.breadcrumbLabel"),
                  to: generateLessonCategoriesPagePath(),
                },
              ]}
              activeItemContent={
                <PBreadcrumbActiveItemLabel
                  label={categoryData.fetchCategory.title}
                />
              }
            />
          ) : (
            loadingCategoryData && (
              <Placeholder animation="glow" style={{ width: 120 }}>
                <Placeholder xs="12" />
              </Placeholder>
            )
          )}
          <SortingMethodSelect
            options={sortingMethodOptions}
            value={sortingMethod}
            onChange={onSortingMethodChange}
          />
        </div>

        {categoryData ? (
          <section>
            <p className={styles.categoryDescription}>
              {categoryData.fetchCategory.description}
            </p>
          </section>
        ) : (
          loadingCategoryData && (
            <section>
              <Placeholder
                className={styles.categoryDescription}
                as="p"
                animation="glow"
              >
                <Placeholder xs="12" />
                <Placeholder xs="12" />
              </Placeholder>
            </section>
          )
        )}

        <section>
          <Row className={classNames(styles.lessonRow, "g-3")}>
            {lessonColComponents}
          </Row>
        </section>
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default LessonCategoryPage;
