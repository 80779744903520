import classNames from "classnames";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styles from "./FullScreenLoading.module.scss";

interface Props {
  className?: string;
}

const FullScreenLoading: React.FC<Props> = React.memo((props) => {
  const { className } = props;
  return (
    <div className={classNames(styles.root, className)}>
      <Spinner animation="border" />
    </div>
  );
});

export default FullScreenLoading;
