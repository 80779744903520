export function modifyListItem<T>(
  list: T[],
  findFunc: (item: T) => boolean,
  modifyFunc: (item: T, index: number) => T
): T[] {
  const index = list.findIndex(findFunc);
  if (index === -1) {
    return list;
  }
  return [
    ...list.slice(0, index),
    modifyFunc(list[index], index),
    ...list.slice(index + 1),
  ];
}

export function removeListItem<T>(
  list: T[],
  findFunc: (item: T) => boolean
): T[] {
  const index = list.findIndex(findFunc);
  if (index === -1) {
    return list;
  }
  return [...list.slice(0, index), ...list.slice(index + 1)];
}

export function createNumberList(num: number): number[] {
  return Array(num)
    .fill(null)
    .map((_, index) => index);
}
