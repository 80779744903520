export function isNetworkConnectionError(error: Error): boolean {
  switch (error.message) {
    case "Failed to fetch": // Failed to fetch
    case "Load failed": // Safari
    case "NetworkError when attempting to fetch resource.": // Firefox
      return true;
    default:
      return false;
  }
}
