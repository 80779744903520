import React, { useEffect, useRef } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useImperativeErrorHandler } from "../../hooks/useErrorHandler";
import { LocalStorage } from "../../utils/local-storage";

interface Props {
  onInitialized: () => void;
}

/**
 * Restore persisted access token when app initializing.
 */
const InitUser: React.FC<Props> = React.memo((props) => {
  const { onInitialized } = props;

  const { tryLoginWithAccessToken } = useAuth();

  const handleError = useImperativeErrorHandler({
    name: "tryLoginWithAccessToken",
  });

  const onInitializedRef = useRef(onInitialized);

  useEffect(() => {
    const persistedToken = LocalStorage.accessToken.get();
    if (persistedToken === null) {
      onInitializedRef.current();
      return undefined;
    }

    let isCleanedUp = false;
    tryLoginWithAccessToken(persistedToken)
      .then((valid) => {
        if (isCleanedUp) {
          return;
        }
        if (!valid) {
          LocalStorage.accessToken.remove();
        }
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        if (isCleanedUp) {
          return;
        }
        onInitializedRef.current();
      });

    return () => {
      isCleanedUp = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Bind onInitializedRef
  useEffect(() => {
    onInitializedRef.current = onInitialized;
  }, [onInitialized]);

  return null;
});

export default InitUser;
