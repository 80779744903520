import classNames from "classnames";
import React from "react";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";
import { useTranslation } from "react-i18next";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { FetchLessonsByCategory_fetchLessonsByCategory } from "../../graphql/__generated__/FetchLessonsByCategory";
import { generateLessonPagePath } from "../../pages/lesson/LessonPage";
import LinkButton from "../button/LinkButton";
import DurationDisplay from "../duration-display/DurationDisplay";
import FixedRatioImage from "../fixed-ratio-image/FixedRatioImage";
import IconButtonContent from "../icon-button-content/IconButtonContent";
import styles from "./LessonItem.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  lesson: FetchLessonsByCategory_fetchLessonsByCategory | undefined;
}

const LessonItem: React.FC<Props> = React.memo((props) => {
  const { className, loading, lesson } = props;

  const { t } = useTranslation();

  if (!lesson) {
    if (loading) {
      return (
        <Card className={classNames(styles.card, className)}>
          <Placeholder
            className="ratio"
            animation="glow"
            style={{
              "--bs-aspect-ratio": `${(106 / 193) * 100}%`,
            }}
          >
            <Placeholder xs={12} />
          </Placeholder>
          <Card.Body>
            <Placeholder className={styles.title} as="h4" animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
            <Placeholder className={styles.duration} as="div" animation="glow">
              <Placeholder xs={4} />
            </Placeholder>
            {/* TODO(nicolas 23Jun2022): adjust button placeholder style */}
            <Placeholder.Button
              className={styles.watchButton}
              variant="primary"
              size="sm"
              xs={6}
            />
          </Card.Body>
        </Card>
      );
    }
    return null;
  }

  return (
    <Card className={classNames(styles.card, className)}>
      <FixedRatioImage aspectRatio={193 / 106} src={lesson.thumbnail.url} />
      <Card.Body>
        <h4 className={styles.title}>{lesson.title}</h4>
        <div>
          <DurationDisplay
            className={styles.duration}
            valueClass={styles.durationValue}
            duration={lesson.videoLength}
          />
        </div>
        <LinkButton
          className={styles.watchButton}
          variant="primary"
          size="sm"
          to={generateLessonPagePath(lesson.slug)}
        >
          <IconButtonContent rightIcon={<MdOutlineArrowForwardIos />}>
            {t("components.lessonItem.watchButton")}
          </IconButtonContent>
        </LinkButton>
      </Card.Body>
    </Card>
  );
});

export default LessonItem;
