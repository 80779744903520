import classNames from "classnames";
import React from "react";
import { Event } from "../../models/event";
import styles from "./SimpleEventItem.module.scss";

interface Props {
  className?: string;
  event: Event;
}

const SimpleEventItem: React.FC<Props> = React.memo((props) => {
  const { className, event } = props;
  return (
    <div className={classNames(styles.root, className)}>
      <span className={styles.date}>{event.startTime.format("HH:mm")}</span>
      <span className={classNames(styles.vr, "vr")} />
      <div className={styles.details}>
        {event.location && <div className={styles.host}>{event.location}</div>}
        <div className={styles.title}>{event.title}</div>
      </div>
    </div>
  );
});

export default SimpleEventItem;
