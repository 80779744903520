import classNames from "classnames";
import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";
import styles from "./PFinCalculatorInputField.module.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  value: string | number;
  onChange?: (value: string) => void;
}

const PFinCalculatorInputField: React.FC<Props> = React.memo((props) => {
  const { className, disabled, readOnly, value, onChange } = props;

  const _onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  return (
    <Form.Control
      className={classNames(styles.formControl, className)}
      type="number"
      disabled={disabled}
      readOnly={readOnly}
      value={value}
      onChange={_onChange}
    />
  );
});

export default PFinCalculatorInputField;
