import classNames from "classnames";
import React, { useMemo } from "react";
import { To } from "react-router-dom";
import LinkButton from "../button/LinkButton";
import styles from "./PBreadcrumb.module.scss";

interface BreadcrumbItem {
  to: To;
  label: string | undefined;
}

interface PBreadcrumbActiveItemLabelProps {
  className?: string;
  label: string | undefined;
}

interface PBreadcrumbProps {
  className?: string;
  parentItems: BreadcrumbItem[];
  activeItemClass?: string;
  activeItemContent: React.ReactNode;
}

export const PBreadcrumbActiveItemLabel: React.FC<PBreadcrumbActiveItemLabelProps> =
  React.memo((props) => {
    const { className, label } = props;
    return <span className={classNames(styles.label, className)}>{label}</span>;
  });

const PBreadcrumb: React.FC<PBreadcrumbProps> = React.memo((props) => {
  const { className, parentItems, activeItemClass, activeItemContent } = props;

  const itemComponents = useMemo(
    () =>
      parentItems.map((item, index) => (
        <React.Fragment key={index}>
          <li className={classNames(styles.item, styles.withLink)}>
            <LinkButton
              className={styles.link}
              variant="outline-primary"
              to={item.to}
            >
              {item.label}
            </LinkButton>
          </li>
        </React.Fragment>
      )),
    [parentItems]
  );

  return (
    <nav className={classNames(styles.root, className)}>
      <ol className={styles.list}>
        {itemComponents}
        <li className={classNames(styles.item, activeItemClass)}>
          {activeItemContent}
        </li>
      </ol>
    </nav>
  );
});

export default PBreadcrumb;
