import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserFetchPFinSimulate_userFetchPFinSimulate_reports } from "../../graphql/__generated__/UserFetchPFinSimulate";
import { getNumberDisplayTextWithChineseNumberUnit } from "../../utils/text";
import PFinCalculatorPercentageIndicator from "./PFinCalculatorPercentageIndicator";
import styles from "./PFinCalculatorTermsTable.module.scss";

interface Props {
  className?: string;
  basicPremium: number;
  pFinReports: UserFetchPFinSimulate_userFetchPFinSimulate_reports[];
}

const PFinCalculatorTermsTable: React.FC<Props> = React.memo((props) => {
  const { className, basicPremium, pFinReports } = props;

  const { t } = useTranslation();

  const renderDataRow = useCallback(
    (item: UserFetchPFinSimulate_userFetchPFinSimulate_reports) => {
      const cashValueText = getNumberDisplayTextWithChineseNumberUnit(
        t,
        item.cashValue,
        0
      );
      const principalText = getNumberDisplayTextWithChineseNumberUnit(
        t,
        basicPremium,
        0
      );
      const expenseText = getNumberDisplayTextWithChineseNumberUnit(
        t,
        -item.accumulatedRepaidIC,
        0
      );
      const returnText = getNumberDisplayTextWithChineseNumberUnit(
        t,
        item.surrenderValAftRepayPrincipalNInterestNC,
        0
      );
      const netGrowthAmountText = getNumberDisplayTextWithChineseNumberUnit(
        t,
        item.netGrowthAmount,
        0
      );
      return (
        <tr key={item.policyYear}>
          <td className={styles.dataCell}>{item.policyYear}</td>
          <td className={styles.dataCell}>{cashValueText}</td>
          <td className={styles.dataCell}>{principalText}</td>
          <td className={styles.dataCell}>{expenseText}</td>
          <td className={styles.dataCell}>{returnText}</td>
          <td className={styles.dataCell}>{netGrowthAmountText}</td>
          <td className={styles.dataCell}>
            <PFinCalculatorPercentageIndicator value={item.netGrowthRate} />
          </td>
        </tr>
      );
    },
    [basicPremium, t]
  );

  const headerRowComponents = useMemo(() => {
    return (
      <tr>
        <th className={styles.headerCell}>
          {t("components.pFinCalculatorTermsTable.term")}
        </th>
        <th className={styles.headerCell}>
          {" "}
          {t("components.pFinCalculatorTermsTable.cashValue")}
        </th>
        <th className={styles.headerCell}>
          <span
            className={classNames(
              styles.headerLabelWithLegendIndicator,
              styles.principal
            )}
          >
            {t("components.pFinCalculatorTermsTable.principal")}
          </span>
        </th>
        <th className={styles.headerCell}>
          <span
            className={classNames(
              styles.headerLabelWithLegendIndicator,
              styles.expense
            )}
          >
            {t("components.pFinCalculatorTermsTable.expense")}
          </span>
        </th>
        <th className={styles.headerCell}>
          <span
            className={classNames(
              styles.headerLabelWithLegendIndicator,
              styles.return
            )}
          >
            {t("components.pFinCalculatorTermsTable.return")}
          </span>
        </th>
        <th className={styles.headerCell} colSpan={2}>
          {t("components.pFinCalculatorTermsTable.netGrowthAmount")}
        </th>
      </tr>
    );
  }, [t]);

  const dataRowComponents = useMemo(() => {
    return pFinReports.map((item) => renderDataRow(item));
  }, [pFinReports, renderDataRow]);

  return (
    <table className={classNames(styles.root, className)}>
      <thead>{headerRowComponents}</thead>
      <tbody>{dataRowComponents}</tbody>
    </table>
  );
});

export default PFinCalculatorTermsTable;
