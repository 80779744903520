import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import styles from "./PFinCalculatorSelectField.module.scss";

interface SelectOption {
  value: string;
  label: string;
}

interface Props {
  className?: string;
  disabled?: boolean;
  options: SelectOption[];
  value: string;
  onChange?: (value: string) => void;
}

const PFinCalculatorSelectField: React.FC<Props> = React.memo((props) => {
  const { className, disabled, options, value, onChange } = props;

  const _onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  const selectOptionComponents = useMemo(() => {
    return options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  }, [options]);

  return (
    <Form.Select
      className={classNames(styles.formSelect, className)}
      disabled={disabled}
      value={value}
      onChange={_onChange}
    >
      {selectOptionComponents}
    </Form.Select>
  );
});

export default PFinCalculatorSelectField;
