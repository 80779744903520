import classNames from "classnames";
import { Property } from "csstype";
import React, { useMemo } from "react";
import Image, { ImageProps } from "react-bootstrap/Image";
import styles from "./FixedRatioImage.module.scss";

interface Props extends ImageProps {
  imageClass?: string;
  aspectRatio: number;
  objectFit?: Property.ObjectFit;
}

const FixedRatioImage: React.FC<Props> = React.memo((props) => {
  const {
    className,
    imageClass,
    aspectRatio,
    objectFit = "cover",
    style,
    ...restProps
  } = props;

  const rootStyle = useMemo(
    () => ({
      paddingTop: `${100 / aspectRatio}%`,
    }),
    [aspectRatio]
  );

  const imageStyle = useMemo(
    () => ({
      objectFit,
      ...style,
    }),
    [objectFit, style]
  );

  return (
    <div className={classNames(styles.root, className)} style={rootStyle}>
      <Image
        className={classNames(styles.image, imageClass)}
        style={imageStyle}
        {...restProps}
      />
    </div>
  );
});

export default FixedRatioImage;
