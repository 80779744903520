export function optional<T>(value: T | null): T | undefined {
  return value ?? undefined;
}

export function optionalMapping<T, U>(
  value: T | null,
  mapper: (value: T) => U
): U | undefined {
  if (value === null) {
    return undefined;
  }
  return mapper(value);
}
