import { toSvg } from "html-to-image";
import { Options } from "html-to-image/lib/options";
import {
  createImage,
  getPixelRatio,
  getNodeWidth,
  getNodeHeight,
} from "html-to-image/lib/util";

/**
 * @see {@link https://github.com/bubkoo/html-to-image/blob/v1.10.0/src/index.ts#L36}
 */
const dimensionCanvasLimit = 16384; // as per https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size

/**
 * @see {@link https://github.com/bubkoo/html-to-image/blob/v1.10.0/src/index.ts#L15-L20}
 */
function getImageSize(node: HTMLElement, options: Options = {}) {
  const width = options.width ?? getNodeWidth(node);
  const height = options.height ?? getNodeHeight(node);

  return { width, height };
}

/**
 * @see {@link https://github.com/bubkoo/html-to-image/blob/v1.10.0/src/index.ts#L38-L62}
 */
function checkCanvasDimensions(canvas: HTMLCanvasElement) {
  if (
    canvas.width > dimensionCanvasLimit ||
    canvas.height > dimensionCanvasLimit
  ) {
    if (
      canvas.width > dimensionCanvasLimit &&
      canvas.height > dimensionCanvasLimit
    ) {
      if (canvas.width > canvas.height) {
        canvas.height *= dimensionCanvasLimit / canvas.width;
        canvas.width = dimensionCanvasLimit;
      } else {
        canvas.width *= dimensionCanvasLimit / canvas.height;
        canvas.height = dimensionCanvasLimit;
      }
    } else if (canvas.width > dimensionCanvasLimit) {
      canvas.height *= dimensionCanvasLimit / canvas.width;
      canvas.width = dimensionCanvasLimit;
    } else {
      canvas.width *= dimensionCanvasLimit / canvas.height;
      canvas.height = dimensionCanvasLimit;
    }
  }
}

/**
 * @see {@link https://github.com/bubkoo/html-to-image/blob/v1.10.0/src/index.ts#L63-L96}
 */
export async function svgToCanvas(
  svg: string,
  node: HTMLElement,
  options: Options = {}
): Promise<HTMLCanvasElement> {
  return createImage(svg).then((img) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d")!;
    const ratio = options.pixelRatio ?? getPixelRatio();
    const { width, height } = getImageSize(node, options);

    const canvasWidth = options.canvasWidth ?? width;
    const canvasHeight = options.canvasHeight ?? height;

    canvas.width = canvasWidth * ratio;
    canvas.height = canvasHeight * ratio;

    if (!options.skipAutoScale) {
      checkCanvasDimensions(canvas);
    }
    canvas.style.width = `${canvasWidth}`;
    canvas.style.height = `${canvasHeight}`;

    if (options.backgroundColor) {
      context.fillStyle = options.backgroundColor;
      context.fillRect(0, 0, canvas.width, canvas.height);
    }

    context.drawImage(img, 0, 0, canvas.width, canvas.height);

    return canvas;
  });
}

/**
 * Fix canvas disappear during first rendering on Safari
 * @see {@link https://github.com/bubkoo/html-to-image/issues/199}
 * @see {@link https://github.com/bubkoo/html-to-image/issues/214}
 */
export async function enhancedHtmlToBlob<T extends HTMLElement>(
  node: T,
  firstRendering: boolean,
  options?: Options
): Promise<Blob | null> {
  const svg = await toSvg(node, options);
  if (firstRendering) {
    await svgToCanvas(svg, node, options);
  }
  const canvas = await svgToCanvas(svg, node, options);
  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob(resolve);
  });
  return blob;
}
