import React, { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FullScreenLoading from "../../components/full-sceen-loading/FullScreenLoading";
import { StrapiErrorMessage, StrapiErrorName } from "../../constants/strapi";
import { useAPIClient } from "../../contexts/APIClientContext";
import { useAuth } from "../../hooks/useAuth";
import {
  useImperativeErrorHandler,
  CustomErrorHandler,
} from "../../hooks/useErrorHandler";
import { StrapiAPIError } from "../../models/errors/api";
import { InvalidAccessTokenError } from "../../models/errors/app";
import { generateHomePagePath } from "../home/HomePage";

export const GOOGLE_AUTH_REDIRECT_PAGE_PATH_PATTERN =
  "/connect/google/redirect";

const GoogleAuthRedirectPage: React.FC = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { tryLoginWithAccessToken } = useAuth();
  const { strapiRestAPIClient } = useAPIClient();

  const handleGoogleAuthError = useImperativeErrorHandler({
    name: "googleAuth",
    customErrorHandler: useCallback<CustomErrorHandler>((error, ctx) => {
      if (error instanceof StrapiAPIError) {
        if (
          error.details.name === StrapiErrorName.APPLICATION_ERROR &&
          error.details.message === StrapiErrorMessage.EMAIL_IS_ALREADY_TAKEN
        ) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          ctx.showErrorToast(
            ctx.t("pages.googleAuthRedirectPage.emailIsAlreadyTaken")
          );
          return true;
        }
      }
      return false;
    }, []),
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      try {
        const data = await strapiRestAPIClient.requestAuthProviderCallback(
          "google",
          location.search
        );

        const valid = await tryLoginWithAccessToken(data.jwt);
        if (!valid) {
          throw new InvalidAccessTokenError();
        }

        navigate(generateHomePagePath());
      } catch (error: unknown) {
        navigate(generateHomePagePath());
        handleGoogleAuthError(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FullScreenLoading />;
});

export default GoogleAuthRedirectPage;
