import React, { useCallback, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import PinchZoomImage from "../../components/pinch-zoom-image/PinchZoomImage";
import { UserFetchAlbumPhotos_userFetchAlbumPhotos_data } from "../../graphql/__generated__/UserFetchAlbumPhotos";
import styles from "./AlbumPhotoViewerModal.module.scss";

interface Data {
  albumTitle: string;
  albumPhoto: UserFetchAlbumPhotos_userFetchAlbumPhotos_data;
}

interface Props {
  show: boolean;
  data: Data | undefined;
  onModalHide: () => void;
}

interface UseAlbumPhotoViewerModalValues {
  open: (data: Data) => void;
  close: () => void;
  props: Props;
}

export function useAlbumPhotoViewerModal(): UseAlbumPhotoViewerModalValues {
  const [show, setShow] = useState(false);
  const [data, setData] = useState<Data | undefined>(undefined);

  const open = useCallback((data: Data) => {
    setData(data);
    setShow(true);
  }, []);

  const close = useCallback(() => {
    setShow(false);
  }, []);

  const onModalHide = useCallback(() => {
    setShow(false);
  }, []);

  const values = useMemo<UseAlbumPhotoViewerModalValues>(
    () => ({
      open,
      close,
      props: {
        show,
        data,
        onModalHide,
      },
    }),
    [close, onModalHide, open, data, show]
  );

  return values;
}

const AlbumPhotoViewerModal: React.FC<Props> = React.memo((props) => {
  const { show, data, onModalHide } = props;

  const contentComponent = useMemo(() => {
    if (!data) {
      return null;
    }
    return (
      <>
        <Modal.Header closeButton={true}>
          <Modal.Title>{data.albumTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <PinchZoomImage
            className={styles.pinchZoomImage}
            imageClass={styles.image}
            src={data.albumPhoto.image.readSignedUrl}
          />
        </Modal.Body>
        {data.albumPhoto.alt && (
          <Modal.Footer>
            <p className={styles.caption}>{data.albumPhoto.alt}</p>
          </Modal.Footer>
        )}
      </>
    );
  }, [data]);

  return (
    <Modal
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      size="xl"
      centered={true}
      show={show}
      onHide={onModalHide}
    >
      {contentComponent}
    </Modal>
  );
});

export default AlbumPhotoViewerModal;
