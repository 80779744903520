import React, { useCallback, useRef } from "react";
import QuickPinchZoom, {
  make3dTransformValue,
  UpdateAction,
} from "react-quick-pinch-zoom";

interface PinchZoomImageProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  imageClass?: string;
}

const PinchZoomImage: React.FC<PinchZoomImageProps> = React.memo((props) => {
  const { className, imageClass, ...restProps } = props;

  const imgRef = useRef<HTMLImageElement>(null);

  const onUpdate = useCallback(({ x, y, scale }: UpdateAction) => {
    imgRef.current?.style.setProperty(
      "transform",
      make3dTransformValue({ x, y, scale })
    );
  }, []);

  return (
    <QuickPinchZoom containerProps={{ className }} onUpdate={onUpdate}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img ref={imgRef} className={imageClass} {...restProps} />
    </QuickPinchZoom>
  );
});

export default PinchZoomImage;
