import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import React, { useMemo } from "react";
import Col from "react-bootstrap/Col";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import MaxWidthContainer from "../../components/container/MaxWidthContainer";
import SimpleEventItem from "../../components/events/SimpleEventItem";
import HorizontalRule from "../../components/horizontal-rule/HorizontalRule";
import SimpleNewsItem from "../../components/news/SimpleNewsItem";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import { TIMEZONE_HK } from "../../constants/moment";
import {
  USER_FETCH_ALBUMS,
  USER_FETCH_ARTICLES,
  USER_FETCH_EVENTS,
} from "../../graphql/queries";
import {
  UserFetchArticles,
  UserFetchArticlesVariables,
} from "../../graphql/__generated__/UserFetchArticles";
import {
  UserFetchEvents,
  UserFetchEventsVariables,
} from "../../graphql/__generated__/UserFetchEvents";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { Event } from "../../models/event";
import { createNumberList } from "../../utils/list";
import { convertEventFromGQLModel } from "../../utils/models/event";
import { generateNewsDetailsPagePath } from "../news/NewsDetailsPage";
import styles from "./HomePage.module.scss";
import {
  UserFetchAlbums,
  UserFetchAlbumsVariables,
} from "../../graphql/__generated__/UserFetchAlbums";
import AlbumItem from "../../components/album/AlbumItem";

const MAX_NEWS = 3;
const MAX_ALBUMS = 4;
const MAX_EVENTS = 10;

export const HOME_PAGE_PATH_PATTERN = "/home";

export function generateHomePagePath(): string {
  return generatePath(HOME_PAGE_PATH_PATTERN, {});
}

function groupEventsByStartDay(events: Event[]) {
  return events
    .sort((a, b) => a.startTime.valueOf() - b.startTime.valueOf())
    .reduce((map, item) => {
      const dayTimestamp = item.startTime
        .clone()
        .tz(TIMEZONE_HK)
        .startOf("day")
        .valueOf();
      map.set(dayTimestamp, [...(map.get(dayTimestamp) ?? []), item]);
      return map;
    }, new Map<number, Event[]>());
}

const HomePage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const {
    data: articlesData,
    loading: loadingArticlesData,
    error: articlesError,
  } = useQuery<UserFetchArticles, UserFetchArticlesVariables>(
    USER_FETCH_ARTICLES,
    {
      variables: {
        offset: 0,
        limit: MAX_NEWS,
      },
    }
  );

  const {
    data: albumsData,
    loading: loadingAlbums,
    error: albumsError,
  } = useQuery<UserFetchAlbums, UserFetchAlbumsVariables>(USER_FETCH_ALBUMS, {
    variables: {
      listQuery: {
        offset: 0,
        limit: MAX_ALBUMS,
      },
    },
  });

  const {
    data: eventsData,
    loading: loadingEventsData,
    error: eventsError,
  } = useQuery<UserFetchEvents, UserFetchEventsVariables>(USER_FETCH_EVENTS, {
    variables: useMemo(
      () => ({
        startTime: moment().toISOString(),
        limit: MAX_EVENTS,
      }),
      []
    ),
  });

  useErrorHandler(articlesError, {
    name: "articlesData",
  });

  useErrorHandler(albumsError, {
    name: "albumsData",
  });

  useErrorHandler(eventsError, {
    name: "eventsData",
  });

  const dayEventsMap = useMemo(() => {
    if (!eventsData) {
      return undefined;
    }
    return groupEventsByStartDay(
      eventsData.userFetchEvents.data.map((event) =>
        convertEventFromGQLModel(event)
      )
    );
  }, [eventsData]);

  const newsComponents = useMemo(() => {
    if (!articlesData) {
      if (loadingArticlesData) {
        return createNumberList(MAX_NEWS).map((index) => (
          <Placeholder key={index} animation="glow">
            <Placeholder xs="12" />
          </Placeholder>
        ));
      }
      return null;
    }
    if (articlesData.userFetchArticles.data.length === 0) {
      return (
        <div className={styles.noItemsMessage}>
          {t("pages.homePage.news.noItems")}
        </div>
      );
    }
    return articlesData.userFetchArticles.data.map((item) => (
      <SimpleNewsItem
        key={item.uuid}
        className={styles.item}
        date={moment(item.createdAt)}
        title={item.title}
        pinned={item.pinned}
        to={generateNewsDetailsPagePath(encodeURIComponent(item.uuid))}
      />
    ));
  }, [articlesData, loadingArticlesData, t]);

  const albumItemColComponents = useMemo(() => {
    if (loadingAlbums) {
      return createNumberList(4).map((index) => (
        <Col key={index} xl={3} lg={4} sm={6} xs={12}>
          <AlbumItem loading={true} album={undefined} />
        </Col>
      ));
    }
    if (!albumsData) {
      return null;
    }
    return albumsData.userFetchAlbums.data.map((album) => {
      return (
        <Col key={album.uuid} xl={3} lg={4} sm={6} xs={12}>
          <AlbumItem album={album} />
        </Col>
      );
    });
  }, [albumsData, loadingAlbums]);

  const dailyEventsComponents = useMemo(() => {
    if (!dayEventsMap) {
      if (loadingEventsData) {
        return createNumberList(3).map((index) => (
          <Placeholder key={index} animation="glow">
            <Placeholder xs="12" />
          </Placeholder>
        ));
      }
      return null;
    }
    if (dayEventsMap.size === 0) {
      return (
        <div className={styles.noItemsMessage}>
          {t("pages.homePage.events.noItems")}
        </div>
      );
    }
    return Array.from(dayEventsMap.entries()).map(
      ([dayTimestamp, list], index) => (
        <div key={dayTimestamp} className={styles.dailyEventsItem}>
          {index !== 0 && <HorizontalRule />}
          <span className={styles.dayLabel}>
            {moment(dayTimestamp).format("MMMDo dddd")}
          </span>
          <div>
            {list.map((item) => (
              <SimpleEventItem
                key={item.uuid}
                className={styles.eventItem}
                event={item}
              />
            ))}
          </div>
        </div>
      )
    );
  }, [dayEventsMap, loadingEventsData, t]);

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <PageCoverHeader
        className={styles.header}
        backgroundImageSrc="/img/home/home-banner.png"
      />
      <MaxWidthContainer className={styles.contentRow}>
        <div className={styles.primaryCol}>
          <section className={styles.newsSection}>
            <h3 className={styles.title}>{t("pages.homePage.news.title")}</h3>
            <div className={styles.list}>{newsComponents}</div>
          </section>
          <HorizontalRule />
          <section className={styles.albumsSection}>
            <h4 className={styles.title}>{t("pages.homePage.albums.title")}</h4>
            <Row className="g-3">{albumItemColComponents}</Row>
          </section>
        </div>
        <div className={styles.secondaryCol}>
          <section className={styles.eventsSection}>
            <h4 className={styles.title}>{t("pages.homePage.events.title")}</h4>
            <div className={styles.dailyEventsList}>
              {dailyEventsComponents}
            </div>
          </section>
        </div>
      </MaxWidthContainer>
    </PageContainerWithBackground>
  );
});

export default HomePage;
