import classNames from "classnames";
import React from "react";
import styles from "./PrimaryMenuItemContent.module.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  icon: React.ReactNode;
  label: string;
}

const PrimaryMenuItemContent: React.FC<Props> = React.memo((props) => {
  const { className, disabled, icon, label } = props;

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.disabled]: disabled,
      })}
    >
      <span className={styles.iconWrapper}>{icon}</span>
      <span className={styles.label}>{label}</span>
    </div>
  );
});

export default PrimaryMenuItemContent;
