import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useParams } from "react-router-dom";
import BackButton from "../../components/button/BackButton";
import PageContainerWithBackground from "../../components/page/PageContainerWithBackground";
import PageContentContainer from "../../components/page/PageContentContainer";
import HorizontalRule from "../../components/horizontal-rule/HorizontalRule";
import PageBackground from "../../components/page/PageBackground";
import PageCoverHeader from "../../components/page/PageCoverHeader";
import { LoadingModal } from "../../contexts/DialogContext";
import { USER_FETCH_ARTICLE } from "../../graphql/queries";
import {
  UserFetchArticle,
  UserFetchArticleVariables,
} from "../../graphql/__generated__/UserFetchArticle";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import styles from "./NewsDetailsPage.module.scss";

type PageParamKey = "uuid";

export const NEWS_DETAILS_PAGE_PATH_PATTERN = "/news/:uuid";

export function generateNewsDetailsPagePath(uuid: string): string {
  return generatePath(NEWS_DETAILS_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const NewsDetailsPage: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const pageParams = useParams<PageParamKey>();

  const {
    data: articleData,
    loading,
    error: articleError,
  } = useQuery<UserFetchArticle, UserFetchArticleVariables>(
    USER_FETCH_ARTICLE,
    {
      variables: {
        uuid: decodeURIComponent(pageParams.uuid!),
      },
    }
  );

  useErrorHandler(articleError, {
    name: "articleData",
  });

  return (
    <PageContainerWithBackground
      className={styles.root}
      stickyBackground={true}
      backgroundComponent={<PageBackground />}
    >
      <LoadingModal show={loading} />
      <PageCoverHeader
        className={styles.header}
        title={t("pages.newsDetailsPage.header.title")}
        backgroundImageSrc="/img/news-details/news-details-banner.png"
      />
      <PageContentContainer className={styles.contentContainer}>
        <nav>
          <BackButton>{t("pages.newsDetailsPage.back")}</BackButton>
        </nav>
        <HorizontalRule />
        {articleData && (
          <div className={styles.card}>
            <div>
              <div className={styles.metaRow}>
                {t("pages.newsDetailsPage.createdAt", {
                  date: moment(articleData.userFetchArticle.createdAt).format(
                    "MMMDo"
                  ),
                })}
              </div>
              <div className={styles.metaRow}>
                {t("pages.newsDetailsPage.author", {
                  author: articleData.userFetchArticle.author,
                })}
              </div>
            </div>
            <HorizontalRule />
            <div
              className={styles.cardContent}
              dangerouslySetInnerHTML={{
                __html: articleData.userFetchArticle.content,
              }}
            />
          </div>
        )}
      </PageContentContainer>
    </PageContainerWithBackground>
  );
});

export default NewsDetailsPage;
