import classNames from "classnames";
import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";
import styles from "./PFinCalculatorPriceField.module.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  currency: string;
  value: string | number;
  onChange?: (value: string) => void;
}

const PFinCalculatorPriceField: React.FC<Props> = React.memo((props) => {
  const {
    className,
    disabled,
    readOnly,
    isInvalid,
    placeholder,
    currency,
    value,
    onChange,
  } = props;

  const _onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  return (
    <div className={classNames(styles.root, className)}>
      <span className={styles.currency}>{currency}</span>
      <Form.Control
        className={styles.formControl}
        type="number"
        disabled={disabled}
        readOnly={readOnly}
        isInvalid={isInvalid}
        placeholder={placeholder}
        value={value}
        onChange={_onChange}
      />
    </div>
  );
});

export default PFinCalculatorPriceField;
