import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  BsBellFill,
  BsCalculatorFill,
  BsCalendarWeekFill,
  BsCardChecklist,
  BsEaselFill,
  BsFlagFill,
  BsGiftFill,
  BsHouseDoorFill,
  BsImages,
  BsPersonCircle,
  BsTrophyFill,
} from "react-icons/bs";
import {
  Menu,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "react-pro-sidebar";
import { useUserContext } from "../../contexts/UserContext";
import { useAuth } from "../../hooks/useAuth";
import { generateAccountPagePath } from "../../pages/account/AccountPage";
import { ALBUM_PAGE_PATH_PATTERN } from "../../pages/album/AlbumPage";
import {
  ALBUMS_PAGE_PATH_PATTERN,
  generateAlbumsPagePath,
} from "../../pages/album/AlbumsPage";
import { generateBillboardPagePath } from "../../pages/billboard/BillboardPage";
import { generateEventsPagePath } from "../../pages/events/EventsPage";
import { generateHomePagePath } from "../../pages/home/HomePage";
import { generateIncentivesPagePath } from "../../pages/incentives/IncentivesPage";
import { generateLeadsPagePath } from "../../pages/lead/LeadsPage";
import {
  generateLessonCategoriesPagePath,
  LESSON_CATEGORIES_PAGE_PATH_PATTERN,
} from "../../pages/lesson-categories/LessonCategoriesPage";
import { LESSON_CATEGORY_PAGE_PATH_PATTERN } from "../../pages/lesson-category/LessonCategoryPage";
import { LESSON_PAGE_PATH_PATTERN } from "../../pages/lesson/LessonPage";
import { generateLoginPagePath } from "../../pages/login/LoginPage";
import { NEWS_DETAILS_PAGE_PATH_PATTERN } from "../../pages/news/NewsDetailsPage";
import {
  generateNewsPagePath,
  NEWS_PAGE_PATH_PATTERN,
} from "../../pages/news/NewsPage";
import {
  generatePFinCalculatorPagePath,
  P_FIN_CALCULATOR_PAGE_PATH_PATTERN,
} from "../../pages/p-fin-calculator/PFinCalculatorPage";
import { P_FIN_CALCULATOR_RESULT_PAGE_PATH_PATTERN } from "../../pages/p-fin-calculator/PFinCalculatorResultPage";
import { generatePurposePagePath } from "../../pages/purpose/PurposePage";
import AlertModal, { useAlertModal } from "../modals/AlertModal";
import LogoutMenuItemContent from "./menu-item-content/LogoutMenuItemContent";
import PrimaryMenuItemContent from "./menu-item-content/PrimaryMenuItemContent";
import SecondaryMenuItemContent from "./menu-item-content/SecondaryMenuItemContent";
import ToggleMenuItemContent from "./menu-item-content/ToggleMenuItemContent";
import UserMenuItemContent from "./menu-item-content/UserMenuItemContent";
import MenuButtonItem from "./menu-item/MenuButtonItem";
import MenuLinkItem from "./menu-item/MenuLinkItem";
import styles from "./SideMenu.module.scss";

interface Props {
  className?: string;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideMenu = React.memo(
  React.forwardRef<HTMLElement, Props>((props, ref) => {
    const { className, isOpened, setIsOpened } = props;

    const { t } = useTranslation();
    const { userState } = useUserContext();
    const { logout } = useAuth();
    const { open: openAlert, props: alertModalProps } = useAlertModal();

    const userMenuItemComponent = useMemo(() => {
      if (!userState.loggedIn) {
        return (
          <MenuLinkItem
            className={styles.userMenuItem}
            to={generateLoginPagePath()}
          >
            <UserMenuItemContent
              icon={<BsPersonCircle />}
              label={t("components.sideMenu.userMenu.guest")}
              linkLabel={t("components.sideMenu.userMenu.login")}
            />
          </MenuLinkItem>
        );
      }

      const userDisplayName =
        userState.user.firstName !== null || userState.user.lastName !== null
          ? [userState.user.firstName, userState.user.lastName]
              .filter((text) => text !== null)
              .join(" ")
          : userState.user.email;

      return (
        <MenuLinkItem
          className={styles.userMenuItem}
          to={generateAccountPagePath()}
        >
          <UserMenuItemContent
            icon={<BsPersonCircle />}
            label={userDisplayName}
            linkLabel={t("components.sideMenu.userMenu.viewAccount")}
          />
        </MenuLinkItem>
      );
    }, [userState.loggedIn, userState.user, t]);

    const toggleSideMenu = useCallback(() => {
      setIsOpened((prev) => !prev);
    }, [setIsOpened]);

    const onLogoutClick = useCallback(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      openAlert({
        message: t("components.sideMenu.confirmToLogout"),
      }).then((reason) => {
        if (reason === "positive") {
          logout();
        }
      });
    }, [logout, openAlert, t]);

    return (
      <ProSidebar
        ref={ref}
        className={classNames(styles.proSidebar, className)}
        collapsed={!isOpened}
      >
        <SidebarHeader className={styles.header}>
          <Menu>
            <MenuButtonItem onClick={toggleSideMenu}>
              <ToggleMenuItemContent />
            </MenuButtonItem>
          </Menu>
        </SidebarHeader>
        <SidebarContent className={styles.content}>
          <Menu>
            {userMenuItemComponent}

            <MenuLinkItem
              className={styles.homeMenuItem}
              disabled={!userState.loggedIn}
              to={generateHomePagePath()}
            >
              <PrimaryMenuItemContent
                disabled={!userState.loggedIn}
                icon={<BsHouseDoorFill />}
                label={t("components.sideMenu.home")}
              />
            </MenuLinkItem>

            <hr className={styles.hrMenuItem} />

            <MenuLinkItem
              disabled={!userState.loggedIn}
              to={generatePurposePagePath()}
            >
              <SecondaryMenuItemContent
                disabled={!userState.loggedIn}
                icon={<BsFlagFill />}
                label={t("components.sideMenu.purpose")}
              />
            </MenuLinkItem>
            <MenuLinkItem
              disabled={!userState.loggedIn}
              to={generateNewsPagePath()}
              activePatterns={[
                NEWS_PAGE_PATH_PATTERN,
                NEWS_DETAILS_PAGE_PATH_PATTERN,
              ]}
            >
              <SecondaryMenuItemContent
                disabled={!userState.loggedIn}
                icon={<BsBellFill />}
                label={t("components.sideMenu.news")}
              />
            </MenuLinkItem>
            <MenuLinkItem
              disabled={!userState.loggedIn}
              to={generateEventsPagePath()}
            >
              <SecondaryMenuItemContent
                disabled={!userState.loggedIn}
                icon={<BsCalendarWeekFill />}
                label={t("components.sideMenu.events")}
              />
            </MenuLinkItem>
            <MenuLinkItem
              disabled={!userState.loggedIn}
              to={generateBillboardPagePath()}
            >
              <SecondaryMenuItemContent
                disabled={!userState.loggedIn}
                icon={<BsTrophyFill />}
                label={t("components.sideMenu.billboard")}
              />
            </MenuLinkItem>
            <MenuLinkItem
              disabled={!userState.loggedIn}
              to={generateLeadsPagePath()}
            >
              <SecondaryMenuItemContent
                disabled={!userState.loggedIn}
                icon={<BsCardChecklist />}
                label={t("components.sideMenu.potentialCustomers")}
              />
            </MenuLinkItem>
            <MenuLinkItem
              disabled={!userState.loggedIn}
              to={generateLessonCategoriesPagePath()}
              activePatterns={[
                LESSON_CATEGORIES_PAGE_PATH_PATTERN,
                LESSON_CATEGORY_PAGE_PATH_PATTERN,
                LESSON_PAGE_PATH_PATTERN,
              ]}
            >
              <SecondaryMenuItemContent
                disabled={!userState.loggedIn}
                icon={<BsEaselFill />}
                label={t("components.sideMenu.courses")}
              />
            </MenuLinkItem>
            <MenuLinkItem
              disabled={!userState.loggedIn}
              to={generateIncentivesPagePath()}
            >
              <SecondaryMenuItemContent
                disabled={!userState.loggedIn}
                icon={<BsGiftFill />}
                label={t("components.sideMenu.incentive")}
              />
            </MenuLinkItem>
            <MenuLinkItem
              disabled={!userState.loggedIn}
              to={generateAlbumsPagePath()}
              activePatterns={[
                ALBUMS_PAGE_PATH_PATTERN,
                ALBUM_PAGE_PATH_PATTERN,
              ]}
            >
              <SecondaryMenuItemContent
                disabled={!userState.loggedIn}
                icon={<BsImages />}
                label={t("components.sideMenu.album")}
              />
            </MenuLinkItem>
            <MenuLinkItem
              disabled={!userState.loggedIn}
              to={generatePFinCalculatorPagePath()}
              activePatterns={[
                P_FIN_CALCULATOR_PAGE_PATH_PATTERN,
                P_FIN_CALCULATOR_RESULT_PAGE_PATH_PATTERN,
              ]}
            >
              <SecondaryMenuItemContent
                disabled={!userState.loggedIn}
                icon={<BsCalculatorFill />}
                label={t("components.sideMenu.premiumFinancingCalculator")}
              />
            </MenuLinkItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter className={styles.footer}>
          <Menu>
            {userState.loggedIn && (
              <MenuButtonItem onClick={onLogoutClick}>
                <LogoutMenuItemContent />
              </MenuButtonItem>
            )}
          </Menu>
        </SidebarFooter>

        <AlertModal {...alertModalProps} />
      </ProSidebar>
    );
  })
);

export default SideMenu;
