import { isApolloError, useMutation } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { BsGoogle } from "react-icons/bs";
import { generatePath } from "react-router-dom";
import IconButtonContent from "../../components/icon-button-content/IconButtonContent";
// import LoginBanner from "../../components/login-banner/LoginBanner";
import { StrapiErrorMessage, StrapiErrorName } from "../../constants/strapi";
import { LOGIN } from "../../graphql/mutations";
import { Login, LoginVariables } from "../../graphql/__generated__/Login";
import { useAuth } from "../../hooks/useAuth";
import {
  useImperativeErrorHandler,
  CustomErrorHandler,
} from "../../hooks/useErrorHandler";
import { InvalidAccessTokenError } from "../../models/errors/app";
import { EnvironmentVariables } from "../../utils/env";
import { isGraphQLErrorsContainStrapiErrorNameAndMessage } from "../../utils/strapi-gql";
import styles from "./LoginPage.module.scss";

const googleLoginUrl = `${EnvironmentVariables.API_ENDPOINT_URI}/api/connect/google`;

export const LOGIN_PAGE_PATH_PATTERN = "/login";

export function generateLoginPagePath(): string {
  return generatePath(LOGIN_PAGE_PATH_PATTERN, {});
}

const LoginPage: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { tryLoginWithAccessToken } = useAuth();

  const [login, { loading: loggingIn }] = useMutation<Login, LoginVariables>(
    LOGIN
  );

  const handleLoginError = useImperativeErrorHandler({
    name: "login",
    customErrorHandler: useCallback<CustomErrorHandler>((error, ctx) => {
      if (isApolloError(error)) {
        if (
          isGraphQLErrorsContainStrapiErrorNameAndMessage(
            error.graphQLErrors,
            StrapiErrorName.VALIDATION_ERROR,
            StrapiErrorMessage.INVALID_ID_OR_PW
          )
        ) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          ctx.showErrorToast(
            ctx.t("pages.loginPage.errors.invalidIdOrPw"),
            undefined,
            {
              autoDismissDuration: 3000,
            }
          );
          return true;
        }
      }
      return false;
    }, []),
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const allFieldsAreNotEmpty = useMemo(
    () => email !== "" && password !== "",
    [email, password]
  );

  const onEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    []
  );

  const onPasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    []
  );

  const onLoginFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();

        const result = await login({
          variables: {
            identifier: email,
            password: password,
          },
        });
        if (!result.data) {
          return;
        }

        const valid = result.data.login.jwt
          ? await tryLoginWithAccessToken(result.data.login.jwt)
          : false;
        if (!valid) {
          throw new InvalidAccessTokenError();
        }
      } catch (error: any) {
        handleLoginError(error);
      }
    },
    [email, handleLoginError, login, password, tryLoginWithAccessToken]
  );

  return (
    <div className={styles.root}>
      {/* <LoginBanner className={styles.bannerSection} /> */}
      <section className={styles.contentSection}>
        <div className={styles.spacer} />
        <div className={styles.contentContainer}>
          <h1 className={styles.title}>{t("pages.loginPage.title")}</h1>
          <div className={styles.content}>
            <Form
              className={styles.loginForm}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={onLoginFormSubmit}
            >
              <Form.Group>
                <Form.Label>{t("pages.loginPage.emailField.label")}</Form.Label>
                <Form.Control
                  type="email"
                  required={true}
                  placeholder={t("pages.loginPage.emailField.placeholder")}
                  disabled={loggingIn}
                  value={email}
                  onChange={onEmailChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  {t("pages.loginPage.passwordField.label")}
                </Form.Label>
                <Form.Control
                  type="password"
                  required={true}
                  placeholder={t("pages.loginPage.passwordField.placeholder")}
                  disabled={loggingIn}
                  value={password}
                  onChange={onPasswordChange}
                />
              </Form.Group>
              <Button
                className="w-100"
                type="submit"
                variant="primary"
                size="lg"
                disabled={!allFieldsAreNotEmpty || loggingIn}
              >
                <IconButtonContent
                  leftIcon={
                    loggingIn && <Spinner animation="border" size="sm" />
                  }
                >
                  {t("pages.loginPage.login")}
                </IconButtonContent>
              </Button>
            </Form>
            <div className={styles.orSeparator}>
              <span className={styles.label}>{t("pages.loginPage.or")}</span>
            </div>
            <Button
              className="w-100"
              variant="secondary"
              size="lg"
              as="a"
              disabled={loggingIn}
              href={googleLoginUrl}
            >
              <IconButtonContent leftIcon={<BsGoogle />}>
                {t("pages.loginPage.googleSignIn")}
              </IconButtonContent>
            </Button>
          </div>
        </div>
        <div className={styles.spacer} />
        <div className={styles.copyright}>{t("pages.loginPage.copyright")}</div>
      </section>
    </div>
  );
});

export default LoginPage;
