import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdTime } from "react-icons/io";
import { getLessonDurationDisplayText } from "../../utils/text";
import styles from "./DurationDisplay.module.scss";

interface Props {
  className?: string;
  valueClass?: string;
  duration: number; // in seconds
}

const DurationDisplay: React.FC<Props> = React.memo((props) => {
  const { className, valueClass, duration } = props;
  const { t } = useTranslation();
  return (
    <span className={className}>
      <IoMdTime />
      <span className={classNames(styles.value, valueClass)}>
        {getLessonDurationDisplayText(t, duration, false)}
      </span>
    </span>
  );
});

export default DurationDisplay;
