export function separateExtFromFilePath(path: string): {
  pathWithoutExt: string;
  ext: string;
} {
  const results = /^(.+)\.([a-zA-Z0-9]+)$/.exec(path);
  if (!results) {
    throw new Error(`Invalid path: "${path}"`);
  }
  const [_, pathWithoutExt, ext] = results;
  return {
    pathWithoutExt,
    ext,
  };
}
