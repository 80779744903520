import classNames from "classnames";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useMatchPathPatterns } from "../../../hooks/useMatchPathPatterns";
import styles from "./MenuLinkItem.module.scss";

const LINK_CLASS = "menu-link-item";
const ACTIVE_CLASS = "active";

interface Props {
  className?: string;
  disabled?: boolean;
  to: string;
  activePatterns?: string[];
  children?: React.ReactNode;
}

const MenuLinkItem: React.FC<Props> = React.memo((props) => {
  const { className, disabled, to, activePatterns, children } = props;

  const currentPathMatched = useMatchPathPatterns(
    useMemo(() => activePatterns ?? [to], [activePatterns, to])
  );

  return (
    <li
      className={classNames(styles.root, LINK_CLASS, className, {
        [ACTIVE_CLASS]: currentPathMatched,
        [styles.disabled]: disabled,
      })}
    >
      <NavLink className={styles.link} to={disabled ? "" : to}>
        {children}
      </NavLink>
    </li>
  );
});

export default MenuLinkItem;
