import classNames from "classnames";
import React from "react";
import styles from "./MaxWidthContainer.module.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const MaxWidthContainer: React.FC<Props> = React.memo((props) => {
  const { className, children } = props;
  return <div className={classNames(styles.root, className)}>{children}</div>;
});

export default MaxWidthContainer;
