import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useEffect } from "react";

export function useBodyScrollLock(
  element: HTMLElement | undefined | null,
  locked: boolean
): void {
  // Lock body scroll when overlay sidebar is opened
  useEffect(() => {
    if (!element) {
      return;
    }
    if (locked) {
      disableBodyScroll(element);
    } else {
      enableBodyScroll(element);
    }
  }, [locked, element]);

  // Reset body scroll locking when unmount
  useEffect(() => {
    return () => {
      if (!element) {
        return;
      }
      enableBodyScroll(element);
    };
  }, [element]);
}
