import cn from "classnames";
import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { useTranslation } from "react-i18next";
import { BillboardRecord } from "../../models/billboard";
import { getNumberDisplayText } from "../../utils/text";
import styles from "./BillboardItem.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  ranking: number | undefined;
  billboardRecord: BillboardRecord | undefined;
}

const BillboardItem: React.FC<Props> = React.memo((props) => {
  const { className, loading, ranking, billboardRecord } = props;

  const { t } = useTranslation();

  if (!billboardRecord || ranking === undefined) {
    if (loading) {
      return (
        <div className={cn(styles.root, className)}>
          <span className={cn(styles.cell, styles.ranking, "placeholder-glow")}>
            <Placeholder xs={12} />
          </span>
          <span className={cn(styles.cell, styles.name, "placeholder-glow")}>
            <Placeholder xs={12} />
          </span>
          <span
            className={cn(styles.cell, styles.submittedFYC, "placeholder-glow")}
          >
            <Placeholder xs={12} />
          </span>
          <span
            className={cn(
              styles.cell,
              styles.submittedCases,
              "placeholder-glow"
            )}
          >
            <Placeholder xs={12} />
          </span>
        </div>
      );
    }
    return null;
  }

  return (
    <div className={cn(styles.root, className)}>
      <span className={cn(styles.cell, styles.ranking)}>{ranking}</span>
      <span className={cn(styles.cell, styles.name)}>
        {billboardRecord.name}
      </span>
      <span className={cn(styles.cell, styles.submittedFYC)}>
        {getNumberDisplayText(t, billboardRecord.submittedFYC, 1)}
      </span>
      <span className={cn(styles.cell, styles.submittedCases)}>
        {getNumberDisplayText(t, billboardRecord.submittedCases, 1)}
      </span>
    </div>
  );
});

export default BillboardItem;
