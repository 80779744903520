import { isApolloError, useMutation } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import PageContentContainer from "../../components/page/PageContentContainer";
import { StrapiErrorMessage } from "../../constants/strapi";
import { LoadingModal, useMessageToast } from "../../contexts/DialogContext";
import { useUserContext } from "../../contexts/UserContext";
import { UPDATE_USER_INFO } from "../../graphql/mutations";
import {
  UpdateUserInfo,
  UpdateUserInfoVariables,
} from "../../graphql/__generated__/UpdateUserInfo";
import {
  CustomErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { StrapiValidationErrorHandlerOptions } from "../../models/strapi-error";
import { mapOptionalStringFormFieldToValue } from "../../utils/form";
import { getMessageFromStrapiYupError } from "../../utils/strapi-error";
import { tryHandleStrapiGQLValidationError } from "../../utils/strapi-gql";
import styles from "./AccountPage.module.scss";

const malaysiaAndSingaporePhonePattern = "\\+6[05]\\d{8,10}";

export const ACCOUNT_PAGE_PATH_PATTERN = "/account";

export function generateAccountPagePath(): string {
  return generatePath(ACCOUNT_PAGE_PATH_PATTERN, {});
}

const AccountPage: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { userState } = useUserContext();
  const { showMessageToast } = useMessageToast();

  const [updateUserInfo, { loading: updatingUserInfo }] = useMutation<
    UpdateUserInfo,
    UpdateUserInfoVariables
  >(UPDATE_USER_INFO);
  const handleUpdateUserInfoError = useImperativeErrorHandler({
    name: "updateUserInfo",
    customErrorHandler: useCallback<CustomErrorHandler>((error, ctx) => {
      if (isApolloError(error)) {
        const validationErrorHandler: StrapiValidationErrorHandlerOptions["validationErrorHandler"] =
          (message) => {
            if (message === StrapiErrorMessage.INVALID_CURRENT_PASSWORD) {
              const message = ctx.t(
                "pages.accountPage.changePasswordError.invalidCurrentPassword"
              );
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              ctx.showErrorToast(message, undefined, {
                autoDismissDuration: 3000,
              });
              return true;
            }
            return false;
          };

        const yupErrorHandler: StrapiValidationErrorHandlerOptions["yupErrorHandler"] =
          (yupError) => {
            const message = getMessageFromStrapiYupError(yupError, {
              password: (details) => {
                switch (details.type) {
                  case "string.min":
                    return ctx.t(
                      "pages.accountPage.changePasswordError.minString",
                      {
                        min: details.data.min,
                      }
                    );
                  default:
                    return undefined;
                }
              },
            });
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            ctx.showErrorToast(message, undefined, {
              autoDismissDuration: 3000,
            });
            return true;
          };

        if (
          tryHandleStrapiGQLValidationError(error.graphQLErrors, {
            validationErrorHandler,
            yupErrorHandler,
          })
        ) {
          return true;
        }
      }
      return false;
    }, []),
  });

  const [gender, setGender] = useState(userState.user?.gender ?? "");
  const [phone, setPhone] = useState(userState.user?.phone ?? "");
  const [district, setDistrict] = useState(userState.user?.district ?? "");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const formHasChanges = useMemo(() => {
    if (!userState.user) {
      return false;
    }
    return (
      mapOptionalStringFormFieldToValue(gender) !== userState.user.gender ||
      mapOptionalStringFormFieldToValue(phone) !== userState.user.phone ||
      mapOptionalStringFormFieldToValue(district) !== userState.user.district ||
      (currentPassword !== "" && newPassword !== "")
    );
  }, [currentPassword, district, gender, newPassword, phone, userState.user]);

  const onGenderChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setGender(event.target.value);
    },
    []
  );
  const onPhoneChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPhone(event.target.value);
    },
    []
  );
  const onDistrictChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDistrict(event.target.value);
    },
    []
  );
  const onCurrentPasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentPassword(event.target.value);
    },
    []
  );
  const onNewPasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPassword(event.target.value);
    },
    []
  );

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();

        await updateUserInfo({
          variables: {
            gender: mapOptionalStringFormFieldToValue(gender),
            phone: mapOptionalStringFormFieldToValue(phone),
            district: mapOptionalStringFormFieldToValue(district),
            changePassword:
              currentPassword !== "" && newPassword !== ""
                ? {
                    currentPassword,
                    newPassword,
                  }
                : undefined,
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        showMessageToast(
          t("pages.accountPage.infoUpdatedAlert.message"),
          t("pages.accountPage.infoUpdatedAlert.title")
        );

        // Reset password management fields
        setCurrentPassword("");
        setNewPassword("");
      } catch (error: unknown) {
        handleUpdateUserInfoError(error);
      }
    },
    [
      updateUserInfo,
      gender,
      phone,
      district,
      currentPassword,
      newPassword,
      showMessageToast,
      t,
      handleUpdateUserInfoError,
    ]
  );

  return (
    <PageContentContainer className={styles.root}>
      <LoadingModal show={updatingUserInfo} />

      <h2 className={styles.title}>{t("pages.accountPage.title")}</h2>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={onSubmit}>
        <section className={styles.formSection}>
          <div className={styles.sectionLabel}>
            {t("pages.accountPage.myProfile")}
          </div>
          <Row className={styles.formRow}>
            <Col className={styles.formCol} md={12}>
              <Form.Label>{t("pages.accountPage.emailField.label")}</Form.Label>
              <Form.Control
                type="email"
                disabled={true}
                value={userState.user?.email ?? ""}
              />
            </Col>
          </Row>
          <Row className={styles.formRow}>
            <Col className={styles.formCol} md={6}>
              <Form.Label>
                {t("pages.accountPage.genderField.label")}
              </Form.Label>
              <Form.Select value={gender} onChange={onGenderChange}>
                <option value=""></option>
                <option value="M">
                  {t("pages.accountPage.genderField.male")}
                </option>
                <option value="F">
                  {t("pages.accountPage.genderField.female")}
                </option>
              </Form.Select>
            </Col>
            <Col className={styles.formCol} md={6}>
              <Form.Label>{t("pages.accountPage.phoneField.label")}</Form.Label>
              <Form.Control
                type="tel"
                pattern={malaysiaAndSingaporePhonePattern}
                value={phone}
                onChange={onPhoneChange}
              />
            </Col>
          </Row>
          <Row>
            <Col className={styles.formCol} md={6}>
              <Form.Label>
                {t("pages.accountPage.districtField.label")}
              </Form.Label>
              <Form.Control
                type="text"
                value={district}
                onChange={onDistrictChange}
              />
            </Col>
          </Row>
        </section>
        <section className={styles.formSection}>
          <div className={styles.sectionLabel}>
            {t("pages.accountPage.changePassword")}
          </div>
          <Row className={styles.formRow}>
            <Col className={styles.formCol} md={6}>
              <Form.Label>
                {t("pages.accountPage.currentPasswordField.label")}
              </Form.Label>
              <Form.Control
                type="password"
                value={currentPassword}
                onChange={onCurrentPasswordChange}
              />
            </Col>
            <Col className={styles.formCol} md={6}>
              <Form.Label>
                {t("pages.accountPage.newPasswordField.label")}
              </Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={onNewPasswordChange}
              />
            </Col>
          </Row>
        </section>
        <div className={styles.submitButtonRow}>
          <Button
            as="input"
            type="submit"
            size="lg"
            value={t("pages.accountPage.save")}
            disabled={!formHasChanges}
          />
        </div>
      </form>
    </PageContentContainer>
  );
});

export default AccountPage;
