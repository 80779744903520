import {
  FieldFunctionOptions,
  FieldMergeFunction,
  FieldPolicy,
  FieldReadFunction,
  ServerError,
} from "@apollo/client";
import { KeySpecifier } from "@apollo/client/cache/inmemory/policies";

interface KeyArgItem<T, N extends keyof T = keyof T> {
  name: N;
  subfields?: KeyArgItem<NonNullable<T[N]>>[];
}

export function isApolloServerError(value: any): value is ServerError {
  return (
    value instanceof Error &&
    "response" in value &&
    "result" in value &&
    "statusCode" in value &&
    typeof value["response"] === "object" &&
    typeof value["result"] === "object" &&
    typeof value["statusCode"] === "number"
  );
}

// TODO (nicolas 30May2022): Remove isObservableCancelledPrematurelyError util function after upgraded apollo-client to 3.6.4 (https://github.com/apollographql/apollo-client/pull/9701).
export function isObservableCancelledPrematurelyError(error: unknown): boolean {
  return (
    error instanceof Error &&
    error.message === "Observable cancelled prematurely"
  );
}

/**
 * Create KeyArgItem with enhanced type inference.
 */
export function createKeyArgItem<T, N extends keyof T = keyof T>(
  name: N,
  subfields?: KeyArgItem<NonNullable<T[N]>>[]
): KeyArgItem<T, N> {
  return {
    name,
    subfields,
  };
}

/**
 * Flatten argItems to Apollo's nested argument keyArgs array.
 * @see {@link https://www.apollographql.com/docs/react/pagination/key-args#keyargs-array}
 */
function flattenArgItems<T>(argItems: KeyArgItem<T>[]): KeySpecifier {
  return argItems
    .map((item) => [
      item.name as string,
      ...(item.subfields ? [flattenArgItems(item.subfields)] : []),
    ])
    .reduce<KeySpecifier>(
      (list, keySpecifier) => [...list, ...keySpecifier],
      []
    );
}

/**
 * Create Apollo's FieldPolicy with enhanced type inference.
 */
export function createFieldPolicy<
  T extends Record<string, any>,
  TVars extends Record<string, any>,
  TArgs = Record<string, any>
>(policy: {
  keyArgs?: KeyArgItem<TArgs>[];
  merge?: FieldMergeFunction<T, T, FieldFunctionOptions<TArgs, TVars>>;
  read?: FieldReadFunction<T, T, FieldFunctionOptions<TArgs, TVars>>;
}): FieldPolicy<T | undefined, T, T> {
  return {
    keyArgs: policy.keyArgs ? flattenArgItems(policy.keyArgs) : undefined,
    merge: policy.merge as any,
    read: policy.read as any,
  };
}
