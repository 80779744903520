import { useMutation, useQuery } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import PageContentContainer from "../../components/page/PageContentContainer";
import PageNavHeader from "../../components/page/PageNavHeader";
import PageToolbar from "../../components/page/PageToolbar";
import QuizQuestionItem from "../../components/quiz/QuizQuestionItem";
import { LoadingModal } from "../../contexts/DialogContext";
import { USER_SUBMIT_QUIZ } from "../../graphql/mutations";
import { USER_FETCH_QUIZ } from "../../graphql/queries";
import {
  UserFetchQuiz,
  UserFetchQuizVariables,
} from "../../graphql/__generated__/UserFetchQuiz";
import {
  UserSubmitQuiz,
  UserSubmitQuizVariables,
} from "../../graphql/__generated__/UserSubmitQuiz";
import {
  useErrorHandler,
  useImperativeErrorHandler,
} from "../../hooks/useErrorHandler";
import { createNumberList } from "../../utils/list";
import styles from "./QuizPage.module.scss";
import {
  generateQuizResultPagePath,
  QuizResultPageLocationState,
} from "./QuizResultPage";

type PageParamKey = "uuid";

export const QUIZ_PAGE_PATH_PATTERN = "/quiz/:uuid";

export function generateQuizPagePath(uuid: string): string {
  return generatePath(QUIZ_PAGE_PATH_PATTERN, {
    uuid,
  });
}

const QuizPage: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageParams = useParams<PageParamKey>();

  const {
    data: quizData,
    loading: loadingQuizData,
    error: fetchQuizError,
  } = useQuery<UserFetchQuiz, UserFetchQuizVariables>(USER_FETCH_QUIZ, {
    variables: {
      uuid: pageParams.uuid!,
    },
  });

  const [submitQuiz, { loading: submittingQuizResult }] = useMutation<
    UserSubmitQuiz,
    UserSubmitQuizVariables
  >(USER_SUBMIT_QUIZ);

  // question UUID to selected answer dictionary
  const [answerDict, setAnswerDict] = useState<Record<string, string>>({});

  useErrorHandler(fetchQuizError, {
    name: "fetchAlbumPhotos",
  });

  const handleSubmitQuizError = useImperativeErrorHandler({
    name: "submitQuiz",
  });

  const answeredAllQuestions = useMemo(() => {
    if (!quizData) {
      return false;
    }
    return !quizData.userFetchQuiz.questions.some(
      (question) => !answerDict[question.uuid]
    );
  }, [answerDict, quizData]);

  const onSubmit = useCallback(async () => {
    if (!answeredAllQuestions) {
      return;
    }
    try {
      const quizUuid = pageParams.uuid!;
      const questions = Object.entries(answerDict).map(
        ([questionUUID, answer]) => ({
          uuid: questionUUID,
          answer,
        })
      );
      const result = await submitQuiz({
        variables: {
          quizUuid,
          questions,
        },
      });
      if (!result.data) {
        return;
      }
      const quizResultPageLocationState: QuizResultPageLocationState = {
        quiz: result.data.userSubmitQuiz.quiz,
        totalScore: result.data.userSubmitQuiz.totalScore,
      };
      navigate(generateQuizResultPagePath(), {
        replace: true,
        state: quizResultPageLocationState,
      });
    } catch (error: any) {
      handleSubmitQuizError(error);
    }
  }, [
    answeredAllQuestions,
    pageParams.uuid,
    answerDict,
    submitQuiz,
    navigate,
    handleSubmitQuizError,
  ]);

  const generateQuestionItemOnChange = useCallback((questionUUID: string) => {
    return (answer: string) => {
      setAnswerDict((prev) => ({ ...prev, [questionUUID]: answer }));
    };
  }, []);

  const quizTitleComponent = useMemo(() => {
    if (!quizData) {
      if (loadingQuizData) {
        return (
          <Placeholder animation="glow" xs={3}>
            <Placeholder className={styles.quizTitle} xs={12} />
          </Placeholder>
        );
      }
      return null;
    }
    return (
      <span className={styles.quizTitle}>{quizData.userFetchQuiz.title}</span>
    );
  }, [quizData, loadingQuizData]);

  const questionColComponents = useMemo(() => {
    if (!quizData) {
      if (loadingQuizData) {
        return createNumberList(3).map((index) => (
          <Col key={index} xs={12}>
            <QuizQuestionItem
              loading={true}
              questionNumber={undefined}
              question={undefined}
              selectedAnswer={undefined}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={() => {}}
            />
          </Col>
        ));
      }
      return null;
    }

    return quizData.userFetchQuiz.questions.map((question, index) => (
      <Col key={question.uuid} xs={12}>
        <QuizQuestionItem
          questionNumber={index + 1}
          question={question}
          selectedAnswer={answerDict[question.uuid]}
          onChange={generateQuestionItemOnChange(question.uuid)}
        />
      </Col>
    ));
  }, [loadingQuizData, quizData, answerDict, generateQuestionItemOnChange]);

  return (
    <div className={styles.root}>
      <LoadingModal show={submittingQuizResult} />
      <PageNavHeader backButtonText={t("pages.quizPage.back")} />
      <PageToolbar contentClass={styles.toolbarContent}>
        {quizTitleComponent}
        <Button
          variant="outline-light"
          disabled={!answeredAllQuestions || !quizData || loadingQuizData}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onSubmit}
        >
          {t("pages.quizPage.submit")}
        </Button>
      </PageToolbar>
      <PageContentContainer className={styles.mainContent}>
        <Row className="g-3">{questionColComponents}</Row>
      </PageContentContainer>
    </div>
  );
});

export default QuizPage;
