import React, { useContext, useMemo } from "react";
import { StrapiRestAPIClient } from "../utils/strapi-rest";

export interface APIClientContextValue {
  strapiRestAPIClient: StrapiRestAPIClient;
}

interface APIClientContextProviderProps {
  baseUri: string;
  children: React.ReactNode;
}

const APIClientContext = React.createContext<APIClientContextValue | null>(
  null
);

export const APIClientContextProvider: React.FC<APIClientContextProviderProps> =
  React.memo((props) => {
    const { baseUri, children } = props;

    const strapiRestAPIClient = useMemo(() => {
      return new StrapiRestAPIClient(baseUri);
    }, [baseUri]);

    const contextValue = useMemo<APIClientContextValue>(() => {
      return {
        strapiRestAPIClient,
      };
    }, [strapiRestAPIClient]);

    return (
      <APIClientContext.Provider value={contextValue}>
        {children}
      </APIClientContext.Provider>
    );
  });

export function useAPIClient(): APIClientContextValue {
  const contextValue = useContext(APIClientContext);

  if (!contextValue) {
    throw Error("useAPIClient should be used inside APIClientContextProvider");
  }

  return contextValue;
}
